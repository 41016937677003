import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, Divider } from "@material-ui/core";
import {
  IContactInfo,
  TravelerSelectStep,
  CheckoutStepper,
  ContactInfoForm,
  ButtonWrap,
  Icon,
  IconName,
  emailRegex,
  phoneRegex,
  ActionButton,
  ActionLink,
  TreesConfirmation,
  InformationalModal as TreesModal,
  HotelCrossSellAwarenessCard,
  VoidWindowNotice,
} from "halifax";
import clsx from "clsx";
import { Location } from "history";
import { RouteComponentProps } from "react-router";
import {
  ADD_CONTACT_INFO,
  PriceDropProtectionEnum,
  PriceDropViewedProperties,
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
  ITravelerStepErrors,
  AIR_SAVE_AND_CONTINUE_CLICKED,
  FlightBookType,
  CallState,
  TripCategory,
  PRICE_FREEZE_ID_QUERY_PARAM,
  //CONFIRMED_VI_BAGGAGE_WARNING,
  //DECLINED_VI_BAGGAGE_WARNING,
} from "redmond";
import { SeatSelection } from "../../SeatSelection";
import "./styles.scss";
import {
  FlightShopSummaryPanel,
  FlightShopProgressBar,
} from "../../../../shop/components";
import { AddOnCustomize } from "../../../../shop/components/addOnComponents";
import { FlightFreezeProgressBar } from "../../../../freeze/components";
import { DesktopFlightBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  FlightBookPassengerSelection,
  PaymentCard,
  FrozenPriceSummary,
  PriceDropProtection,
} from "../../../components";
import {
  BOOK_BUTTON_TEXT,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_HELPER_TEXT,
  REQUIRED_DETAILS_TEXT,
  SEAT_SELECTION_TITLE,
  CONTACT_INFO_TITLE_NO_STEP,
  CHECKOUT_PRICE_BREAKDOWN_HEADER,
  TRAVELER_INFO_TITLE,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
} from "./textConstants";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_BOOK_WITH_PF_CONFIRMATION,
  PATH_BOOK_WITH_VI_CONFIRMATION,
  PATH_HOME,
} from "../../../../../utils/urlPaths";
import { ClientContext } from "../../../../../App";
import { AGENT_FEE } from "../../index";
import { CheckoutSteps } from "../../../reducer/selectors";
import { SEATING } from "../../../reducer/selectors/textConstants";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  PRICE_FREEZE,
  SEAT_SELECTION,
  getExperimentVariant,
  useExperiments,
  TREES_MODAL_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  getExperimentVariantCustomVariants,
  VOID_WINDOW_EXPERIMENT,
  CREDIT_OFFER_STACKING_V1,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  SEATS_UX_OPTIMIZATION,
  PASSPORT_ENHANCEMENT,
  CONTROL,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
} from "../../../../../context/experiments";
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import {
  FlightShopStep,
  MulticityFlightShopStep,
} from "../../../../shop/reducer";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import { getPriceDropRefundTypeSelector } from "../../../../ancillary/reducer";
import { MulticityFlightShopProgressBar } from "../../../../shop/components/MulticityFlightShopProgressBar";
import { OptIn } from "../../AlertOptIn";
import { PassportAwarenessCard } from "../../PassportAwarenessCard/component";
import {
  IVirtualInterliningVariant,
  VirtualIinterliningModal,
} from "../../../../shop/components/VirtualIinterliningModal";
import { MultipleAirlinesFareModal } from "../../../../shop/components/MultipleAirlinesFareModal";

//import {SelfTransferReminderModal} from "../../../shop/components/SelfTransferReminderModal";
export interface IDesktopFlightBookWorkflowProps
  extends RouteComponentProps,
    DesktopFlightBookWorkflowConnectorProps {}

export const DesktopFlightBookWorkflow = ({
  setContactInfo,
  scheduleQuote,
  finalizedItinerary,
  history,
  selectedTrip,
  confirmationEmail,
  confirmationPhoneNumber,
  isConfirmAndBookReady,
  priceDifferenceAcknowledged,
  schedulePayment,
  currentSession,
  payments,
  ancillaries,
  priceDropProtection,
  priceDropViewedProperties,
  priceQuote,
  hasDifference,
  isTravelerStepComplete,
  isCustomizePageMarketplaceEnabled,
  isFlightBookWithAncillariesEnabled,
  isFlightBookWithAncillariesActive,
  isOptionSelectionComplete,
  isDisruptionProtectionEnabled,
  fetchAncillaryOfferCallState,
  fetchAncillaryOffer,
  isPriceFreezeExerciseEnabled,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setSelectedSeats,
  bookingProgressList,
  getCheckoutStepNumber,
  isWaitingPriceQuote,
  priceQuoteErrors,
  setQuote,
  flightShopProgress,
  setFlightShopProgress,
  tripCategory,
  hasActiveRefundableFare,
  flightBookType,
  fetchApplicableTravelWalletItems,
  resetFetchAncillaryOfferState,
  airports,
  fetchInitialCrossSellHotelAvailability,
  isVoidWindowEligible,
  isTravelWalletPaymentOnly,
  fetchTravelWalletCreditHistory,
  multicityFlightShopProgress,
  setMulticityFlightShopProgress,
  smsBeenSelected,
  appNotifBeenSelected,
  hasSelectedDisruption,
  setUserTcpaConsentPhoneNumber,
  disruptionOptInEnabled,
  nonFDAdisruptionOptInEnabled,
  isInternationalTrip,
  isCfarMulticityEnabled,
  tripDetails,
  disruptionDelayThreshold,
  viewedTripSummaryProperties,
  potentialCrossSellOffers,
}: IDesktopFlightBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;
  const isFirstStepCompletedInCheckout = isTravelerStepComplete;

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
  });

  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    useState<boolean>(true);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [disableEditTravelerInfo, setDisableEditTravelerInfo] = useState(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const expState = useExperiments();

  const seatSelectionGroup = getExperimentVariant(
    expState.experiments,
    SEAT_SELECTION
  );
  const priceFreezeGroup = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE
  );

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );

  const isPriceFreeze = useMemo(
    () => isPriceFreezeExerciseEnabled && priceFreezeGroup === AVAILABLE,
    [priceFreezeGroup, isPriceFreezeExerciseEnabled]
  );

  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const isVoidWindowExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, VOID_WINDOW_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const passportEnhancementExperiment = getExperimentVariant(
    expState.experiments,
    PASSPORT_ENHANCEMENT
  );
  const isPassportEnhancementExperiment = useMemo(() => {
    return passportEnhancementExperiment === AVAILABLE;
  }, [passportEnhancementExperiment]);

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = tripDetails.fareDetails[0]?.slices.some((slice) =>
    slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  );

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openVIVariantModal, setOpenVIVariantModal] = useState<
    IVirtualInterliningVariant | "reminder" | false
  >(false);

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openMultipleAirlinesFares, setOpenMultipleAirlinesFares] =
    useState(false);

  const priceDropRefundType = useSelector(getPriceDropRefundTypeSelector);

  const isSeatSelectionGroupEnabled = seatSelectionGroup === AVAILABLE;

  const disruptionDelayThresholdStringInHours =
    disruptionDelayThreshold === 180 ? "3" : "2";

  /*
    note: the proper way to handle the filtering logic is by passing the feature flag to redux, but since we currently have
    a plan to refactor that pattern (e.g. https://hopper-jira.atlassian.net/browse/BF-874), a workaround is used here for now
  */
  const bookingProgressSteps = bookingProgressList.filter(
    (progress) => progress.name !== SEATING || isSeatSelectionGroupEnabled
  );

  const onBookFlight = () => {
    if (selectedTrip.tripId) {
      priceQuote &&
      currentSession &&
      payments &&
      ancillaries &&
      (!hasDifference || priceDifferenceAcknowledged)
        ? schedulePayment({
            session: currentSession,
            payments,
            ancillaries,
            agentFee: isAgentPortal ? AGENT_FEE : 0,
          })
        : scheduleQuote({ agentFee: isAgentPortal ? AGENT_FEE : 0 });
    }
  };

  const isPDPEligible =
    priceDropProtection &&
    priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;
  useEffect(() => {
    if (finalizedItinerary) {
      fetchInitialCrossSellHotelAvailability(finalizedItinerary, airports);
      if (history.location.search.includes(PRICE_FREEZE_ID_QUERY_PARAM)) {
        history.push(PATH_BOOK_WITH_PF_CONFIRMATION);
      } else if (isVITripSelected) {
        history.push(PATH_BOOK_WITH_VI_CONFIRMATION);
      } else {
        history.push(PATH_BOOK_CONFIRMATION);
      }
      if (isPDPEligible) {
        const properties: PriceDropViewedProperties = {
          ...priceDropViewedProperties,
          page: "flight_book",
        };
        trackEvent({
          eventName: PRICE_DROP_VIEWED,
          properties,
        });
      }
    }
  }, [finalizedItinerary]);

  useEffect(() => {
    if (priceQuoteErrors.length > 0) {
      setDisableEditTravelerInfo(false);
    }
  }, [priceQuoteErrors]);

  useEffect(() => {
    // note: this handles if on the booking error modals, the button gets PQ again but does not bring user to next step (such as LapInfantsUnsupported error)
    if (priceQuote && priceQuoteErrors.length === 0 && isTravelerStepComplete) {
      setDisableEditTravelerInfo(true);
    }
  }, [priceQuote, priceQuoteErrors]);

  useEffect(() => {
    if (
      !isMulticity &&
      isFlightBookWithAncillariesEnabled &&
      fetchAncillaryOfferCallState === CallState.NotCalled
    ) {
      fetchAncillaryOffer({
        preserveCfarId: hasActiveRefundableFare,
      });
    }
  }, [
    isFlightBookWithAncillariesEnabled,
    fetchAncillaryOfferCallState,
    hasActiveRefundableFare,
  ]);

  useEffect(() => {
    if (isCfarMulticityEnabled && isMulticity) {
      // Multi-city tracks flight shop progress a little differently. Book is a step in itself.
      setMulticityFlightShopProgress(MulticityFlightShopStep.BookTrip);
    }
  }, []);

  useEffect(() => {
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();

    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setUserTcpaConsentPhoneNumber(details?.phoneNumber || "");
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();

    // note: resolve any potential loop caused by flightShopStep (if it exists)
    if (isMulticity) {
      const prevMulticityFlightShopProgress:
        | MulticityFlightShopStep
        | undefined = (
        history.location as Location<{
          multicityFlightShopProgress: MulticityFlightShopStep;
        }>
      ).state?.multicityFlightShopProgress;
      if (
        isMulticity &&
        prevMulticityFlightShopProgress !== undefined &&
        multicityFlightShopProgress !== prevMulticityFlightShopProgress
      ) {
        setMulticityFlightShopProgress(prevMulticityFlightShopProgress);
      }
    } else {
      const prevFlightShopProgress: FlightShopStep | undefined = (
        history.location as Location<{ flightShopProgress: FlightShopStep }>
      ).state?.flightShopProgress;
      if (
        !isMulticity &&
        prevFlightShopProgress !== undefined &&
        flightShopProgress !== prevFlightShopProgress
      ) {
        setFlightShopProgress(prevFlightShopProgress);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      if (isFlightBookWithAncillariesActive) {
        resetFetchAncillaryOfferState({
          preserveCfarId: hasActiveRefundableFare,
        });
      }
    };
  }, [isFlightBookWithAncillariesActive, hasActiveRefundableFare]);

  const isMulticity = useMemo(() => {
    return tripCategory === TripCategory.MULTI_CITY;
  }, [tripCategory]);

  const renderLogo = (
    <ButtonWrap
      className="logo"
      onClick={() => {
        history.push(PATH_HOME);
      }}
    >
      {clientContext.logo}
    </ButtonWrap>
  );

  return (
    // TODO: migrate CheckoutTemplate to Halifax for reusability
    <Box className="desktop-flight-book-workflow-root">
      <CheckoutStepper
        steps={bookingProgressSteps}
        headerCopy={BOOK_BUTTON_TEXT}
        subHeaderCopy={REQUIRED_DETAILS_TEXT}
        logo={renderLogo}
        className={clsx("b2b", "combined-step")}
      />
      {isCustomizePageMarketplaceEnabled && (
        <Box className="flight-book-progress-bar-wrapper">
          {!isMulticity && flightBookType === FlightBookType.DEFAULT && (
            <FlightShopProgressBar
              useCustomizeStep={true}
              prevPath={PATH_BOOK}
              usePush={true}
            />
          )}
          {isMulticity && <MulticityFlightShopProgressBar />}
          {flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE && (
            <FlightFreezeProgressBar />
          )}
        </Box>
      )}
      <Box
        className={clsx("desktop-flight-book-workflow-container", {
          "with-progress-bar": isCustomizePageMarketplaceEnabled,
        })}
      >
        <Box className={clsx("checkout-template-column", "left")}>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "flight"
            )}
          >
            <FlightShopSummaryPanel
              showViewDetailsSection
              prevPath={PATH_BOOK}
              disableChangeFlight={isPriceFreezeExerciseEnabled}
              isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
              isVITripSelected={isVITripSelected}
              setOpenVIVariantModal={setOpenVIVariantModal}
              setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
            />
          </Box>
          {isHotelCrossSellV3Experiment &&
          potentialCrossSellOffers.length > 0 ? (
            <Box className="checkout-template-card-content-container">
              <HotelCrossSellAwarenessCard
                offerText={potentialCrossSellOffers[0].mainDescription}
                offerPillText={potentialCrossSellOffers[0].subDescription}
              />
            </Box>
          ) : undefined}
          {isInternationalTrip && isPassportEnhancementExperiment && (
            <Box className="checkout-template-card-content-container">
              <PassportAwarenessCard />
            </Box>
          )}
          {!isMulticity &&
            isTravelWalletOfferExperiment &&
            !isCreditAndOfferStackingExperimentV1 && <TravelOfferSelection />}

          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "traveler"
            )}
          >
            <>
              <Box className={clsx("checkout-template-description")}>
                <Typography
                  variant="h2"
                  className={clsx("checkout-template-title")}
                >
                  {TRAVELER_INFO_TITLE(
                    getCheckoutStepNumber(CheckoutSteps.ADD_TRAVELERS)
                  )}
                </Typography>
              </Box>
              <Divider className="traveler-info-container-divider" />
            </>
            <FlightBookPassengerSelection
              progress={travelerWorkflowStep}
              setProgress={setTravelerWorkflowStep}
              combinedStep
              disabled={disableEditTravelerInfo}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              saveButtonClicked={saveButtonClicked}
              hiddenTravelerIds={[]}
            />

            <ContactInfoForm
              disabled={disableEditTravelerInfo}
              title={CONTACT_INFO_TITLE_NO_STEP}
              subtitle={CONTACT_INFO_SUBTITLE}
              contactInfo={contactInfo}
              onContactInfoChange={(info: IContactInfo) => {
                setContact(info);
                setContactInfo(info.email, info.phoneNumber);
                if (
                  info.email &&
                  info.phoneNumber &&
                  emailRegex.test(info.email) &&
                  phoneRegex.test(info.phoneNumber)
                ) {
                  trackEvent({
                    eventName: ADD_CONTACT_INFO,
                    properties: {},
                  });
                }
              }}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              loading={customerDetailsLoading}
              disruptionOptInPresent={
                hasSelectedDisruption && disruptionOptInEnabled
              }
            />
            <OptIn
              disabled={disableEditTravelerInfo}
              smsBeenSelected={smsBeenSelected}
              appNotifBeenSelected={appNotifBeenSelected}
              hasSelectedDisruption={hasSelectedDisruption}
              disruptionOptInEnabled={disruptionOptInEnabled}
              nonFDAdisruptionOptInEnabled={nonFDAdisruptionOptInEnabled}
              disrutpionDelayThresholdStringInHours={
                disruptionDelayThresholdStringInHours
              }
            />

            {!disableEditTravelerInfo && (
              <ActionButton
                className={clsx(
                  "traveler-select-workflow-button",
                  "traveler-step-cta"
                )}
                message="Save and continue"
                onClick={() => {
                  if (isFirstStepCompletedInCheckout) {
                    trackEvent({
                      eventName: AIR_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: false,
                      },
                    });
                    scheduleQuote({
                      agentFee: isAgentPortal ? AGENT_FEE : 0,
                      pollQuoteOnly: true,
                    });
                    setDisableEditTravelerInfo(true);
                    setShowErrors({
                      phone: false,
                      email: false,
                      travelerSelect: false,
                    });
                  } else {
                    trackEvent({
                      eventName: AIR_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: true,
                        traveler_error: !isTravelerStepComplete,
                        email_error:
                          !contactInfo || !emailRegex.test(contactInfo.email),
                        phone_error:
                          !contactInfo ||
                          !phoneRegex.test(contactInfo.phoneNumber),
                      },
                    });
                    setShowErrors({
                      phone:
                        !contactInfo ||
                        !phoneRegex.test(contactInfo.phoneNumber),
                      email:
                        !contactInfo || !emailRegex.test(contactInfo.email),
                      travelerSelect: !isTravelerStepComplete,
                    });
                    if (!saveButtonClicked) {
                      setSaveButtonClicked(true);
                    }
                  }
                }}
                defaultStyle="h4r-primary"
              />
            )}
            {disableEditTravelerInfo && (
              <ActionLink
                className={clsx("traveler-step-cta", "b2b")}
                content={
                  <Box className="edit-traveler-link">
                    <Typography variant="body1">
                      Edit Traveler Information
                    </Typography>
                    <Icon
                      aria-hidden
                      className="icon-end"
                      name={IconName.B2BEditPencil}
                      ariaLabel=""
                    />
                  </Box>
                }
                onClick={() => {
                  // reset the price quote since it will be called again
                  setQuote(null);
                  setDisableEditTravelerInfo(false);
                  setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                    multiTicketType:
                      viewedTripSummaryProperties?.multi_ticket_type,
                  });
                  setSelectedRewardsAccountReferenceId(null);
                  setSelectedSeats([]);
                }}
              />
            )}
            <Box className={clsx("contact-info-helper-container")}>
              <Typography
                variant="body2"
                className={clsx("contact-info-helper-text")}
              >
                {CONTACT_INFO_HELPER_TEXT}
              </Typography>
            </Box>
          </Box>
          {isSeatSelectionGroupEnabled && (
            <SeatSelection
              title={SEAT_SELECTION_TITLE(
                getCheckoutStepNumber(CheckoutSteps.SEATING)
              )}
              disabled={
                !isFirstStepCompletedInCheckout ||
                !disableEditTravelerInfo ||
                // note: in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, the seat map data is persisted while refetching a new price quote
                (isWaitingPriceQuote && !isFlightBookWithAncillariesActive)
              }
            />
          )}
          {(!isMulticity || isCfarMulticityEnabled) &&
            isFlightBookWithAncillariesActive && (
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "ancillary"
                )}
              >
                <AddOnCustomize
                  tripCategory={tripCategory}
                  isDisruptionProtectionEnabled={isDisruptionProtectionEnabled}
                  disableCollapse={true}
                  useFlightCheckoutVariant={true}
                />
              </Box>
            )}
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "credit"
            )}
          >
            <PaymentCard
              disabled={
                !isFirstStepCompletedInCheckout ||
                !disableEditTravelerInfo ||
                (isFlightBookWithAncillariesActive &&
                  !isOptionSelectionComplete) ||
                (!isCreditAndOfferStackingExperimentV1 &&
                  isTravelWalletPaymentOnly)
              }
              className={clsx({ "less-padding": isTreesModalExperiment })}
            />
            {isTreesModalExperiment && (
              <TreesModal
                image={TreesConfirmation}
                header={TREES_MODAL_HEADER}
                title={TREES_MODAL_TITLE}
                onClose={() => setTreeModalOpen(false)}
                subtitle={TREES_MODAL_SUBTITLE}
                icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
                openModal={treeModalOpen}
                setOpenModal={setTreeModalOpen}
                modalLinkCopy={TREES_MODAL_CTA_TEXT}
                modalButtonCopyStartIcon={
                  <Icon className="trees-icon" name={IconName.TreesIcon} />
                }
                boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
              />
            )}
          </Box>
        </Box>
        <Box className={clsx("checkout-template-column", "right")}>
          <Box className="checkout-template-column-right-wrapper">
            {!isMulticity && isPriceFreeze && (
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "price-freeze-details"
                )}
              >
                <FrozenPriceSummary />
              </Box>
            )}
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "pricing-breakdown"
              )}
            >
              <Box className="pricing-breakdown-header-container">
                <Typography className="header" variant="h2">
                  {CHECKOUT_PRICE_BREAKDOWN_HEADER}
                </Typography>
              </Box>
              {!isMulticity && priceDropProtection && isPDPEligible && (
                <PriceDropProtection
                  priceDropProtection={priceDropProtection}
                  onClick={() =>
                    trackEvent({
                      eventName: VIEWED_PRICE_DROP_DETAILS,
                      properties: {
                        page: "flight_book",
                        refund_type: priceDropRefundType,
                      },
                    })
                  }
                />
              )}
              <Box className={clsx("payment-break-down")}>
                <PriceBreakdown isMobile={false} />
              </Box>
              <Box className="confirm-book-button-container">
                <Button
                  className="confirm-book-button"
                  disabled={!isConfirmAndBookReady}
                  onClick={onBookFlight}
                >
                  {BOOK_BUTTON_TEXT}
                </Button>
              </Box>
            </Box>

            {isVoidWindowExperiment && isVoidWindowEligible && (
              <VoidWindowNotice />
            )}
          </Box>
        </Box>
      </Box>

      {openVIVariantModal && openVIVariantModal !== "reminder" && (
        <VirtualIinterliningModal
          isMobile={false}
          variant={openVIVariantModal}
          fareDetails={tripDetails.fareDetails}
          airports={airports}
          isOpen
          onClose={() => setOpenVIVariantModal(false)}
        />
      )}

      {/*openVIVariantModal === "reminder" && (
        <SelfTransferReminderModal
           isOpen
           fareDetails={tripDetails.fareDetails}
           tripDetails={tripDetails}
           onClickContinue={() => {
             trackEvent({
               eventName: CONFIRMED_VI_BAGGAGE_WARNING,
               properties: {},
             });
             populateFlightBookQueryParams({ history });
           }}
           onClickDifferentFlight={() => {
             trackEvent({
               eventName: DECLINED_VI_BAGGAGE_WARNING,
               properties: {},
             });
             setFlightShopProgress(FlightShopStep.ChooseDeparture);
           }}
           onClose={() => setOpenVIVariantModal(false)}
           slices={tripDetails.slices}
           airports={airports}
        />
      )*/}

      {openMultipleAirlinesFares && (
        <MultipleAirlinesFareModal
          isOpen={openMultipleAirlinesFares}
          tripDetails={tripDetails}
          airports={airports}
          onClose={() => setOpenMultipleAirlinesFares(false)}
        />
      )}
    </Box>
  );
};
