import React, { useContext, useState } from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  useDeviceTypes,
  getDateTimeWithFormat,
  ToggleActions,
  VoidWindowNotice,
} from "halifax";
import {
  IPerson,
  PersonId,
  CallState,
  ADD_FREQUENT_FLYER,
  ITravelerStepErrors,
  CLICKED_ADD_TRAVELER,
  ClickedAddTravelerProperties,
  ICorpPerson,
} from "redmond";
import clsx from "clsx";
import dayjs from "dayjs";
import { isEqual } from "lodash-es";

import "./styles.scss";
import { FlightBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { InfantSeatPickerModal } from "../InfantSeatPickerModal";
import { FlightError } from "@b2bportal/air-booking-api";
import { PassportAwarenessCard } from "../PassportAwarenessCard/component";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import { isCorpTenant } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";

export interface IFlightBookPassengerSelectionProps
  extends FlightBookPassengerSelectionConnectorProps {
  progress: TravelerSelectStep;
  setProgress: (progress: TravelerSelectStep) => void;
  onProgressChange?: (step: TravelerSelectStep) => void;
  onContinue?: (travelersChanged?: boolean) => void;
  onGoBack?: (travelersChanged?: boolean) => void;
  className?: string;
  selectionScreenHeaderElement?: JSX.Element;
  onReviewStep?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  combinedStep?: boolean;
  useLocalIds?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  saveButtonClicked?: boolean;
  localTravelerIds?: String[];
  setLocalTravelerIds?: (ids: string[]) => void;
  localLapInfantIds?: String[];
  setLocalLapInfantIds?: (ids: string[]) => void;
  showVoidWindowNotice?: boolean;
  showPassportAwareness?: boolean;
  hiddenTravelerIds: string[];
}

export const FlightBookPassengerSelection = (
  props: IFlightBookPassengerSelectionProps
) => {
  const {
    className,
    travelers,
    progress,
    setProgress,
    onProgressChange,
    onContinue,
    onGoBack,
    selectedTravelerIds,
    selectedLapInfantIds,
    setUserSelectedPassengerIds,
    setUserSelectedLapInfantIds,
    updateUserPassenger,
    deleteUserPassenger,
    fetchUserPassengers,
    validatePassengers,
    tripFinalFlightDate,
    userPassengerCallState,
    selectionScreenHeaderElement,
    onReviewStep,
    isMobile,
    disabled,
    combinedStep,
    useLocalIds,
    priceQuoteErrors,
    showErrors,
    setShowErrors,
    saveButtonClicked,
    setQuote,
    priceQuote,
    localLapInfantIds,
    setLocalLapInfantIds,
    localTravelerIds,
    setLocalTravelerIds,
    showVoidWindowNotice,
    showPassportAwareness,
    viewedTripSummaryProperties,
    fetchCorpUserPassengers,
  } = props;

  const streamlineTravelerProfileEnabled =
    useExperimentsById("corp-streamline-traveler-profile-fe")?.variant === "m2";

  const { matchesMobile } = useDeviceTypes();
  const [currentInfantToSelectSeat, setCurrentInfantToSelectSeat] = useState<
    string | boolean
  >(false);
  const expState = useExperiments();
  const travelersChanged =
    !isEqual(selectedTravelerIds, localTravelerIds) ||
    !isEqual(selectedLapInfantIds, localLapInfantIds);

  React.useEffect(() => {
    streamlineTravelerProfileEnabled && isCorpTenant(config.TENANT)
      ? fetchCorpUserPassengers()
      : fetchUserPassengers();
  }, []);

  React.useEffect(() => {
    if (
      travelers.length === 1 &&
      (!(travelers[0] as ICorpPerson).isMissingInfo ?? true)
    ) {
      const travelerIds = [travelers[0].id];
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: travelerIds,
        multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
      });
      validatePassengers(travelerIds, []);
    }
  }, [travelers]);

  // note: reset lap infant ids when the FE gets LapInfantsUnsupported
  React.useEffect(() => {
    if (
      priceQuoteErrors.find(
        (error) => error === FlightError.LapInfantsUnsupported
      )
    ) {
      setLocalLapInfantIds && setLocalLapInfantIds([]);
      setUserSelectedLapInfantIds({
        userSelectedLapInfantIds: [],
      });
    }
  }, [priceQuoteErrors]);

  const { sessionInfo } = useContext(ClientContext);

  const getIsLapInfant = (traveler: IPerson) => {
    const ageAtBoardingTime = dayjs(tripFinalFlightDate).diff(
      getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
      "year"
    );
    return ageAtBoardingTime < 2;
  };

  const handleSelectedTravelerIds = (travelerIds: string[]) => {
    // travelerIds -> selectedTravelerIds + selectedLapInfants + unknowns

    // Filter any removed ids from currentTravelerIds & currentLapInfantIds
    let userSelectedPassengerIdsToUpdate = selectedTravelerIds.filter((id) =>
      travelerIds.includes(id)
    );
    let userSelectedLapInfantIdsToUpdate = selectedLapInfantIds.filter((id) =>
      travelerIds.includes(id)
    );

    // If any travelers are selected, get the new travelers selected
    // - and check if there is an infant in the selected ids
    if (travelerIds.length > 0) {
      const newTravelersIds = travelerIds.filter(
        (id) =>
          !selectedTravelerIds.includes(id) &&
          !selectedLapInfantIds.includes(id)
      );
      const filteredTravelers = travelers.filter((traveler) =>
        newTravelersIds.includes(traveler.id)
      );
      const firstInfantNotInState = filteredTravelers.find((traveler) =>
        getIsLapInfant(traveler)
      );

      if (firstInfantNotInState) {
        setCurrentInfantToSelectSeat(firstInfantNotInState.id);
        // only update passengers that are not infants, since infants are added to state in the InfantSeatPickerModal
        userSelectedPassengerIdsToUpdate = travelerIds.filter(
          (id) =>
            id !== firstInfantNotInState.id &&
            !selectedLapInfantIds.includes(id)
        );
        userSelectedLapInfantIdsToUpdate = [...selectedLapInfantIds];
      } else {
        userSelectedPassengerIdsToUpdate = [
          ...userSelectedPassengerIdsToUpdate,
          ...newTravelersIds,
        ];
      }
    } else if (travelerIds.length === 0) {
      userSelectedPassengerIdsToUpdate = [];
      userSelectedLapInfantIdsToUpdate = [];
    }

    updateUserSelectedTravelerIds(
      userSelectedPassengerIdsToUpdate,
      userSelectedLapInfantIdsToUpdate
    );
  };

  const updateUserSelectedTravelerIds = (
    passengerIds: PersonId[],
    lapInfantIds: PersonId[]
  ) => {
    setUserSelectedPassengerIds({
      userSelectedPassengerIds: passengerIds,
      multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
    });
    setUserSelectedLapInfantIds({
      userSelectedLapInfantIds: lapInfantIds,
    });
  };

  const handleContinue = () => {
    if (useLocalIds && travelersChanged) {
      setLocalTravelerIds && setLocalTravelerIds(selectedTravelerIds);
      setLocalLapInfantIds && setLocalLapInfantIds(selectedLapInfantIds);
    }
    onContinue && onContinue(travelersChanged);
  };

  const handleGoBack = () => {
    if (useLocalIds && travelersChanged) {
      setLocalTravelerIds && setLocalTravelerIds(selectedTravelerIds);
      setLocalLapInfantIds && setLocalLapInfantIds(selectedLapInfantIds);
    }
    onGoBack && onGoBack(travelersChanged);
  };

  React.useEffect(() => {
    // note: need to reset PQ or else when changing pax from review screen, price breakdown will not update as it will use the last PQ ran
    if (travelersChanged && priceQuote) {
      setQuote(null);
    }
  }, [travelersChanged]);

  return (
    <>
      {userPassengerCallState === CallState.InProcess ? (
        <LoadingIndicator
          className="flight-book-passenger-selection-loading-indicator"
          indicatorSize="small"
          indicator={B2BSpinner}
          message={
            userPassengerCallState === CallState.InProcess
              ? constants.UPDATE_TEXT
              : constants.VALIDATE_TEXT
          }
        />
      ) : (
        <TravelerSelectWorkflow
          showAdditionalInfoSection
          showFrequentFlyerSection
          showGenderField
          showNationalityField
          className={clsx("flight-book-passenger-selection-root", className, {
            "combined-step": combinedStep,
          })}
          travelers={travelers}
          progress={progress}
          setProgress={setProgress}
          userInfo={sessionInfo?.userInfo}
          disabled={disabled}
          titles={{
            travelerInfoTitle: matchesMobile
              ? onReviewStep
                ? constants.TRAVELER_INFO_TEXT
                : constants.ADD_TRAVELERS_TEXT
              : constants.TRAVELER_INFO_TITLE_UPDATED,
            travelerInfoSubtitle: constants.TRAVELER_INFO_SUBTITLE,
            frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
            additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
            adultTitle: constants.ADULT_TITLE,
            childTitle: constants.CHILD_TITLE,
            infantSeatTitle: constants.INFANT_SEAT_TITLE,
            infantLapTitle: constants.INFANT_LAP_TITLE,
            addTravelers: constants.ADD_TRAVELERS_TEXT_UPDATED,
            travelerInfoFormSubtitle: constants.ADD_TRAVELERS_SUBTITLE,
            travelerInfoSectionTitle: constants.TRAVELER_INFO_TEXT,
            passportTitle: constants.PASSPORT_TITLE,
            passportSubtitle: constants.PASSPORT_SUBTITLE,
            passportSubtitle2: constants.PASSPORT_SUBTITLE_2,
            completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
            completeTravelerProfileSubtitle:
              constants.COMPLETE_PROFILE_SUBTITLE,
          }}
          selectedTravelerIds={[
            ...selectedTravelerIds,
            ...selectedLapInfantIds,
          ]}
          setSelectedTravelerIds={handleSelectedTravelerIds}
          handleUpdatePassenger={(
            traveler: IPerson,
            hideProfileAction: ToggleActions,
            entryPoint?: string,
            updatePassport?: boolean,
            isNewTraveler?: boolean
          ) => {
            const freqFlyerKeys = Object.keys(traveler.frequentFlyer);
            if (
              freqFlyerKeys.length > 0 &&
              traveler.frequentFlyer !==
                travelers.find((t) => t.id === traveler.id)?.frequentFlyer
            ) {
              trackEvent({
                eventName: ADD_FREQUENT_FLYER,
                properties: {
                  frequent_flyer_program:
                    freqFlyerKeys.length > 0
                      ? freqFlyerKeys[freqFlyerKeys.length - 1]
                      : "",
                },
              });
            }
            if (getIsLapInfant(traveler)) {
              updateUserPassenger(
                { person: traveler },
                false,
                updatePassport,
                undefined,
                isNewTraveler,
                undefined,
                streamlineTravelerProfileEnabled
              );
              setCurrentInfantToSelectSeat(traveler.id);
            } else {
              updateUserPassenger(
                {
                  person: traveler,
                  hideProfileAction: hideProfileAction,
                },
                true,
                updatePassport,
                undefined,
                isNewTraveler,
                undefined,
                streamlineTravelerProfileEnabled,
                entryPoint
              );
            }
          }}
          handleDeletePassenger={(travelerId: PersonId) =>
            deleteUserPassenger({ personId: travelerId })
          }
          isMobile={matchesMobile}
          onProgressChange={onProgressChange}
          onContinue={handleContinue}
          onGoBack={handleGoBack}
          selectionScreenHeaderElement={selectionScreenHeaderElement}
          buttonClassName="b2b"
          wrapButton={isMobile && !onReviewStep}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          saveButtonClicked={saveButtonClicked}
          errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
          isFlights={true}
          bottomContent={
            showVoidWindowNotice || showPassportAwareness ? (
              <>
                {showVoidWindowNotice && <VoidWindowNotice />}
                {showPassportAwareness && <PassportAwarenessCard />}
              </>
            ) : undefined
          }
          showPassportSection
          requireNationality
          onClickAddNewTraveler={() => {
            trackEvent({
              eventName: CLICKED_ADD_TRAVELER,
              properties: addTrackingProperties(expState.trackingProperties, {
                entry_type: "checkout",
              } as ClickedAddTravelerProperties),
            });
          }}
          tenant={config.TENANT}
          trackEvent={trackEvent}
        />
      )}
      <InfantSeatPickerModal
        currentInfantToSelectSeat={currentInfantToSelectSeat}
        setCurrentInfantToSelectSeat={setCurrentInfantToSelectSeat}
        userSelectedPassengerIds={selectedTravelerIds}
        userSelectedLapInfantIds={selectedLapInfantIds}
        setUserSelectedPassengerIds={(ids: string[]) => {
          setUserSelectedPassengerIds({
            userSelectedPassengerIds: ids,
            multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
          });
        }}
        setUserSelectedLapInfantIds={(ids: string[]) => {
          setUserSelectedLapInfantIds({
            userSelectedLapInfantIds: ids,
          });
        }}
      />
    </>
  );
};
