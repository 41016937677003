import {
  AncillaryFetchOfferFailure,
  AncillaryFetchOfferResponse,
  AncillaryFetchOfferResponseEnum,
  AncillaryFetchOfferSuccess,
  AncillaryOfferEnum,
  AncillaryKindEnum,
} from "redmond";
import { put, select } from "redux-saga/effects";

import { getAncillaryOffer } from "../../../api/v2/ancillary/getAncillaryOffer";
import {
  fetchAncillaryOfferRequestParametersSelector,
  cfarOfferFromRefundableFaresSelector,
} from "../reducer/selectors";
import { fetchAncillaryOfferRequestParametersByPriceFreezeSelector } from "../../freeze/reducer/selectors";
import { isChfarEnabledSelector } from "../../ancillary/reducer/selectors";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import Logger from "../../../helpers/Logger";

export function* fetchAncillaryOfferSaga(props: actions.IFetchAncillaryOffer) {
  try {
    const { isPriceFreezeOverview, request } = props;
    const state: IStoreState = yield select();
    const fetchOfferRequest =
      request ??
      (isPriceFreezeOverview
        ? fetchAncillaryOfferRequestParametersByPriceFreezeSelector(state)
        : fetchAncillaryOfferRequestParametersSelector(state));
    const hasCfarOfferFromRefundableFares =
      !!cfarOfferFromRefundableFaresSelector(state);

    if (!fetchOfferRequest) {
      yield put(actions.setFetchAncillaryOfferCallStateFailed());
      return;
    }

    const response: AncillaryFetchOfferResponse = yield getAncillaryOffer({
      ...fetchOfferRequest,
      // note: when there is already a CFAR offer stored through selecting a refundable fare, only DP offers need to be requested
      requestedProducts: hasCfarOfferFromRefundableFares
        ? [AncillaryKindEnum.Delay, AncillaryKindEnum.MissedConnection]
        : undefined,
    });

    const responseFailure = response as AncillaryFetchOfferFailure;

    if (responseFailure.Failure) {
      yield put(actions.setFetchAncillaryOfferCallStateFailed());
      return;
    }

    const responseSuccess = response as AncillaryFetchOfferSuccess;
    const isChfarEnabled = isChfarEnabledSelector(state);
    if (isChfarEnabled) {
      responseSuccess.ancillaryOffers.push({
        AncillaryOffer: AncillaryOfferEnum.ChfarOffer,
        id: {
          productId: "CHFAR_PRODUCT_ID_MOCK_123",
          policyId: "CHFAR_POLICY_ID_123",
        },
        premiumPerPax: {
          fiat: {
            currencyCode: "USD",
            currencySymbol: "$",
            value: 100,
          },
          rewards: {
            "t.1b13a4a2ccfd4d848796ad32bc4bc6de": {
              value: 10000,
              currency: "Miles",
              currencyDescription: "miles",
            },
          },
        },
      });
    }

    switch (responseSuccess.FetchOfferResponseV2) {
      case AncillaryFetchOfferResponseEnum.MultiTicketV2:
      case AncillaryFetchOfferResponseEnum.SingleTicket:
      case AncillaryFetchOfferResponseEnum.MultiCity:
        break;
      default:
        yield put(actions.setFetchAncillaryOfferCallStateFailed());
        return;
    }

    yield put(actions.setFetchAncillaryOfferResponse(responseSuccess));
  } catch (e) {
    yield put(actions.setFetchAncillaryOfferCallStateFailed());
    Logger.debug(e);
  }
}
