import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MobileAddOnCustomize } from "./component";
import {
  fetchAncillaryOfferCallStateSelector,
  cfarAddOnChoicePropertiesSelector,
  isDisruptionProtectionAvailableSelector,
  isCfarAvailableSelector,
  isChfarAvailableSelector,
  dpAddOnChoicePropertiesSelector,
  cfarAttachPropertiesForViewBookingAddOnSelector,
  dpOfferPropertiesForViewBookingAddOnSelector,
  viewBookingAddOnPropertiesSelector,
  hasActiveRefundableFareInFlightShopSelector,
  cfarDiscountPropertiesSelector,
  cfarOfferSelector,
} from "../../../reducer/selectors";
import { populateFlightBookQueryParams } from "../../../../book/actions/actions";
import { fetchAncillaryOffer } from "../../../actions/actions";
import { IStoreState } from "../../../../../reducers/types";
import {
  cfarSocialVariantSelector,
  isRapidRebookRenameEnabledSelector,
} from "../../../../ancillary/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    cfarAddOnChoiceProperties: cfarAddOnChoicePropertiesSelector(state),
    dpAddOnChoiceProperties: dpAddOnChoicePropertiesSelector(state),
    isDisruptionProtectionAvailable:
      isDisruptionProtectionAvailableSelector(state),
    isCfarAvailable: isCfarAvailableSelector(state),
    isChfarAvailable: isChfarAvailableSelector(state),
    viewBookingAddOnProperties: viewBookingAddOnPropertiesSelector(state),
    dpOfferPropertiesForViewBookingAddOn:
      dpOfferPropertiesForViewBookingAddOnSelector(state),
    cfarOfferPropertiesForViewBookingAddOn:
      cfarAttachPropertiesForViewBookingAddOnSelector(state),
    hasActiveRefundableFare: hasActiveRefundableFareInFlightShopSelector(state),
    cfarSocialVariant: cfarSocialVariantSelector(state),
    isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
    cfarDiscountProperties: cfarDiscountPropertiesSelector(state),
    cfarOffer: cfarOfferSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  fetchAncillaryOffer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileAddOnCustomizeConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileAddOnCustomize = withRouter(
  connector(MobileAddOnCustomize)
);
