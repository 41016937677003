import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Typography } from "@material-ui/core";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import queryStringParser from "query-string";
import clsx from "clsx";
import {
  MODAL_ALERT_CHOICE,
  ModalScreens,
  MODAL_ALERT,
  ModalButtonType,
  ModalCategoryType,
  ErrorModalType,
  FlightBookType,
  FlightShopType,
  FLIGHT_SHOP_TYPE,
  PRICE_FREEZE_ID_QUERY_PARAM,
  CallState,
  ModalAlertProperties,
} from "redmond";
import {
  PATH_BOOK,
  PATH_HOME,
  PATH_PRICE_FREEZE_OVERVIEW,
  FAQ_URL,
  PATH_TRIPS,
} from "../../../../utils/urlPaths";
import { BookingErrorModalConnectorProps } from "./container";
import { FlightShopStep } from "../../../shop/reducer";
import {
  TRY_AGAIN,
  ERROR_ICON,
  UNABLED_ICON,
  PRICE_DECREASE_ICON,
  PRICE_INCREASE_ICON,
  CONFIRM_AND_BOOK,
  UPDATE_PAYMENT_INFO,
  SEARCH_AGAIN,
  UPDATE_TRAVELERS,
  CONTINUE,
  CHOOSE_ANOTHER_FLIGHT,
  MODIFY_PAX,
  CONTINUE_WITH_OWN_SEAT,
  EDIT_PAYMENT_INFO,
  GO_TO_MY_TRIPS,
} from "../../reducer/selectors/textConstants";
import { AGENT_FEE } from "../index";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import "./styles.scss";
import { MobileFlightBookWorkflowStep } from "../../component";
import {
  Progress,
  CheckoutSteps,
  PriceFreezePurchaseSteps,
} from "../../reducer";

export interface IBookingErrorModalProps
  extends BookingErrorModalConnectorProps,
    RouteComponentProps {
  checkoutStep?: MobileFlightBookWorkflowStep;
  setCheckoutStep?: (step: any) => void;
  setLocalTravelerIds?: (ids: string[]) => void;
  setLocalLapInfantIds?: (ids: string[]) => void;
}

export const BookingErrorModal = ({
  bookingProgressList,
  priceDifference,
  errorTitles,
  hasError,
  hasFlightFreezeError,
  resetBookingErrors,
  history,
  acknowledgePriceDifference,
  acknowledgePriceFreezeFareQuoteDetails,
  populateFlightShopQueryParams,
  scheduleQuote,
  schedulePayment,
  currentSession,
  payments,
  ancillaries,
  isMissingPassport,
  origin,
  fightBookType,
  resetSelectedTrip,
  modalType,
  priceFreeze,
  setUpFlightBookParams,
  setUpFlightFreezeParams,
  resetPriceFreezePurchase,
  selectedPriceFreezeOfferData,
  generateCustomPriceFreezeOffer,
  setDisplayPriceFreezeRefundModal,
  infantOnlyError,
  setInfantOnlyError,
  checkoutStep,
  setCheckoutStep,
  priceQuote,
  schedulePaymentCallState,
  selectedLapInfantIds,
  selectedPaxIds,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
  setLocalLapInfantIds,
  setLocalTravelerIds,
  isFlightBookWithAncillariesActive,
  resetPaymentCardSelectedAccounts,
  viewedTripSummaryProperties,
}: IBookingErrorModalProps) => {
  // checking for the existence of priceFreezeId to make sure that we are in the
  // air PF exercise flow
  const priceFreezeId = new URLSearchParams(history.location.search).get(
    PRICE_FREEZE_ID_QUERY_PARAM
  );
  const priceFreezeExerciseFlow = priceFreezeId ? true : false;
  const {
    primaryButtonText,
    secondaryButtonText,
    type: errorModalType,
    subtitle,
    mobileSubtitle,
    useHtml,
  } = errorTitles;
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  const [isOpen, setIsOpen] = useState(false);
  const displayedSubtitle =
    matchesMobile && !!mobileSubtitle ? mobileSubtitle : subtitle;
  const [ranScheduleQuote, setRanScheduleQuote] = useState(false);

  const priceFreezeFlow =
    errorModalType === ErrorModalType.PRICE_FREEZE_EXERCISE_REFUND ||
    errorModalType ===
      ErrorModalType.PRICE_FREEZE_EXERCISE_SIMILAR_FLIGHTS_AND_REFUND;

  const getModalEventPropetiesDescription = () => {
    switch (errorModalType) {
      case ErrorModalType.PRICE_FREEZE_EXERCISE_REFUND:
        return {
          description: "PFNoAvailRefund when exercising price freeze",
        };
      case ErrorModalType.PRICE_FREEZE_EXERCISE_SIMILAR_FLIGHTS_AND_REFUND:
        return {
          description: "PFNoAvailSimilarFlight when exercising price freeze",
        };
      default:
        return undefined;
    }
  };

  const getCheckoutStep = (
    progressList: {
      status: Progress;
      name: string;
      type: CheckoutSteps | PriceFreezePurchaseSteps;
    }[]
  ): string => {
    const progress = progressList.find(
      (p) => p.status === Progress.IN_PROGRESS
    );
    return progress?.name || "flights_checkout";
  };

  const getTransferredFlight = () =>
    priceFreezeFlow
      ? {
          price_freeze_transferred:
            priceFreeze?.priceFreeze.actions.transferTime !== undefined,
        }
      : undefined;

  const modalEventProperties: ModalAlertProperties = {
    type: modalType,
    primary_button: errorTitles.primaryButtonText!,
    secondary_button: SEARCH_AGAIN,
    screen:
      fightBookType === FlightBookType.PRICE_FREEZE_PURCHASE
        ? ModalScreens.AIR_PRICE_FREEZE_CHECKOUT
        : ModalScreens.FLIGHTS_CHECKOUT,
    category: ModalCategoryType.TROUBLE,
    ...getModalEventPropetiesDescription(),
    ...getTransferredFlight(),
    modal_subtitle: errorTitles.subtitle!,
    modal_title: errorTitles.title,
    agent_title: errorTitles.agentTitle!,
    agent_subtitle: errorTitles.agentSubtitle!,
    funnel: "flights",
    step: getCheckoutStep(bookingProgressList),
  };

  useEffect(() => {
    if (hasError || hasFlightFreezeError)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          ...modalEventProperties,
          price_freeze_flow: priceFreezeExerciseFlow,
        },
      });
  }, [hasError]);

  useEffect(() => {
    // if we have error during scheduleQuote (where it's not price quote only), we can handle error
    if (priceQuote && matchesMobile && ranScheduleQuote && setCheckoutStep) {
      hasError
        ? checkoutStep !== MobileFlightBookWorkflowStep.RewardsAndPayment &&
          setCheckoutStep(MobileFlightBookWorkflowStep.RewardsAndPayment)
        : checkoutStep !== MobileFlightBookWorkflowStep.Review &&
          setCheckoutStep(MobileFlightBookWorkflowStep.Review);
    }
  }, [priceQuote]);

  const scrollToTravelers = () => {
    const travelerSectionElement = document.querySelector(
      ".traveler-select-workflow-container"
    );

    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT = matchesMobile
      ? 0
      : document.querySelector(".app-header")?.getBoundingClientRect().height ??
        0;
    const MARGIN = matchesMobile ? 18 : 20;

    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);

    const y =
      (travelerSectionElement?.getBoundingClientRect().top ?? 0) + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  /**
   * @description Spirtitual successor to `handleClickButton` that de-couples
   * the callback from the button text or modal type
   * @param {string} actionKey A key to get the callback by. May or may not be the button text
   * @param {boolean} [isPrimary=false] Used for event tracking
   */
  const handleButtonClick = (actionKey: string, isPrimary = false) => {
    handleResetErrors();
    setIsOpen(false);
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...modalEventProperties,
        button_choice: isPrimary
          ? ModalButtonType.PRIMARY
          : ModalButtonType.SECONDARY,
      },
    });

    switch (actionKey) {
      case SEARCH_AGAIN:
        resetSelectedTrip();
        redirectToShop();
        break;
      case CHOOSE_ANOTHER_FLIGHT:
        redirectToShop();
        break;
      default:
    }
  };

  /**
   * @description A function that returns an on-click callback that performs
   * different actions based on the text on the button.
   * @param {boolean} isPrimary Whether or not the primary button was clicked
   */
  const handleClickButton = (isPrimary: boolean) => () => {
    const buttonText = isPrimary ? primaryButtonText : secondaryButtonText;
    const choice = isPrimary
      ? ModalButtonType.PRIMARY
      : ModalButtonType.SECONDARY;

    // note: this switch statement controls closing modal / resetting book errors
    switch (errorModalType) {
      case ErrorModalType.PRICE_FREEZE_EXERCISE_GENERIC_FAILED:
        if (isPrimary) {
          handleResetErrors();
        }
        break;
      default:
        handleResetErrors();
        break;
    }

    if (priceDifference.hasDifference) {
      acknowledgePriceDifference();
    }

    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...modalEventProperties,
        button_choice: choice,
      },
    });

    switch (buttonText) {
      case GO_TO_MY_TRIPS: {
        history.push({
          pathname: PATH_TRIPS,
        });
        break;
      }
      case CONFIRM_AND_BOOK: {
        // if (selectedTrip.tripId) {
        //   paymentRequest && priceQuoteSuccessful
        //     ? schedulePayment(paymentRequest, isAgentPortal ? AGENT_FEE : 0)
        //     : scheduleQuote({agentFee: isAgentPortal ? AGENT_FEE : 0});
        // }
        break;
      }
      case EDIT_PAYMENT_INFO: {
        handleResetErrors(true);
        break;
      }
      case UPDATE_PAYMENT_INFO: {
        switch (errorModalType) {
          case ErrorModalType.FLIGHTS_PRICE_QUOTE_HAS_PRICE_DIFFERENCE:
            acknowledgePriceDifference();
            break;
          default:
            break;
        }
        break;
      }
      case TRY_AGAIN: {
        // TODO: determining the funtionality of a button by its button text value feels limiting,
        // because it can end up having multiple different CTAs sharing the same button text
        switch (errorModalType) {
          case ErrorModalType.PRICE_FREEZE_EXERCISE_SET_BOOK_PARAMS_FAILED:
            setUpFlightBookParams({ history });
            break;
          case ErrorModalType.PRICE_FREEZE_PURCHASE_SET_BOOK_PARAMS_FAILED:
            resetPriceFreezePurchase();
            setUpFlightFreezeParams({ history, isMobile: matchesMobile });
            break;
          case ErrorModalType.PRICE_FREEZE_EXERCISE_GENERIC_FAILED:
            // note: handled the PRICE_FREEZE_EXERCISE_GENERIC_FAILED case so that it doesn't count as default
            break;
          case ErrorModalType.NO_ADULT_PASSENGER:
            scheduleQuote({
              agentFee: isAgentPortal ? AGENT_FEE : 0,
              isPriceFreezePurchase:
                fightBookType === FlightBookType.PRICE_FREEZE_PURCHASE,
              pollQuoteOnly: true,
            });
            break;
          default:
            if (currentSession && payments && ancillaries) {
              scheduleQuote({
                agentFee: isAgentPortal ? AGENT_FEE : 0,
                isPriceFreezePurchase:
                  fightBookType === FlightBookType.PRICE_FREEZE_PURCHASE,
              });
              if (matchesMobile && setCheckoutStep) {
                // setting ranScheduleQuote here to go into useEffect above
                setRanScheduleQuote(true);
              }
            }
            break;
        }
        break;
      }
      case UPDATE_TRAVELERS: {
        if (infantOnlyError) {
          setInfantOnlyError(false);
        }
        break;
      }
      case CONTINUE:
        switch (errorModalType) {
          case ErrorModalType.FLIGHT_INACTIVITY:
          case ErrorModalType.PROVIDER_ERROR:
            if (currentSession && payments && ancillaries) {
              scheduleQuote({
                agentFee: isAgentPortal ? AGENT_FEE : 0,
                isPriceFreezePurchase:
                  fightBookType === FlightBookType.PRICE_FREEZE_PURCHASE,
                pollQuoteOnly: true,
              });
            }
            if (matchesMobile && setCheckoutStep) {
              // setting this to contact info so when we do get price quote, it'll increment step on MobileFlightBookingWorkflow to be seats or rewards/payment
              setCheckoutStep(MobileFlightBookWorkflowStep.ContactInformation);
            }
            break;
          default:
            break;
        }
        break;
      case CHOOSE_ANOTHER_FLIGHT: {
        redirectToShop();
        break;
      }
      case MODIFY_PAX: {
        if (matchesMobile && setCheckoutStep) {
          setCheckoutStep(MobileFlightBookWorkflowStep.TravelerInformation);
        } else {
          scrollToTravelers();
        }
        break;
      }
      case CONTINUE_WITH_OWN_SEAT: {
        setUserSelectedPassengerIds({
          userSelectedPassengerIds: [
            ...selectedPaxIds,
            ...selectedLapInfantIds,
          ],
          multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
        });
        setUserSelectedLapInfantIds({
          userSelectedLapInfantIds: [],
        });
        setLocalLapInfantIds && setLocalLapInfantIds([]);
        setLocalTravelerIds &&
          setLocalTravelerIds([...selectedPaxIds, ...selectedLapInfantIds]);
        scheduleQuote({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isPriceFreezePurchase:
            fightBookType === FlightBookType.PRICE_FREEZE_PURCHASE,
          pollQuoteOnly: true,
        });
        break;
      }
      default:
        break;
    }

    const handlePriceFreezePurchasePopulateFlightShopQueryParams = () =>
      populateFlightShopQueryParams({
        history,
        prevPath: PATH_BOOK,
        useHistoryPush: true,
        forceQueryUpdate: true,
        newQueryParams: {
          [FLIGHT_SHOP_TYPE]: FlightShopType.PRICE_FREEZE_PURCHASE,
          flightShopProgress: FlightShopStep.ChooseDeparture,
        },
      });
    const handlePriceFreezePurchaseReschedulePayment = () => {
      if (schedulePaymentCallState !== CallState.InProcess) {
        schedulePayment({
          ancillaries: [],
          agentFee: undefined,
          isPriceFreezePurchase: true,
        });
      }
    };
    // TODO: button functionality should be determined by the modal type, intead of through the button text (the previous switch statement);
    // too many examples of buttons with the same text that need to have different functions because they are from different modals.
    switch (errorModalType) {
      case ErrorModalType.PRICE_FREEZE_PURCHASE_GENERIC_FAILED:
        if (isPrimary) {
          handlePriceFreezePurchasePopulateFlightShopQueryParams();
        }
        break;
      case ErrorModalType.PRICE_FREEZE_PURCHASE_GENERATE_CUSTOM_OFFER_FAILED:
        if (isPrimary) {
          if (selectedPriceFreezeOfferData) {
            generateCustomPriceFreezeOffer(
              selectedPriceFreezeOfferData,
              history,
              false,
            );
          }
        } else {
          handlePriceFreezePurchasePopulateFlightShopQueryParams();
        }
        break;
      case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED_DURING_PAYMENT:
      case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED:
        if (isPrimary) {
          acknowledgePriceFreezeFareQuoteDetails();
          if (
            errorModalType ===
            ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED_DURING_PAYMENT
          ) {
            handlePriceFreezePurchaseReschedulePayment();
          }
        } else {
          populateFlightShopQueryParams({
            history,
            useHistoryPush: true,
            forceQueryUpdate: true,
            newQueryParams: {
              [FLIGHT_SHOP_TYPE]: FlightShopType.PRICE_FREEZE_PURCHASE,
              flightShopProgress: FlightShopStep.ChooseDeparture,
            },
          });
        }
        break;
      case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED_DURING_PAYMENT:
      case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED:
        acknowledgePriceFreezeFareQuoteDetails();
        if (
          errorModalType ===
          ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED_DURING_PAYMENT
        ) {
          handlePriceFreezePurchaseReschedulePayment();
        }
        break;
      case ErrorModalType.PRICE_FREEZE_PURCHASE_HAS_NO_AVAILABILITY:
        if (isPrimary) {
          populateFlightShopQueryParams({
            history,
            useHistoryPush: true,
            forceQueryUpdate: true,
            newQueryParams: {
              [FLIGHT_SHOP_TYPE]: FlightShopType.PRICE_FREEZE_PURCHASE,
              flightShopProgress: FlightShopStep.ChooseDeparture,
            },
          });
        } else {
          acknowledgePriceFreezeFareQuoteDetails();
        }
        break;
      case ErrorModalType.PRICE_FREEZE_EXERCISE_GENERIC_FAILED:
        if (isPrimary) {
          history.push({
            pathname: PATH_PRICE_FREEZE_OVERVIEW,
            search: queryStringParser.stringify({
              [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreeze?.priceFreeze.id,
            }),
          });
        } else {
          window.open(FAQ_URL, "_blank")?.focus();
        }
        break;
      case ErrorModalType.PRICE_FREEZE_EXERCISE_SIMILAR_FLIGHTS_AND_REFUND:
        if (isPrimary) {
          populateFlightShopQueryParams({
            history,
            prevPath: PATH_BOOK,
            useHistoryPush: true,
            forceQueryUpdate: true,
            newQueryParams: {
              [FLIGHT_SHOP_TYPE]: FlightShopType.PRICE_FREEZE_EXERCISE,
              [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreeze?.priceFreeze.id,
              flightShopProgress: FlightShopStep.ChooseDeparture,
            },
          });
        } else {
          setDisplayPriceFreezeRefundModal(true);
        }
        break;
      case ErrorModalType.PRICE_FREEZE_EXERCISE_REFUND:
        if (isPrimary) {
          setDisplayPriceFreezeRefundModal(true);
        }
        break;
      case ErrorModalType.PRICE_FREEZE_EXERCISE_PROVIDER_ERROR:
        if (isPrimary) {
          window.open(FAQ_URL, "_blank")?.focus();
        }
        break;
      case ErrorModalType.FLIGHTS_PRICE_QUOTE_HAS_NO_AVAILABILITY:
        if (isPrimary) {
          handleSearchAgain();
        }
        break;
      case ErrorModalType.FLIGHTS_PRICE_QUOTE_HAS_PRICE_DIFFERENCE:
        /*
          note: in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, the price quote can be invalidated by changing any
          of the ancillary seleciton options, and therefore requiring the user to reselect payment method to accommodate a new price quote
        */
        if (isFlightBookWithAncillariesActive) {
          resetPaymentCardSelectedAccounts();
        }
        break;
      case ErrorModalType.FLIGHTS_PRICE_QUOTE_WITH_ADD_ON_HAS_PRICE_DIFFERENCE:
        if (!isPrimary) {
          redirectToShop();
        }
        break;
      default:
        break;
    }
  };

  const handleResetErrors = (isPostQuote?: boolean) => {
    setIsOpen(false);
    resetBookingErrors(isPostQuote);
  };

  const handleSearchAgain = () => {
    resetSelectedTrip();

    // note: all "Search again" ctas should go to the flight list
    // if we don't have the state to populate flight shop navigate to search page
    if (origin) {
      redirectToShop();
    } else {
      history.push(PATH_HOME);
    }
  };

  const getButtons = () => {
    let buttons = [];

    // buttons intentionally saved in reverse
    if (secondaryButtonText) {
      buttons.push({
        buttonText: secondaryButtonText,
        onClick: handleClickButton(false),
        defaultStyle: "h4r-secondary",
      });
    }

    if (primaryButtonText) {
      buttons.push({
        buttonText: primaryButtonText,
        onClick: handleClickButton(true),
        defaultStyle: "h4r-primary",
      });
    }

    // fallback so that at least one button shows up that can close the modal
    if (!primaryButtonText && !secondaryButtonText) {
      buttons.push(
        {
          buttonText: SEARCH_AGAIN,
          defaultStyle: "h4r-secondary",
          onClick: () => handleButtonClick(SEARCH_AGAIN),
        },
        {
          buttonText: CHOOSE_ANOTHER_FLIGHT,
          defaultStyle: "h4r-primary",
          onClick: () => handleButtonClick(CHOOSE_ANOTHER_FLIGHT, true),
        }
      );
    }

    // note: this buttons reverse logic should be refactored by applying it to specific errorModalType.
    if (
      (priceDifference.hasDifference || errorTitles.primaryButtonText) &&
      errorModalType !== ErrorModalType.PRICE_FREEZE_EXERCISE_GENERIC_FAILED &&
      errorModalType !==
        ErrorModalType.FLIGHTS_PRICE_QUOTE_WITH_ADD_ON_HAS_PRICE_DIFFERENCE
    ) {
      buttons = [...buttons].reverse();
    }

    return buttons;
  };

  const getIconImage = (type: string) => {
    const iconMap = {
      [ERROR_ICON]: <Icon className="error-icon" name={IconName.ErrorState} />,
      [UNABLED_ICON]: (
        <Icon className="error-icon" name={IconName.UnableToProcess} />
      ),
      [PRICE_DECREASE_ICON]: (
        <Icon className="error-icon" name={IconName.ModalPriceDrop} />
      ),
      [PRICE_INCREASE_ICON]: (
        <Icon className="error-icon" name={IconName.PriceIncrease} />
      ),
    };

    return iconMap[type] || iconMap[ERROR_ICON];
  };

  const redirectToShop = () => {
    populateFlightShopQueryParams({
      history,
      prevPath: PATH_BOOK,
      useHistoryPush: true,
      forceQueryUpdate: true,
      newQueryParams: {
        flightShopProgress: FlightShopStep.ChooseDeparture,
      },
    });
  };

  useEffect(() => {
    setIsOpen((hasError || hasFlightFreezeError) && !isMissingPassport);
  }, [hasError, hasFlightFreezeError, isMissingPassport]);

  return (
    <GenericInfoPopup
      agentSubtitle={errorTitles.agentSubtitle}
      agentTitle={errorTitles.agentTitle}
      buttons={getButtons()}
      // TODO: not the best solution out there, but some customization is possbile through adding errorModalType as the className
      className={clsx("flights-booking-error-modal", errorModalType, {
        mobile: matchesMobile,
      })}
      image={getIconImage(errorTitles.icon as string)}
      isMobile={matchesMobile}
      isAgent={isAgentPortal}
      open={isOpen}
      subtitle={
        useHtml && displayedSubtitle ? (
          <Typography
            variant="inherit"
            dangerouslySetInnerHTML={{ __html: displayedSubtitle }}
          />
        ) : (
          displayedSubtitle
        )
      }
      title={errorTitles.title}
      // note: mobile popovers in the checkout workflow have z-index: 1300
      zIndex={matchesMobile ? 1301 : 1300}
    />
  );
};
