import React from "react";
import { Box, Dialog, Slide, Typography } from "@material-ui/core";
import { TripDetails, FareDetails, Airport } from "redmond";
import {
  ActionButton,
  MixedCabinToolTip,
  ButtonWrap,
  CloseButtonIcon,
  removeTimezone,
} from "halifax";

import { getSliceIndex } from "../../../../../../utils/flights";
import { FlightShopReviewDetails } from "../../../index";
import * as constants from "./constants";
import clsx from "clsx";
import "./styles.scss";
import { getMulticityReviewHeader } from "../../../FlightShopReviewItinerary/constants";
import { Airport as b2bAirport } from "@b2bportal/air-shopping-api";
import dayjs from "dayjs";
import { IVirtualInterliningVariant } from "../../../VirtualIinterliningModal";

interface IDesktopFlightReviewDetailsPopoverProps {
  isMulticity?: boolean;
  multicitySliceIndex?: number;
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMultiTicket: boolean;
  openPopover: boolean;
  onClose?: () => void;
  onClickEdit?: () => void;
  airports: { [key: string]: Airport };
  isMixedCabinClass?: boolean;
  hasActiveRefundableFare: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  isVITripSelected: boolean;
  setOpenVIVariantModal: (variant: IVirtualInterliningVariant) => void;
  setOpenMultipleAirlinesFares: (value: boolean) => void;
  disableChangeFlight: boolean;
}

export const DesktopFlightReviewDetailsPopover = (
  props: IDesktopFlightReviewDetailsPopoverProps
) => {
  const {
    isMulticity,
    multicitySliceIndex,
    departure,
    tripDetails,
    fareDetails,
    isMultiTicket,
    openPopover,
    onClose,
    onClickEdit,
    airports,
    isMixedCabinClass,
    hasActiveRefundableFare,
    isSeatsUXOptimizationExperiment,
    isVITripSelected,
    setOpenVIVariantModal,
    setOpenMultipleAirlinesFares,
    disableChangeFlight,
  } = props;
  const sliceIndex =
    multicitySliceIndex ?? getSliceIndex(departure, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];

  const renderHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(isMulticity ? "-" : ":");
    return (
      <Typography variant="body1" className="flight-details-header">
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const renderReviewDetails = () => {
    const originAirport = airports[tripSlice.originCode] as b2bAirport;
    const destinationAirport = airports[
      tripSlice.destinationCode
    ] as b2bAirport;

    const header = isMulticity
      ? getMulticityReviewHeader(
          multicitySliceIndex!,
          originAirport,
          destinationAirport,
          dayjs(removeTimezone(tripSlice.departureTime)).toDate(),
          true
        )
      : constants.getSummaryPanelHeader(
          departure,
          airports[tripSlice.destinationCode]
            ? airports[tripSlice.destinationCode].cityName
            : tripSlice.destinationName,
          removeTimezone(tripSlice.departureTime),
          true
        );

    return (
      <Box className="desktop-flight-review-details-root">
        <Box className="desktop-flight-review-details-container">
          <Box className="header-section">
            <Box
              className="header-text"
              id="review-flight-details-header"
              tabIndex={-1}
            >
              {renderHeader(header)}
            </Box>
            {!disableChangeFlight && !!onClickEdit && (
              <ActionButton
                className="change-flight-button-wrapper"
                buttonClassName="change-flight-button"
                defaultStyle="h4r-secondary"
                message={constants.CHANGE_FLIGHT_TEXT}
                onClick={onClickEdit}
              />
            )}
          </Box>
          <Box className="content-section">
            <FlightShopReviewDetails
              multicitySliceIndex={multicitySliceIndex}
              isMulticity={isMulticity}
              departure={departure}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              isMultiTicket={isMultiTicket}
              isMixedCabinClass={isMixedCabinClass}
              hasActiveRefundableFare={hasActiveRefundableFare}
              isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
              isVITripSelected={isVITripSelected}
              setOpenVIVariantModal={setOpenVIVariantModal}
              setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      hideBackdrop={false}
      TransitionComponent={Slide}
      TransitionProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of TransitionProps to include direction.
        direction: "up",
        /* eslint-enable */
        role: "none",
      }}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      className={clsx(
        "desktop-popover-card-root",
        "desktop-flight-review-details-popup"
      )}
      onClick={(event) => {
        event.stopPropagation();
      }}
      role="dialog"
      aria-labelledby="review-flight-details-header"
      open={openPopover}
      onClose={onClose}
      invisibleBackdrop={false}
    >
      <Box className={clsx("desktop-popover-card-top-button", "right")}>
        <ButtonWrap aria-label="Close" onClick={onClose}>
          <CloseButtonIcon />
        </ButtonWrap>
      </Box>
      {renderReviewDetails()}
    </Dialog>
  );
};
