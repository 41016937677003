import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../reducers/types";
import {
  priceFreezeOfferDataSelector,
  selectedPriceFreezeOfferTtlSelector,
  selectedPriceFreezeOfferPricesSelector,
  selectedAndValidPriceFreezeOfferDataIndexSelector,
  changedPriceFreezeDurationPropertiesSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  priceFreezeVoidWindowSelector,
  priceFreezeVoidWindowEndSelector,
  isPriceFreezeDurationShortActiveSelector,
} from "../../../reducer/selectors";
import {
  setSelectedPriceFreezeOfferDataIndex,
  generateCustomPriceFreezeOffer,
} from "../../../actions/actions";
import { PriceFreezeDurationSelection } from "./component";
import { getSelectedAccountReferenceId } from "../../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceFreezeOfferDataList: priceFreezeOfferDataSelector(state),
    selectedPriceFreezeOfferTtl: selectedPriceFreezeOfferTtlSelector(state),
    priceFreezeOfferPrices: selectedPriceFreezeOfferPricesSelector(state),
    selectedPriceFreezeOfferDataIndex:
      selectedAndValidPriceFreezeOfferDataIndexSelector(state),
    changedPriceFreezeDurationProperties:
      changedPriceFreezeDurationPropertiesSelector(state),
    isPriceFreezeDurationPopupEnabled:
      isPriceFreezeDurationPopupEnabledSelector(state),
    selectedAccountReferenceId: getSelectedAccountReferenceId(state),
    priceFreezeVoidWindow: priceFreezeVoidWindowSelector(state),
    priceFreezeVoidWindowEnd: priceFreezeVoidWindowEndSelector(state),
    isPriceFreezeDurationShortActive: isPriceFreezeDurationShortActiveSelector(state),
  };
};

const mapDispatchToProps = {
  setSelectedPriceFreezeOfferDataIndex,
  generateCustomPriceFreezeOffer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeDurationSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeDurationSelection = withRouter(
  connector(PriceFreezeDurationSelection)
);
