import { FiatPrice, RewardsPrice } from "redmond";
import { getTotalPriceText, getRewardText, twoDecimalFormatter } from "halifax";

/*
  note: ESTIMATED_CHFAR_CONTENT_HEIGHT is actually an upper bound to the height of CFAR content (with image-background header);
  in the future, it can be removed if we were to find a better solution. DIALOG_MARGIN is the same as the margin in MuiDialog-paperScrollPaper,
  which comes from material-ui.Dialog; MuiDialog-paperScrollPaper has max-height set to calc(100% - 2 * 32px)
*/
export const ESTIMATED_CHFAR_CONTENT_HEIGHT = 750;
export const DIALOG_MARGIN = 32;
export const PARTIAL_SCROLL_THRESHOLD = `(max-height: ${
  ESTIMATED_CHFAR_CONTENT_HEIGHT + 2 * DIALOG_MARGIN
}px)`;
export const LOCATION_TEXT = "Munich, Germany";
export const TOP_CONTENT_HEADER_TEST =
  "<strong>Change for<strong/> <br>any reason";
export const NO_OPTION_COPY = "No thanks, I do not want this option";
export const YES_OPTION_COPY = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined
) =>
  `Yes, add this option for <strong>${getTotalPriceText({
    price: fiat,
    priceFormatter: twoDecimalFormatter,
  })}</strong>${
    reward
      ? ` / ${getRewardText({
          reward,
        })}`
      : ""
  } per traveler.`;
export const CHANGE_DESCRIPTION_COPY = `Change your dates, time, origin, destination, airline, or anything else up until departure.`;
export const WE_COVER_COPY = `We cover 100% of your original base fare and taxes.`;
export const CHANGE_NO_CONTACT_COPY = `Change your flight for any reason through Capital One Travel without contacting the airline or completing forms.`;
export const CHANGE_WITHIN_ONE_YEAR_COPY = `You can change your flight to depart within 1 year before or after your original departure date. Upgrades and baggage fees are not included.`;
export const CHFAR_NO_OPTION_SELECTED_COPY =
  "You must add or decline this option to continue.";
export const READ_TERMS_AND_CONDITIONS_COPY = "Read terms and conditions";
