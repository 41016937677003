import { getRewardsString } from "halifax";
import { RewardsPrice } from "redmond";

export const TITLE =
  "Flight disruption assistance successfully added to your trip!";
export const SUBTITLE_EARN = (rewardsText: RewardsPrice) => {
  return `You’ve earned <b>${getRewardsString(
    rewardsText
  )}</b> by adding flight disruption assistance to your trip!`;
};
export const SUBTITLE_EMAIL = (email: string) => {
  return `An email confirmation will be sent to ${email}.`;
};
export const START_NEW_SEARCH = "Start a new search";
export const VIEW_TRIP_DETAILS = "View trip details";
export const WHATS_NEXT = "What’s next?";
export const STEP_ONE =
  "We’ll monitor your flight and alert you in minutes if we detect a disruption.";
export const STEP_TWO =
  "If you experience a cancellation, delay or missed connection, you can:";
export const STEP_TWO_BODY =
  "Easily rebook on any airline on Capital One Travel for free or choose a refund and keep your original flight from the airline.";
export const STEP_TWO_BODY_COPY_NEW =
  "Easily rebook on any airline on Capital One Travel for free (up to $5,000 per traveler) or, if you are not happy with the rebooking options, you can receive a refund of your booking (base fare + taxes) and keep your original flight.";
