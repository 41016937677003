export const getPriceAndRewardsCopy = ({
  price,
  rewards,
  separator,
}: {
  price: string;
  rewards?: string;
  separator?: string;
}) =>
  `<strong>${price}</strong>${
    rewards ? ` ${separator ?? "/"} ${rewards}` : ""
  }`;

export const getDiscountedPriceAndRewardsCopy = ({
  origPrice,
  discountedPrice,
  rewards,
}: {
  origPrice: string;
  discountedPrice: string;
  rewards?: string;
}) =>
  `<span class="cross-out">${origPrice}</span> <span class="green-text">${discountedPrice}${
    rewards ? ` / ${rewards}` : ""
  }</span>`;

export const getStepCopy = (step: number, ancillary: string) =>
  `Step ${step}: ${ancillary}`;

export const CFAR_SUBTITLE_COPY =
  "Add the flexibilty to cancel your flight for any reason.";

export const DISRUPTION_PROTECTION_SUBTITLE_COPY =
  "We’re here to help if you experience a flight disruption while traveling.";

export const CONTINUE_TO_CHECKOUT_COPY = "Continue to checkout";

export const BACK = "Back";

export const CONTINUE = "Continue";

export const AIR_OFFER_REDESIGN_CLASSNAME = "air-offer-redesign";
