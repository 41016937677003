import React, { useContext, useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  CheckoutStepper,
  ButtonWrap,
  ActionButton,
  useDeviceTypes,
  emailRegex,
  BannerSeverity,
  NotificationBanner,
  Icon,
  IconName,
} from "halifax";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";

import { DesktopFlightFreezeWorkflowConnectorProps } from "./container";
import { FlightFreezeSummary } from "../FlightFreezeSummary";
import { FlightFreezeReviewItinerary } from "../FlightFreezeReviewItinerary";
import { FlightFreezeTravelers } from "../FlightFreezeTravelers";
import { FlightFreezePriceBreakdown } from "../FlightFreezePriceBreakdown";
import { FlightFreezeContactInfoForm } from "../FlightFreezeContactInfoForm";
import { DesktopPriceFreezeDurationDetails } from "../priceFreezeDurationComponents";
import { PaymentCard } from "../../../book/components/PaymentCard";
import {
  PATH_FREEZE_CONFIRMATION,
  PATH_HOME,
} from "../../../../utils/urlPaths";
import * as t from "./textConstants";
import { ClientContext } from "../../../../App";
import "./styles.scss";
import { AVAILABLE, PRICE_FREEZE_TRAVEL_CREDITS, PRICE_FREEZE_VOID_WINDOW_NAV_DISCLOSURE, TRAVEL_WALLET_OFFER_EXPERIMENT, getExperimentVariant, useExperiments } from "../../../../context/experiments";
import dayjs from "dayjs";
import { FlightShopStep } from "redmond";

export interface IDesktopFlightFreezeWorkflowProps
  extends RouteComponentProps,
  DesktopFlightFreezeWorkflowConnectorProps {
  useLockPriceLanguage?: boolean;
  disableChangeFlight: boolean;
}

interface IDesktopFlightFreezeStep {
  reviewItineraryStep: number;
  priceFreezeDurationStep?: number;
  travelersStep: number;
  paymentCardStep: number;
}

export const DesktopFlightFreezeWorkflow = ({
  freezeProgress,
  history,
  contactInfo,
  isBookingValid,
  schedulePayment,
  finalizedPriceFreeze,
  isTravelerStepComplete,
  useLockPriceLanguage,
  isPriceFreezeDurationActive,
  isPriceFreezeDurationPopupEnabled,
  priceFreezeVoidWindow,
  priceFreezeVoidWindowEnd,
  priceFreezeDuration,
  disableChangeFlight,
  isPriceFreezeShowDurationsVariant1Active,
  priceFreezeEntryPoint,
  fetchApplicableTravelWalletItems,
}: IDesktopFlightFreezeWorkflowProps) => {
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(
    !emailRegex.test(contactInfo.email)
  );
  const travelersDivRef = React.useRef<HTMLDivElement | null>(null);
  const paymentCardDivRef = React.useRef<HTMLDivElement | null>(null);
  const { logo, isAgentPortal } = clientContext;
  const expState = useExperiments();
  const showPriceFreezeDurationSlider =
    isPriceFreezeDurationActive &&
    !isPriceFreezeDurationPopupEnabled &&
    !(
      isPriceFreezeShowDurationsVariant1Active &&
      priceFreezeEntryPoint === FlightShopStep.ReviewItinerary
    );

  const isFromFlightShopReviewItinerary = priceFreezeEntryPoint === 3;

  const ClientLogo = (
    <ButtonWrap
      onClick={() => {
        history.push(PATH_HOME);
      }}
    >
      {logo}
    </ButtonWrap>
  );
  const {
    reviewItineraryStep,
    priceFreezeDurationStep,
    travelersStep,
    paymentCardStep,
  }: IDesktopFlightFreezeStep = (() => {
    if (showPriceFreezeDurationSlider) {
      return {
        reviewItineraryStep: 1,
        priceFreezeDurationStep: 2,
        travelersStep: 3,
        paymentCardStep: 4,
      };
    } else {
      return {
        reviewItineraryStep: 1,
        travelersStep: 2,
        paymentCardStep: 3,
      };
    }
  })();
  const scrollToCardContent = (
    divRef: React.MutableRefObject<HTMLDivElement | null>,
    position?: "center" | "top"
  ) => {
    const offsetTop = divRef?.current?.offsetTop ?? 0;
    const offsetHeight = divRef?.current?.offsetHeight ?? 0;
    const top = (() => {
      switch (position) {
        case "top":
          return offsetTop + offsetHeight;
        case "center":
        default:
          return offsetTop - offsetHeight;
      }
    })();

    window.scrollTo({ top, behavior: "smooth" });
  };

  useEffect(() => {
    if (finalizedPriceFreeze) {
      history.push(PATH_FREEZE_CONFIRMATION);
    }
  }, [finalizedPriceFreeze]);

  const isPriceFreezePurchase = true;

  let showVoidWindowBanner;
  if (priceFreezeVoidWindowEnd) {
    if (priceFreezeVoidWindowEnd === "Unavailable") {
      showVoidWindowBanner = false;
    } else {
      showVoidWindowBanner = dayjs().isBefore(dayjs(priceFreezeVoidWindowEnd));
    }
  }

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );
  const priceFreezeTravelCredits = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE_TRAVEL_CREDITS
  );
  const isPriceFreezeTravelCreditsAvailable = useMemo(
    () => priceFreezeTravelCredits === AVAILABLE,
    [priceFreezeTravelCredits]
  );

  useEffect(() => {
    isTravelWalletOfferExperiment && isPriceFreezeTravelCreditsAvailable && fetchApplicableTravelWalletItems();
    // isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory(); not currently using the history
  }, []);

  return (
    // TODO: migrate CheckoutTemplate to Halifax for reusability
    <Box className="desktop-flight-freeze-workflow-root">
      <CheckoutStepper
        steps={freezeProgress}
        headerCopy={t.HEADER_COPY(useLockPriceLanguage)}
        subHeaderCopy={t.SUBHEADER_COPY}
        logo={ClientLogo}
        className={clsx("b2b", "combined-step")}
      />
      <Box className="desktop-flight-freeze-workflow-container">
        <Box className={clsx("freeze-template-column", "left")}>
          <Box
            className={clsx(
              "freeze-template-card-content-container",
              "summary"
            )}
          >
            <FlightFreezeSummary
              displayPriceFreezeChangesContent
              useLockPriceLanguage={useLockPriceLanguage}
              isFromFlightShopReviewItinerary={isFromFlightShopReviewItinerary}
            />
          </Box>
          <Box
            className={clsx(
              "freeze-template-card-content-container",
              "review-itinerary"
            )}
          >
            <FlightFreezeReviewItinerary
              title={t.REVIEW_ITINERARY_TITLE(reviewItineraryStep)}
              isMobile={matchesMobile}
              disableChangeFlight={disableChangeFlight}
            />
          </Box>
          {showPriceFreezeDurationSlider && (
            <Box
              className={clsx(
                "freeze-template-card-content-container",
                "choose-duration"
              )}
            >
              <DesktopPriceFreezeDurationDetails
                titleStep={priceFreezeDurationStep}
              />
            </Box>
          )}
          <div
            className={clsx(
              "freeze-template-card-content-container",
              "travelers"
            )}
            ref={travelersDivRef}
          >
            <Box className="travelers-selection-wrapper">
              <FlightFreezeTravelers
                titleStep={travelersStep}
                useLockPriceLanguage={useLockPriceLanguage}
              />
            </Box>
            <FlightFreezeContactInfoForm
              title={t.CONTACT_INFO_TITLE}
              subtitle={t.CONTACT_INFO_SUBTITLE(useLockPriceLanguage)}
              isDisableSave={isDisableSave}
              setIsDisableSave={setIsDisableSave}
            />
          </div>
          <div
            className={clsx("freeze-template-card-content-container", "credit")}
            ref={paymentCardDivRef}
          >
            <PaymentCard
              disabled={!isTravelerStepComplete}
              paymentStepTitle={t.REWARDS_AND_PAYMENT_TITLE(paymentCardStep)}
              isPriceFreezePurchase={true}
            />
          </div>
        </Box>
        <Box className={clsx("freeze-template-column", "right")}>
          <Box className="flight-freeze-right-content">
            <Box
              className={clsx(
                "freeze-template-card-content-container",
                "payment-breakdown"
              )}
            >
              <FlightFreezePriceBreakdown />
            </Box>
            <Box className="complete-purchase-button-container">
              <ActionButton
                className="complete-purchase-button"
                defaultStyle="h4r-primary"
                // isAgentPortal here is to disable this feature for an agent on the checkout page BOPS-209
                // cap1 doesnt want agents to be doing this because it is seen as an upsell, and they dont want agents doing that
                disabled={!isBookingValid || isDisableSave || isAgentPortal}
                onClick={() =>
                  schedulePayment({
                    ancillaries: [],
                    agentFee: undefined,
                    isPriceFreezePurchase,
                  })
                }
                onClickWhenDisabled={() => {
                  if (!isTravelerStepComplete) {
                    scrollToCardContent(travelersDivRef);
                  } else if (!isBookingValid) {
                    scrollToCardContent(paymentCardDivRef, "top");
                  }
                }}
                message={t.CONFIRM_BUTTON_TEXT(useLockPriceLanguage)}
              />
            </Box>
          </Box>
          {priceFreezeVoidWindow === PRICE_FREEZE_VOID_WINDOW_NAV_DISCLOSURE &&
            showVoidWindowBanner &&
            (priceFreezeDuration?.inSeconds ==
              t.PRICE_FREEZE_DURATION_12_HOURS_IN_SECONDS ||
              priceFreezeDuration?.inSeconds ===
              t.PRICE_FREEZE_DURATION_24_HOURS_IN_SECONDS) && (
              <Box className="banner-content">
                <NotificationBanner
                  className="void-window-banner"
                  severity={BannerSeverity.NOTICE}
                  icon={<Icon name={IconName.AlertIcon} />}
                  content={
                    <Typography
                      className="void-window-banner-label"
                      variant="caption"
                    >
                      <span className="label-copy">{t.VOID_WINDOW_TEXT}</span>
                    </Typography>
                  }
                />
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};
