import React, { useEffect } from "react";
import {
  CloseButtonIcon,
  Header,
  ActionLink,
  LoadingPopup,
  B2BSpinner,
  FlightCategoryRadio,
  UserPreferencesBanner,
} from "halifax";

import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import { MobileFlightSearchControlV3ConnectorProps } from "./container";
import "./styles.scss";
import { LocationSearch, MobileCalendarPicker } from "./components";
import * as textConstants from "./textConstants";
import { PATH_CUSTOMER_PROFILE, PATH_HOME } from "../../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../reducer";
import { MulticityFlightShopStep } from "../../../shop/reducer";
import {
  ITripTerminus,
  TripCategory,
  RecentFlightSearch,
  CallState,
} from "redmond";
import { Link } from "react-router-dom";
export interface IMobileFlightSearchControlV3Props
  extends MobileFlightSearchControlV3ConnectorProps,
    RouteComponentProps {
  isMultiCityEnabled?: boolean;
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
  isAirCXV3Experiment?: boolean;
  isCustomerProfileExperiment?: boolean;
}

export const MobileFlightSearchControlV3 = (
  props: IMobileFlightSearchControlV3Props
) => {
  const {
    origin,
    destination,
    originCode,
    destinationCode,
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    departureDateBucketsLoading,
    currentStep,
    departureDate,
    returnDate,
    setCurrentStep,
    setTripCategory,
    history,
    hasOriginAutocompleteError,
    hasDestinationAutocompleteError,
    setOriginAutocompleteError,
    setDestinationAutocompleteError,
    isMultiCityEnabled,
    populateFlightShopQueryParams,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
    isAirCXV3Experiment,
    isCustomerProfileExperiment,
    userHasSetFlightPreferences = false,
    userFlightPreferencesCallState = CallState.NotCalled,
    shouldApplyUserFlightPreferences,
    setApplyUserFlightPreferences,
  } = props;

  const showUserFlightPreferencesBanner =
    isCustomerProfileExperiment &&
    userHasSetFlightPreferences &&
    userFlightPreferencesCallState === CallState.Success;

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const handleGoBack = () => {
    setCurrentStep(
      currentStep > MobileFlightSearchStep.LocationSearch
        ? currentStep - 1
        : currentStep
    );
  };

  useEffect(() => {
    if (originCode && destinationCode) {
      fetchDepartureCalendar(
        origin as ITripTerminus,
        destination as ITripTerminus
      );
    } else {
      setCalendar();
    }
  }, [originCode, destinationCode, tripCategory]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const resetAutocompleteErrors = () => {
    hasOriginAutocompleteError && setOriginAutocompleteError(false);
    hasDestinationAutocompleteError && setDestinationAutocompleteError(false);
  };

  return (
    <Box
      className={clsx("mobile-flight-search-v3", {
        "date-range-picker":
          currentStep === MobileFlightSearchStep.CalendarPicker,
      })}
    >
      <Header
        className="homepage"
        center={
          <HeaderCenterSection
            originCode={originCode}
            destinationCode={destinationCode}
            currentStep={currentStep}
          />
        }
        left={
          <ActionLink
            className={clsx("mobile-flight-search-header-go-back", {
              hidden: currentStep === MobileFlightSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-flight-search-header-close"
            onClick={() => {
              setCurrentStep(MobileFlightSearchStep.LocationSearch);
              history.push(PATH_HOME);
              resetAutocompleteErrors();
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="location-search-container">
          <FlightCategoryRadio
            setTripCategory={setTripCategory}
            selectedCategory={tripCategory}
            isMultiCityFlightsAvailable={isMultiCityEnabled}
          />

          <LocationSearch
            onComplete={() => {
              if (tripCategory === TripCategory.MULTI_CITY) {
                populateFlightShopQueryParams({
                  history,
                  useHistoryPush: true,
                  forceQueryUpdate: false,
                  newQueryParams: {
                    multicityFlightShopProgress:
                      MulticityFlightShopStep.ChooseDeparture0,
                  },
                });
              } else {
                setCurrentStep(MobileFlightSearchStep.CalendarPicker);
              }
            }}
            currentTripCategory={tripCategory}
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
            onSelectLocation={onSelectLocation}
            isAirCXV3Experiment={isAirCXV3Experiment}
          />
          {showUserFlightPreferencesBanner &&
            tripCategory !== TripCategory.MULTI_CITY && (
              <UserPreferencesBanner
                shouldApplyUserPreferences={shouldApplyUserFlightPreferences}
                setShouldApplyUserPreferences={setApplyUserFlightPreferences}
                userHasSetPreferences={userHasSetFlightPreferences}
                userPreferencesCallState={userFlightPreferencesCallState}
                isMobile={true}
                bannerProfileCTA={
                  <Link
                    to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
                    className="profile-cta"
                  >
                    {userHasSetFlightPreferences
                      ? "Manage flight preferences"
                      : "Add flight preferences to your travel profile"}
                  </Link>
                }
                type={"flight"}
              />
            )}
        </Box>
      )}
      {currentStep === MobileFlightSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          focusedMonthIndex={focusedMonthIndex}
          returnDate={returnDate}
          departure={departureDate}
          onSelectDates={onSelectDates}
        />
      )}
      {
        <LoadingPopup
          indicatorSize={"small"}
          indicator={B2BSpinner}
          open={!!departureDateBucketsLoading}
          message={textConstants.UPDATE_CALENDAR_TEXT}
        />
      }
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  originCode?: string;
  destinationCode?: string;
  currentStep: MobileFlightSearchStep;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { originCode, destinationCode, currentStep } = props;
  const headerText =
    currentStep === MobileFlightSearchStep.CalendarPicker
      ? textConstants.CHOOSE_DATE
      : textConstants.FLIGHTS_HEADER;
  return (
    <Box className="header-center-section">
      {originCode &&
      destinationCode &&
      currentStep === MobileFlightSearchStep.CalendarPicker ? (
        <Box className="trip-origin-destination-summary">
          <Box className="origin-destination-container">
            <Typography variant="body1">{headerText}</Typography>
          </Box>
        </Box>
      ) : (
        <Box className="mobile-flight-search-location-label">
          <span>{textConstants.FLIGHTS_HEADER}</span>
        </Box>
      )}
    </Box>
  );
};
