import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";
import {
  CallState,
  Cap1DisruptionAddOnChoiceProperties,
  CAP1_VIEW_BOOKING_ADD_ON,
  CFAR_ADD_ON_CHOICE,
  DISRUPTION_ADD_ON_CHOICE,
} from "redmond";
import { Location } from "history";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { CfarDetails } from "../cfar";
import { ChfarDetails } from "../chfar";
import { DisruptionProtectionDetails } from "../disruptionProtection";
import { MobileAddOnCustomizeConnectorProps } from "./container";
import { AddOnCustomizeStep, AddOnCustomizeStepAlias } from "./types";
import { PATH_BOOK } from "../../../../../utils/urlPaths";
import {
  CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY,
  CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY_CFAR_FDA,
} from "../../../../../context/experiments";
import { AddOnTestimonyCarousel } from "../AddOnTestimonyCarousel";

export interface IMobileAddOnCustomizeProps
  extends MobileAddOnCustomizeConnectorProps,
    RouteComponentProps {
  // note: this callback function is used to overwrite the default (final) on-continue behaviour
  goToCheckout?: () => void;
  goBack: () => void;
  currentStep: AddOnCustomizeStepAlias;
  setCurrentStep: React.Dispatch<React.SetStateAction<AddOnCustomizeStepAlias>>;
  isCfarEnabled: boolean;
  isChfarEnabled: boolean;
  isDisruptionProtectionEnabled: boolean;
}

export const MobileAddOnCustomize = (props: IMobileAddOnCustomizeProps) => {
  const {
    populateFlightBookQueryParams,
    fetchAncillaryOffer,
    goToCheckout = () => {
      populateFlightBookQueryParams({ history });
    },
    goBack,
    currentStep,
    setCurrentStep,
    fetchAncillaryOfferCallState,
    history,
    cfarAddOnChoiceProperties,
    dpAddOnChoiceProperties,
    isCfarAvailable,
    isCfarEnabled,
    isChfarAvailable,
    isChfarEnabled,
    isDisruptionProtectionAvailable,
    isDisruptionProtectionEnabled,
    viewBookingAddOnProperties,
    dpOfferPropertiesForViewBookingAddOn,
    cfarOfferPropertiesForViewBookingAddOn,
    hasActiveRefundableFare,
    cfarSocialVariant,
    isRapidRebookRenameEnabled,
    cfarDiscountProperties,
    cfarOffer,
  } = props;
  const isAncillaryExposed =
    (isCfarAvailable && isCfarEnabled) ||
    (isDisruptionProtectionAvailable && isDisruptionProtectionEnabled) ||
    (isChfarAvailable && isChfarEnabled);
  const prevStep = useRef<AddOnCustomizeStepAlias>(currentStep);
  const cfarDiscount =
    cfarOffer && cfarOffer.length > 0 ? cfarOffer[0].discount : null;
  const handleCfarAddOnChoiceEvent = () => {
    trackEvent({
      eventName: CFAR_ADD_ON_CHOICE,
      properties: {
        ...cfarAddOnChoiceProperties,
        ...{
          cfar_discount_original_premium:
            cfarDiscountProperties.original_premium,
          cfar_discount_percentage: cfarDiscountProperties.discount_percentage,
        },
        success: true,
      },
    });
  };

  const handleDpAddOnChoiceEvent = () => {
    trackEvent({
      eventName: DISRUPTION_ADD_ON_CHOICE,
      properties: {
        ...dpAddOnChoiceProperties,
      } as Cap1DisruptionAddOnChoiceProperties,
    });
  };

  // todo add chfar event tracking. will be in https://hopper-jira.atlassian.net/browse/FLEX-2968
  const handleChfarAddOnChoiceEvent = () => {};

  const onContinue = () => {
    setCurrentStep((step) => step + 1);
  };

  const addOnCustomizeSteps = {
    [AddOnCustomizeStepAlias.GoBack]: AddOnCustomizeStep.GoBack,
    [AddOnCustomizeStepAlias.AddOn1]: AddOnCustomizeStep.DisruptionProtection,
    [AddOnCustomizeStepAlias.AddOn2]: AddOnCustomizeStep.CFAR,
    [AddOnCustomizeStepAlias.AddOn3]: AddOnCustomizeStep.CHFAR,
    [AddOnCustomizeStepAlias.GoToCheckout]: AddOnCustomizeStep.GoToCheckout,
  };

  useEffect(() => {
    const processStep = (skipCondition: boolean) => {
      // note: when a step isn't enabled (e.g. cfar experiment is turned off or the cfar offer is not available),
      // that step needs to be skipped
      if (skipCondition) {
        const stepAfter = currentStep + 1;
        const stepBefore = currentStep - 1;

        // note: when none of the ancillary offers is exposed to the user, consider either GoBack or GoToCheckout
        if (!isAncillaryExposed) {
          const { prevPath } =
            (history.location as Location<{ prevPath: string }>)?.state ?? {};

          // note: the user is trying to go-back from flights checkout - it should redirect to PF overview
          if (prevPath === PATH_BOOK) {
            setCurrentStep(AddOnCustomizeStepAlias.GoBack);
          } else {
            setCurrentStep(AddOnCustomizeStepAlias.GoToCheckout);
          }
        }
        // note: this check of prevStep provides a robust
        // way of determining the step before the user took their action.
        // it should be noted that it is possible to have multiple unavailable
        // ancillary products in this flow, so when handling how to navigate
        // between them, we just need to know if the previous step was
        // _any_ step later on, not just the next step.
        // (the comment in the PR of this commit provides a more thorough explanation)
        else if (prevStep.current > currentStep) {
          setCurrentStep(stepBefore);
        } else {
          setCurrentStep(stepAfter);
        }
      } else {
        prevStep.current = currentStep;
      }
    };

    switch (addOnCustomizeSteps[currentStep]) {
      case AddOnCustomizeStep.GoBack:
        prevStep.current = currentStep;
        goBack();
        break;
      case AddOnCustomizeStep.GoToCheckout:
        prevStep.current = currentStep;
        goToCheckout();
        break;
      case AddOnCustomizeStep.DisruptionProtection: {
        processStep(!isDisruptionProtectionAvailable);
        break;
      }
      case AddOnCustomizeStep.CFAR:
        processStep(!isCfarAvailable);
        break;
      case AddOnCustomizeStep.CHFAR:
        processStep(!isChfarAvailable);
        break;
    }
  }, [
    currentStep,
    isCfarAvailable,
    isDisruptionProtectionAvailable,
    isChfarAvailable,
    goBack,
    goToCheckout,
    setCurrentStep,
  ]);

  useEffect(() => {
    /*
      note: after the user refreshes the page on flight checkout, and clicks on "customize" in the progress bar,
      it needs to re-fetch ancillary offers
    */
    if (fetchAncillaryOfferCallState === CallState.NotCalled) {
      fetchAncillaryOffer({
        preserveCfarId: hasActiveRefundableFare,
      });
    }
  }, [fetchAncillaryOfferCallState, hasActiveRefundableFare]);

  useEffect(() => {
    return () => {
      setCurrentStep(AddOnCustomizeStepAlias.AddOn1);
    };
  }, []);

  useEffect(() => {
    if (
      fetchAncillaryOfferCallState === CallState.Success &&
      (isCfarAvailable || isChfarAvailable || isDisruptionProtectionAvailable)
    ) {
      trackEvent({
        eventName: CAP1_VIEW_BOOKING_ADD_ON,
        properties: {
          ...viewBookingAddOnProperties,
          ...(isDisruptionProtectionAvailable &&
            dpOfferPropertiesForViewBookingAddOn),
          ...(isCfarAvailable && cfarOfferPropertiesForViewBookingAddOn),
          ...{
            cfar_discount_original_premium:
              cfarDiscount?.originalPremiumAmount.fiat.value,
            cfar_discount_percentage: cfarDiscount?.discountPercentage,
          },
          // todo add chfar props. will be done in https://hopper-jira.atlassian.net/browse/FLEX-2968
        },
      });
    }
  }, [
    fetchAncillaryOfferCallState,
    isCfarAvailable,
    isChfarAvailable,
    isDisruptionProtectionAvailable,
  ]);

  const hasDisruptionProtectionAddOnToShow =
    addOnCustomizeSteps[currentStep] ===
      AddOnCustomizeStep.DisruptionProtection &&
    isDisruptionProtectionAvailable;
  const hasCfarAddOnToShow =
    addOnCustomizeSteps[currentStep] === AddOnCustomizeStep.CFAR &&
    isCfarAvailable;

  return (
    <Box className="mobile-add-on-customize-root">
      <Box className="mobile-add-on-customize-container">
        {addOnCustomizeSteps[currentStep] ===
          AddOnCustomizeStep.DisruptionProtection &&
          isDisruptionProtectionAvailable && (
            <DisruptionProtectionDetails
              onContinue={() => {
                handleDpAddOnChoiceEvent();
                onContinue();
              }}
              onNoDisruptionProtectionOffer={onContinue}
              cardContentProps={{
                disablePartialScroll: true,
              }}
            />
          )}
        {addOnCustomizeSteps[currentStep] === AddOnCustomizeStep.CFAR &&
          isCfarAvailable && (
            <CfarDetails
              onContinue={() => {
                handleCfarAddOnChoiceEvent();
                onContinue();
              }}
              onNoCfarOffer={onContinue}
              cardContentProps={{
                disablePartialScroll: true,
              }}
              topBannerEnabled={true}
            />
          )}
        {addOnCustomizeSteps[currentStep] === AddOnCustomizeStep.CHFAR &&
          isChfarAvailable && (
            <ChfarDetails
              onContinue={() => {
                handleChfarAddOnChoiceEvent();
                onContinue();
              }}
              cardContentProps={{
                disablePartialScroll: true,
              }}
            />
          )}
      </Box>
      {cfarSocialVariant === CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY_CFAR_FDA && (
        <AddOnTestimonyCarousel
          hasCfarAddOnToShow={hasCfarAddOnToShow}
          hasDisruptionProtectionAddOnToShow={
            hasDisruptionProtectionAddOnToShow
          }
          isRapidRebookRenameEnabled={isRapidRebookRenameEnabled}
          showArrows={false}
        />
      )}
      {cfarSocialVariant === CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY && (
        <AddOnTestimonyCarousel
          // For the DP only variant, this carousel element behaves as if CFAR is not offered (thus no CFAR testimonies).
          hasCfarAddOnToShow={false}
          hasDisruptionProtectionAddOnToShow={
            hasDisruptionProtectionAddOnToShow
          }
          isRapidRebookRenameEnabled={isRapidRebookRenameEnabled}
          showArrows={false}
        />
      )}
    </Box>
  );
};
