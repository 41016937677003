import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import {
  FlightSummaryPanel,
  ArrowLink,
  ActionLink,
  ButtonWrap,
  removeTimezone,
  formatInterval,
  Header,
  LoadingIndicator,
  B2BSpinner,
  BannerSeverity,
  NotificationBanner,
  getTotalPassengerFromPaxPricing,
  IFlightSummaryPanelProps,
} from "halifax";
import { Location } from "history";
import queryStringParser from "query-string";
import * as textConstants from "./textConstants";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import background from "./priceFreezeOverview.png";
import "./styles.scss";
import { useDeviceTypes } from "halifax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  Airport,
  FareDetails,
  TripDetails,
  TripSlice,
  PRICE_FREEZE_ID_QUERY_PARAM,
  VIEW_PRICE_FREEZE_ITINERARY,
  ViewPriceFreezeItinerary,
  getHopperFareRatingName,
  PriceFreezeFrozenFare,
  BOOK_PRICE_FREEZE,
  CallState,
  PriceFreezeStatusEnum,
} from "redmond";

import { PriceFreezeOverviewPageConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import dayjs from "dayjs";
import { ContactSupportHeader } from "../../../common";
import {
  DesktopFlightReviewDetailsPopover,
  MobileItineraryDetailsModal,
} from "../../../shop/components";
import { PATH_PRICE_FREEZE_CUSTOMIZE } from "../../../../utils/urlPaths";
import { getPlusDays } from "../../../shop/reducer/utils";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  PORTAL_TITLE,
  PRICE_FREEZE_OVERVIEW,
} from "../../../../lang/textConstants";
import { FlightFreezeProgressBar } from "../FlightFreezeProgressBar";

export interface IPriceFreezeOverviewPageProps
  extends RouteComponentProps,
    PriceFreezeOverviewPageConnectorProps {}

export const PriceFreezeOverviewPage = ({
  fetchPriceFreeze,
  fetchAncillaryOffer,
  resetFetchAncillaryOfferState,
  populateFlightBookQueryParams,
  priceFreezeCallState,
  fetchAncillaryOfferCallState,
  transferToSimilarFlightsResponse,
  currentPriceFreezeOverview,
  isAddOnOptionAvailable,
  history,
  location,
  isCfarEnabled,
  isCustomizePageMarketplaceEnabled,
  disableChangeFlight,
}: IPriceFreezeOverviewPageProps) => {
  const {
    tripDetails,
    fareDetails,
    airports,
    expiresAt,
    currentTripPricePerPax,
    totalPassengers,
    isRoundTrip,
    priceFreezeActiveStatus,
    chargedTripPricePerPax,
    frozenTripPricePerPax,
    capAmount,
    hasReachedFareCap,
    savingsAmount,
    isCurrentPriceLowerThanFrozen,
    offer,
    fareDetailsTrip,
    priceFreezeId,
    status,
  } = currentPriceFreezeOverview;
  const { matchesMobile } = useDeviceTypes();
  const isExpired = status === PriceFreezeStatusEnum.IsExpired;

  const [destinationCityName, setDestinationCityName] = useState("");

  const isProgressBarEnabled =
    isCustomizePageMarketplaceEnabled && !matchesMobile;

  const isPriceFreezeReady =
    !!tripDetails && priceFreezeCallState === CallState.Success;

  const goToCheckout = () => {
    populateFlightBookQueryParams({
      history,
      newQueryParams: { [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeId ?? "" },
    });
  };

  const handleViewPriceFreezeItineraryEvent = () => {
    const { fromTrips }: any = history.location.state ?? {};
    if (tripDetails && fareDetailsTrip) {
      trackEvent({
        eventName: BOOK_PRICE_FREEZE,
        properties: {
          ...getTrackingPriceFreezeProperties(
            fromTrips,
            tripDetails,
            fareDetailsTrip
          ),
        } as ViewPriceFreezeItinerary,
      });
    }
  };

  const onContinue = () => {
    handleViewPriceFreezeItineraryEvent();

    if (
      (isCustomizePageMarketplaceEnabled || matchesMobile) &&
      isAddOnOptionAvailable
    ) {
      history.push({
        pathname: PATH_PRICE_FREEZE_CUSTOMIZE,
        search: queryStringParser.stringify({
          [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeId,
        }),
      });
    }
    // note: it catches the case where isFlightBookWithAncillariesActive is true
    else {
      goToCheckout();
    }
  };


  const getTotalOtm = (fareDetailsTrip: PriceFreezeFrozenFare) => {
    const paxPricings = fareDetailsTrip.paxPricings
    let totalOtm = 0;
    if(paxPricings.length){
      paxPricings.forEach(paxPricing => {
        totalOtm += paxPricing.pricing.savingsAmount.fiat.value
      })
    }
    return totalOtm
  };

  // TODO: this should be stored as a selector
  // BF-1552 Modify properties to support multi-city flights
  const getTrackingPriceFreezeProperties = (
    fromTrips: boolean,
    tripDetails: TripDetails,
    fareDetailsTrip: PriceFreezeFrozenFare
  ) => ({
    price_freeze_entry: fromTrips ? "frozen_prices" : "hp_banner",
    advance: dayjs(tripDetails?.slices[0].departureTime).diff(
      dayjs().startOf("day"),
      "d"
    ),
    trip_type:
      tripDetails?.slices.length > 2
        ? "multi_city"
        : tripDetails?.slices.length === 2
        ? "round_trip"
        : "one_way",
    // TODO: check fare rating here
    fare_class: getHopperFareRatingName(
      tripDetails?.fareDetails[0].slices[0].fareShelf?.rating
    ),
    departure_date: tripDetails?.slices[0].departureTime,
    price_freeze_flow: true,
    price_freeze_id: offer?.id,
    price_freeze_duration: offer?.timeToLive?.inSeconds || 0,
    price_freeze_pax_total: getTotalPassengerFromPaxPricing(
      fareDetailsTrip?.paxPricings || []
    ),
    // TODO: add this proeprty when we get the date
    // time_since_price_freeze_purchase:
    price_freeze_shown_OTM_total_usd:
      fareDetailsTrip.totalPricing?.savingsAmount?.fiat?.value || getTotalOtm(fareDetailsTrip),
    return_date:
      tripDetails.slices.length > 1 ? tripDetails.slices[1].departureTime : "",
    price_freeze_cap_hit: hasReachedFareCap,
    frozen_price_total_usd:
      fareDetailsTrip.totalPricing?.originalAmount.fiat.value || 0,
    price_freeze_total_cost: offer?.totalAmount.fiat.value,
    price_freeze_otm_cap_usd: offer?.cap.value.amount,
    price_freeze_cost_per_pax: offer?.perPaxAmount.fiat.value,
    price_freeze_expiration_date: expiresAt,
  });

  useEffect(() => {
    if (isExpired) {
      history.push(textConstants.FROZEN_PRICES_LIST_LINK);
    }
  }, [isExpired]);

  useEffect(() => {
    document.title = PRICE_FREEZE_OVERVIEW;
    const urlSearchParams = new URLSearchParams(history.location.search);
    const priceFreezeIdFromQuery = urlSearchParams.get(
      PRICE_FREEZE_ID_QUERY_PARAM
    );
    const { useRefetch } =
      (history.location as Location<{ useRefetch: boolean }>)?.state ?? {};
    if (
      priceFreezeIdFromQuery &&
      // note: it should attempt to refetch only if the price freeze id from URL query differs from the one in redux state
      (priceFreezeIdFromQuery !== priceFreezeId || useRefetch)
    ) {
      resetFetchAncillaryOfferState();
      fetchPriceFreeze(priceFreezeIdFromQuery);
    }
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (
      isCfarEnabled &&
      isPriceFreezeReady &&
      fetchAncillaryOfferCallState === CallState.NotCalled
    ) {
      fetchAncillaryOffer({
        isPriceFreezeOverview: true,
      });
    }
  }, [isCfarEnabled, isPriceFreezeReady, fetchAncillaryOfferCallState]);

  useEffect(() => {
    if (tripDetails && airports) {
      const slice = tripDetails.slices.find(
        (s: TripSlice) => s.outgoing === true
      );
      const destination = airports[slice!.destinationCode]
        ? airports[slice!.destinationCode].cityName
        : slice!.destinationName;
      setDestinationCityName(destination);
    }
  }, [currentPriceFreezeOverview]);

  useEffect(() => {
    const { fromTrips }: any = location?.state || {};
    if (tripDetails && offer && fareDetailsTrip) {
      trackEvent({
        eventName: VIEW_PRICE_FREEZE_ITINERARY,
        properties: {
          ...getTrackingPriceFreezeProperties(
            fromTrips,
            tripDetails,
            fareDetailsTrip
          ),
        } as ViewPriceFreezeItinerary,
      });
    }
  }, [location, tripDetails, fareDetailsTrip]);

  const getSliceDetails = (
    isOutgoing: boolean,
    isMobile: boolean,
    tripDetails: TripDetails,
    airports: { [key: string]: Airport }
  ): IFlightSummaryPanelProps => {
    const slice = tripDetails.slices.find((s) => s.outgoing === isOutgoing);
    const { type, description } = textConstants.getReviewCardHeaderWithType(
      isOutgoing,
      airports[slice!.destinationCode]
        ? airports[slice!.destinationCode].cityName
        : slice!.destinationName,
      airports[slice!.destinationCode]
        ? airports[slice!.destinationCode].code
        : "",
      removeTimezone(slice!.departureTime)
    );

    return {
      airlineCode: slice!.segmentDetails[0].airlineCode,
      airline: slice!.segmentDetails[0].airlineName,
      departureDescriptionBold: type,
      departureDescription: description,
      formattedDepartureTime: dayjs(
        removeTimezone(slice!.departureTime)
      ).format("h:mm A"),
      formattedArrivalTime: dayjs(removeTimezone(slice!.arrivalTime)).format(
        "h:mm A"
      ),
      duration: formatInterval(slice!.totalDurationMinutes || 0),
      stopString: textConstants.getStopsString(slice!.stops),
      plusDays: getPlusDays(slice!),
      isMobile,
    };
  };

  interface IFlightReviewDetailsProps {
    departure: boolean;
    tripDetails: TripDetails;
    fareDetails: FareDetails;
    isMobile: boolean;
    isMultiTicket: boolean;
    airports: { [key: string]: Airport };
    summaryData: IFlightSummaryPanelProps;
    disableChangeFlight: boolean;
  }

  const FlightReviewDetails = (props: IFlightReviewDetailsProps) => {
    const {
      departure,
      tripDetails,
      fareDetails,
      isMobile,
      isMultiTicket,
      airports,
      summaryData,
      disableChangeFlight,
    } = props;

    const [openPopover, setOpenPopover] = React.useState<boolean>(false);

    return (
      <div className="flight-freeze-flight-summary">
        {isMobile && (
          <>
            <ButtonWrap
              onClick={() => setOpenPopover(true)}
              className="flight-freeze-flight-summary-button-card"
            >
              <FlightSummaryPanel
                {...summaryData}
                isMobile
                className={clsx(
                  summaryData.className,
                  "flight-freeze-flight-summary-panel"
                )}
              />
              <FontAwesomeIcon icon={faChevronRight} />
            </ButtonWrap>
            <MobileItineraryDetailsModal
              openModal={openPopover}
              isDeparture={departure}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              onClose={() => setOpenPopover(false)}
            />
          </>
        )}
        {!isMobile && (
          <>
            <FlightSummaryPanel
              {...summaryData}
              isMobile={isMobile}
              className={clsx(
                summaryData.className,
                "flight-freeze-flight-summary-panel"
              )}
            />
            <ActionLink
              className="flight-freeze-flight-summary-open-popover-button"
              onClick={() => setOpenPopover(true)}
              content={textConstants.VIEW_DETAILS}
            />
            <DesktopFlightReviewDetailsPopover
              departure={departure}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              isMultiTicket={isMultiTicket}
              openPopover={openPopover}
              onClose={() => setOpenPopover(false)}
              airports={airports}
              hasActiveRefundableFare={false}
              // TODO: If supported for Air Price Freeze.
              isVITripSelected={false}
              setOpenVIVariantModal={() => {}}
              setOpenMultipleAirlinesFares={() => {}}
              disableChangeFlight={disableChangeFlight}
            />
          </>
        )}
      </div>
    );
  };

  const renderHeader = (isMobile: boolean) => {
    const getHeaderTitleMobile = () => {
      if (priceFreezeActiveStatus) {
        return textConstants.HEADER_TITLE(isMobile, priceFreezeActiveStatus);
      }
      return null;
    };
    const getHeaderSubtitleMobile = () => {
      return (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: textConstants.HEADER_SUBTITLE(expiresAt),
          }}
        />
      );
    };
    const onGoBackMobile = () =>
      history.push(textConstants.FROZEN_PRICES_LIST_LINK);

    if (isMobile) {
      return (
        <Header
          center={
            <div className="price-freeze-header-content">
              <Typography variant="body1">{getHeaderTitleMobile()}</Typography>
              {getHeaderSubtitleMobile()}
            </div>
          }
          left={
            <ActionLink
              className={clsx("mobile-flight-search-header-go-back")}
              onClick={onGoBackMobile}
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          }
          isMobile={true}
          fullWidth={true}
        />
      );
    } else {
      return (
        <ContactSupportHeader
          title={
            priceFreezeActiveStatus
              ? textConstants.HEADER_TITLE(isMobile, priceFreezeActiveStatus)
              : ""
          }
          subtitle={textConstants.HEADER_SUBTITLE(expiresAt)}
          history={history}
        />
      );
    }
  };

  const shownPrices = (
    pricePerPax: { fiat: string; rewards?: string } | null
  ) => {
    return (
      <>
        <span className="frozen-price-fiat-amount">
          <strong>{pricePerPax?.fiat}</strong>
        </span>
        {pricePerPax?.rewards && (
          <>
            {" "}
            {textConstants.OR_SEPARATOR} {pricePerPax.rewards}
          </>
        )}
      </>
    );
  };

  if (isExpired) {
    return null;
  }

  return (
    <div
      className={clsx("price-freeze-overview-page", {
        mobile: matchesMobile,
      })}
    >
      {renderHeader(matchesMobile)}
      {isPriceFreezeReady ? (
        <>
          {isProgressBarEnabled && (
            <Box className="progress-bar-wrapper">
              <FlightFreezeProgressBar />
            </Box>
          )}
          <Grid
            container
            className={clsx("price-freeze-overview-content", {
              mobile: matchesMobile,
              "with-progress-bar": isProgressBarEnabled,
            })}
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Grid
                container
                className={clsx("card", "price-freeze-overview-header")}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    className={clsx("price-freeze-overview-header-banner")}
                  >
                    <Grid
                      item
                      xs={12}
                      md={7}
                      className="price-freeze-overview-header-content"
                    >
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html:
                            textConstants.OVERVIEW_TITLE(destinationCityName),
                        }}
                        variant="h4"
                        className="price-freeze-overview-header-title"
                      />
                      <Typography
                        className="price-freeze-overview-header-subtitle"
                        variant="subtitle1"
                        dangerouslySetInnerHTML={{
                          __html: textConstants.OVERVIEW_SUBTITLE(expiresAt),
                        }}
                        color="inherit"
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div
                        className="overview-image"
                        style={{ backgroundImage: `url(${background})` }}
                      ></div>
                    </Grid>
                  </Grid>
                </Grid>
                {transferToSimilarFlightsResponse ? (
                  <NotificationBanner
                    className="status-banner"
                    label={textConstants.ITINERARY_UPDATED}
                    severity={BannerSeverity.WARNING}
                  />
                ) : null}
                <Grid container className="price-freeze-overview-flight-info">
                  <Grid item xs={12} md={isRoundTrip ? 6 : 12}>
                    <FlightReviewDetails
                      departure={true}
                      tripDetails={tripDetails!}
                      fareDetails={fareDetails!}
                      isMobile={matchesMobile}
                      isMultiTicket={false}
                      airports={{}}
                      summaryData={getSliceDetails(
                        true,
                        matchesMobile,
                        tripDetails!,
                        airports!
                      )}
                      disableChangeFlight={disableChangeFlight}
                    />
                  </Grid>
                  {isRoundTrip && (
                    <Grid item xs={12} md={6}>
                      <FlightReviewDetails
                        departure={false}
                        tripDetails={tripDetails!}
                        fareDetails={fareDetails!}
                        isMobile={matchesMobile}
                        isMultiTicket={false}
                        airports={{}}
                        summaryData={getSliceDetails(
                          false,
                          matchesMobile,
                          tripDetails!,
                          airports!
                        )}
                        disableChangeFlight={disableChangeFlight}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={clsx("card", "frozen-price-banner")}>
                <Grid
                  item
                  xs={12}
                  className={clsx("card-data", "frozen-price")}
                >
                  <Typography
                    variant="subtitle1"
                    className="frozen-price-banner-title"
                  >
                    {isCurrentPriceLowerThanFrozen
                      ? textConstants.FROZEN_PRICE
                      : textConstants.CURRENT_PRICE}
                  </Typography>
                  <Typography className="frozen-price-amount">
                    {shownPrices(
                      isCurrentPriceLowerThanFrozen
                        ? frozenTripPricePerPax
                        : currentTripPricePerPax
                    )}
                  </Typography>
                  <Typography variant="body2">
                    {textConstants.TRIP_PER_TRAVELER(isRoundTrip)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={clsx("card-data", "current-price")}
                >
                  <Typography
                    variant="subtitle1"
                    dangerouslySetInnerHTML={{
                      __html: textConstants.TOTAL_PRICE_TEXT(
                        isCurrentPriceLowerThanFrozen,
                        !hasReachedFareCap,
                        totalPassengers
                      ),
                    }}
                    className="frozen-price-banner-title"
                  ></Typography>
                  <Typography
                    className={clsx("frozen-price-amount", {
                      "charged-price-amount": !hasReachedFareCap,
                    })}
                  >
                    {shownPrices(
                      hasReachedFareCap
                        ? frozenTripPricePerPax
                        : chargedTripPricePerPax
                    )}
                  </Typography>
                  <Typography variant="body2">
                    {textConstants.TRIP_PER_TRAVELER(isRoundTrip)}
                  </Typography>
                  {!!hasReachedFareCap && !!chargedTripPricePerPax && (
                    <Typography
                      variant="body2"
                      className={clsx({
                        "charged-price-amount": hasReachedFareCap,
                      })}
                    >
                      {textConstants.PRICE_AFTER_CAP(
                        chargedTripPricePerPax.fiat
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={clsx("card-data", "frozen-price-button-container")}
                >
                  <Button
                    onClick={onContinue}
                    className="continue-button"
                    variant="contained"
                  >
                    {isCustomizePageMarketplaceEnabled
                      ? textConstants.CONTINUE
                      : textConstants.CONTINUE_TO_BOOK}
                  </Button>
                  {!!savingsAmount && !hasReachedFareCap && (
                    <Typography
                      className="frozen-price-text"
                      dangerouslySetInnerHTML={{
                        __html: textConstants.SAVING_TEXT(savingsAmount),
                      }}
                    ></Typography>
                  )}
                  {hasReachedFareCap && (
                    <Typography
                      className="frozen-price-text"
                      dangerouslySetInnerHTML={{
                        __html: textConstants.MAX_CAP_REACHED(capAmount!),
                      }}
                    ></Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                className={clsx("card", "price-freeze-faq-container")}
              >
                <Grid item xs={12} className="price-freeze-faq-header">
                  <Typography
                    variant="h5"
                    className="price-freeze-faq-header-title"
                  >
                    {textConstants.FAQ_TITLE}
                  </Typography>
                  <Typography variant="subtitle2">
                    {textConstants.FAQ_SUBTITLE}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ArrowLink
                    content={
                      <Typography className="price-freez-faq">
                        {textConstants.FAQ_1}
                      </Typography>
                    }
                    onClick={() => {
                      window.open(textConstants.FAQ_1_LINK);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArrowLink
                    content={
                      <Typography className="price-freez-faq">
                        {textConstants.FAQ_2}
                      </Typography>
                    }
                    onClick={() => {
                      window.open(textConstants.FAQ_2_LINK);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="price-freeze-loading-container">
          <LoadingIndicator
            indicatorSize={"small"}
            indicator={B2BSpinner}
            message={textConstants.LOADING_FROZEN_PRICE_TEXT}
          />
        </div>
      )}
    </div>
  );
};
