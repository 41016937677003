import { put } from "redux-saga/effects";

import { fetchTripDetails as fetchDetails } from "../../../api/v0/shop/fetchTripDetails";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { TripDetails } from "redmond";

export function* fetchTripDetailsSaga({
  tripId,
  filterFareDetail,
}: actions.IFetchTripDetails) {
  try {
    const tripDetails: TripDetails = yield fetchDetails(tripId);

    if (filterFareDetail) {
      tripDetails.fareDetails = tripDetails.fareDetails.filter((fare) =>
        filterFareDetail(fare)
      );
    }

    yield put(actions.setTripDetails(tripDetails));
  } catch (e) {
    Logger.debug(e);
  }
}
