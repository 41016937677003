import React, { useState, useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  FlightDetailsCard,
  MixedCabinToolTip,
  FareDetailsCardCtaTitles,
  IFareCustomization,
  useDeviceTypes,
  removeTimezone,
  IconName,
} from "halifax";
import {
  Airport,
  FareDetails,
  FlightRatingsEnum,
  TripDetails,
  TripSegment,
  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
  VirtualInterlineEntryPoint,
  VirtualInterlineModalProperties,
  VIEWED_SELF_TRANSFER_MODAL,
  isSouthwestAirlines,
  CHAT_ENTERED,
  AirEntryProperties,
  MultiTicketTypeEnum,
  PolicyViolation,
  FiatPrice,
  FareclassOptionFilter,
} from "redmond";
import {
  getFlightPolicyLimit,
  getStopoverDurationForSegments,
} from "@capone/common";
import {
  COMBINATION_FLIGHT_TOOLTIP,
  COMBINATION_FLIGHT_WARNING,
  getReviewCardHeader,
} from "../../../../../components/FlightShopReviewItinerary/constants";
import * as textConstants from "../../../../../../search/components/FlightSearchFilter/components/FareclassOptionDetails/textConstants";
import {
  getPlusDays,
  isInDisruptionProtectionRebookSelector,
} from "../../../../../reducer";
import { getSliceIndex } from "../../../../../../../utils/flights";
import { Typography } from "@material-ui/core";
import { getSummaryPanelHeader } from "../../../../../components/FlightShopSummaryPanel/constants";
import {
  getIsRefundableFare,
  getBaseFareIdFromRefundableFareId,
} from "../../../../../../ancillary/utils/refundableFareHelpers";

import { MobileFlightDetailsModal } from "halifax";
import clsx from "clsx";
import "./styles.scss";
import { getIsMixedClass } from "../../../../../constants";

import { ClientContext } from "../../../../../../../App";

import dayjs from "dayjs";
import { FlightVICombinationBanner } from "../../../../../components/FlightVICombinationBanner";
import {
  FlightMissedConnectionGuarantee,
  SelfTransferBanner,
} from "../../../../../components/FlightMissedConnectionGuarantee";
import {
  IVirtualInterliningVariant,
  VirtualIinterliningModal,
} from "../../../../../components/VirtualIinterliningModal";
import { trackEvent } from "../../../../../../../api/v0/analytics/trackEvent";

interface IFlightDetailsProps {
  isOutgoing: boolean;
  selectedFareId: string;
  onFareClick: (
    sliceId: string,
    fare?: FareDetails,
    limit?: FiatPrice | null
  ) => void;
  onAlgomerchClick?: (label: string) => void;
  tripDetails: TripDetails;
  rewardsKey: string | undefined;
  departureDate: Date | undefined | null;
  returnDate: Date | undefined | null;
  airports: { [key: string]: Airport };
  outgoingFareRating?: number;
  openMobileFlightDetailsModal: boolean;
  setOpenMobileFlightDetailsModal: (val: boolean) => void;
  noticeBannerContent?: JSX.Element;
  ctaTitles?: FareDetailsCardCtaTitles;
  onPriceFreezeClick?: (sliceId: string, fare?: FareDetails) => void;
  handleRefundableFare?: (fareId: string, isRefundableFare: boolean) => void;
  fareCustomizations?: { [key in string]: IFareCustomization };
  showFareDetailsTitle?: boolean;
  isRefundableFaresEnabled?: boolean;
  isPriceHidden?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  isChatbotEnabled?: boolean;
  airEntryProperties?: AirEntryProperties;
  selectedOutboundFare?: any;
  isThebesHackerFaresV2Cap1ExperimentAvailable?: boolean;
  onOpenPolicyDescriptor?: (
    entryPoint: string,
    policyReasons: PolicyViolation[]
  ) => void;
  isSpiritOrFrontierAirlinesSelected: boolean;
  isAirCXV4Experiment: boolean;
  fareClassFilter?: FareclassOptionFilter;
  hasAppliedFareClassFilter?: boolean;
}

enum ClickType {
  Purchase,
  Freeze,
}

export const getEmptyRestrictionsText = (fareRating: number | undefined) => {
  switch (fareRating) {
    case FlightRatingsEnum.basic:
      return textConstants.BASIC_DETAILS_TEXT;
    case FlightRatingsEnum.standard:
      return textConstants.STANDARD_DETAILS_TEXT;
    case FlightRatingsEnum.enhanced:
      return textConstants.ENHANCED_DETAILS_TEXT;
    case FlightRatingsEnum.premium:
      return textConstants.PREMIUM_DETAILS_TEXT;
    case FlightRatingsEnum.luxury:
      return textConstants.LUXURY_DETAILS_TEXT;
    default:
      return "";
  }
};

export const airlinesCountTripSegment = (segmentDetails: TripSegment[]) => {
  const set = new Set(
    (segmentDetails ?? []).map((segment) => segment.marketingAirline.code)
  );
  return set.size - 1;
};

export const layoverDetails = ({
  tripDetails,
  airports,
  isOutgoing,
}: {
  airports: { [key: string]: Airport };
  tripDetails: TripDetails;
  isOutgoing: boolean;
}) => {
  return tripDetails.slices
    .filter((slice) => isOutgoing === slice.outgoing)
    .map((slice) =>
      slice.segmentDetails.map(
        ({ airlineCode, originCode, destinationCode, airlineName }) => ({
          airlineCode,
          originCode,
          destinationCode,
          airlineName,
          originCity: airports[originCode].cityName,
          destinationCity: airports[destinationCode].cityName,
        })
      )
    )
    .flat();
};

export const FlightDetails = ({
  tripDetails,
  isOutgoing,
  selectedFareId,
  onFareClick,
  onAlgomerchClick,
  rewardsKey,
  departureDate,
  returnDate,
  airports,
  openMobileFlightDetailsModal,
  setOpenMobileFlightDetailsModal,
  noticeBannerContent,
  ctaTitles,
  onPriceFreezeClick,
  handleRefundableFare,
  fareCustomizations,
  showFareDetailsTitle,
  isRefundableFaresEnabled,
  isPriceHidden = false,
  isFlightListOptimizationExperiment = false,
  isSeatsUXOptimizationExperiment = false,
  isChatbotEnabled = false,
  airEntryProperties,
  outgoingFareRating,
  selectedOutboundFare,
  isThebesHackerFaresV2Cap1ExperimentAvailable,
  onOpenPolicyDescriptor,
  isSpiritOrFrontierAirlinesSelected,
  isAirCXV4Experiment = false,
  fareClassFilter,
  hasAppliedFareClassFilter = false,
}: IFlightDetailsProps) => {
  const isInDisruptionProtectionRebook = useSelector(
    isInDisruptionProtectionRebookSelector
  );
  const clientContext = useContext(ClientContext);
  const { isAgentPortal, policies } = clientContext;
  const { matchesMobile } = useDeviceTypes();
  const [openModal, setOpenModal] = useState<
    IVirtualInterliningVariant | false
  >(false);
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);
  const [fareDetails, setFareDetails] = useState<FareDetails | undefined>(
    undefined
  );

  const [clickedFareId, setClickedFareId] = useState("");
  const [clickType, setClickType] = useState<ClickType>();
  const [hasClickedRefundableFare, setHasClickedRefundableFare] =
    useState<boolean>(false);

  const [openedChatbotFareID, setOpenedChatbotFareID] = useState("");

  useEffect(() => {
    if (openedChatbotFareID)
      trackEvent({
        eventName: CHAT_ENTERED,
        properties: airEntryProperties,
      });
  }, [openedChatbotFareID]);

  useEffect(() => {
    if (selectedFareId || clickedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find(
          (f) => f.id === selectedFareId || f.id === clickedFareId
        )
      );
    }
  }, [selectedFareId, clickedFareId]);

  useEffect(() => {
    if (fareDetails) {
      isOutgoing
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  if (!tripDetails) return null;
  const slice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
  const isFlightMultiTicketType = tripDetails?.fareDetails?.[0]?.slices[
    isOutgoing ? 0 : 1
  ]?.fareDetails?.segments.some((s) => s.isSelfTransferLayover);

  const totalOutgoingDurationWithoutStopover =
    (slice.totalDurationMinutes ?? 0) -
    getStopoverDurationForSegments(slice.segmentDetails);

  const totalReturnDurationWithoutStopover =
    (tripDetails.slices[0].totalDurationMinutes ?? 0) -
    getStopoverDurationForSegments(tripDetails.slices[0].segmentDetails);

  // take the longest duration of all slices to determine whether to use short or long flight policy for roundtrip
  const tripDurationInMinutes = isOutgoing
    ? totalOutgoingDurationWithoutStopover
    : Math.max(
        totalOutgoingDurationWithoutStopover,
        totalReturnDurationWithoutStopover
      );

  const policyLimit = getFlightPolicyLimit(
    policies?.["flights"],
    tripDurationInMinutes,
    tripDetails.slices.length > 1
  );

  const hackerFareNotice = tripDetails.fareDetails.map((fare) => {
    if (fare.multiTicket && !isFlightMultiTicketType) {
      return {
        id: fare.id,
        message: COMBINATION_FLIGHT_WARNING,
        tooltipCopy: COMBINATION_FLIGHT_TOOLTIP,
      };
    } else {
      return null;
    }
  });

  const handleMobileFareClick = ({
    fareId,
    type,
    isRefundableFare,
  }: {
    fareId: string;
    type?: ClickType;
    isRefundableFare: boolean;
  }) => {
    setClickedFareId(fareId);
    setClickType(type);
    setHasClickedRefundableFare(isRefundableFare);
    setOpenMobileFlightDetailsModal(true);
  };

  const handleMobileModalContinue = useCallback(() => {
    const sliceId = slice.id;
    const fare: FareDetails | undefined = tripDetails.fareDetails.find(
      (f) => f.id === clickedFareId
    );

    switch (clickType) {
      case ClickType.Freeze:
        if (onPriceFreezeClick) onPriceFreezeClick(sliceId, fare);
        break;
      case ClickType.Purchase:
      default:
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (handleRefundableFare) {
          handleRefundableFare(clickedFareId, hasClickedRefundableFare);
        }
        onFareClick(sliceId, fare, policyLimit);
        break;
    }
  }, [
    slice,
    tripDetails,
    clickedFareId,
    clickType,
    onFareClick,
    onPriceFreezeClick,
  ]);

  const showFlightCombinationBanner = Boolean(
    airlinesCountTripSegment(slice.segmentDetails)
  );

  const getFareClickHandler =
    (
      onClick: (
        sliceId: string,
        fare?: FareDetails,
        limit?: FiatPrice | null | undefined
      ) => void,
      type?: ClickType
    ) =>
    (fareId: string) => {
      const isRefundableFare = getIsRefundableFare(fareId);
      /*
          note: in refundable fares, the REFUNDABLE_FARE_SUFFIX is appended at the end of fare id's; in order to avoid dealing with any
          consequence in storing the modified fare id in redux, we should transform it back to the base fare id before further propagation
        */
      const baseFareId = isRefundableFare
        ? getBaseFareIdFromRefundableFareId(fareId)
        : fareId;

      if (matchesMobile) {
        if (
          isChatbotEnabled &&
          isSouthwestAirlines(tripDetails.validatingCarrierCode || "") &&
          !!openedChatbotFareID
        )
          handleMobileModalContinue();
        else
          handleMobileFareClick({ fareId: baseFareId, type, isRefundableFare });
      } else {
        if (handleRefundableFare) {
          handleRefundableFare(baseFareId, isRefundableFare);
        }
        onClick(
          slice.id,
          tripDetails.fareDetails.find((f) => f.id === baseFareId),
          policyLimit
        );
      }
    };

  const renderHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");
    return (
      <Typography variant="body1" className="flight-details-header">
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);
  const outboundHeader =
    departureDate &&
    getReviewCardHeader(
      true,
      airports[tripDetails.slices[firstSliceIndex].destinationCode]
        ? airports[tripDetails.slices[firstSliceIndex].destinationCode].cityName
        : tripDetails.slices[firstSliceIndex].destinationName,
      dayjs(
        removeTimezone(tripDetails.slices[firstSliceIndex].departureTime)
      ).toDate(),
      true
    );

  const returnHeader =
    returnDate &&
    getReviewCardHeader(
      false,
      airports[tripDetails.slices[secondSliceIndex].destinationCode]
        ? airports[tripDetails.slices[secondSliceIndex].destinationCode]
            .cityName
        : tripDetails.slices[secondSliceIndex].destinationName,
      dayjs(
        removeTimezone(tripDetails.slices[secondSliceIndex].departureTime)
      ).toDate(),
      true
    );

  const renderCardHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");
    return (
      <>
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
      </>
    );
  };

  if (
    !isOutgoing &&
    selectedOutboundFare?.multiTicketType === MultiTicketTypeEnum.HackerFare &&
    outgoingFareRating !== undefined &&
    isThebesHackerFaresV2Cap1ExperimentAvailable
  ) {
    // we filter the fares from refundable fare, to see if how many do we get
    const withoutRefundableFares = tripDetails.fareDetails.filter(
      (fare) => !fare.id.includes("refundable-fare")
    );
    // if we have only one fare we do not filter because this could filter the only fare
    if (withoutRefundableFares.length > 1) {
      const filteredFare = tripDetails.fareDetails.filter((fare) => {
        const outboundSlice = fare.slices[0];
        // check if the fare is hackerfare if its single added to the array of fares
        return (
          outboundSlice.fareShelf?.rating === outgoingFareRating ||
          fare.multiTicketType === "single"
        );
      });
      tripDetails.fareDetails = filteredFare;
    }
  }

  return (
    <>
      <FlightDetailsCard
        fareClassFilter={fareClassFilter}
        hasAppliedFareClassFilter={hasAppliedFareClassFilter}
        className={clsx("b2b", {
          "with-banner": !!noticeBannerContent,
        })}
        isAgentPortal={isAgentPortal}
        selectedFareId={selectedFareId}
        tripDetails={tripDetails}
        isOutgoing={isOutgoing}
        getEmptyRestrictionsText={getEmptyRestrictionsText}
        onFareClick={getFareClickHandler(onFareClick, ClickType.Purchase)}
        fareNotice={hackerFareNotice}
        onAlgomerchClick={onAlgomerchClick}
        rewardsKey={rewardsKey}
        isMobile={matchesMobile}
        plusDays={getPlusDays(slice!)}
        header={
          isOutgoing
            ? outboundHeader
              ? renderHeader(outboundHeader)
              : undefined
            : returnHeader
            ? renderHeader(returnHeader)
            : undefined
        }
        isMixedCabinClass={isMixedCabinClass}
        noticeBannerContent={noticeBannerContent}
        ctaTitles={ctaTitles}
        onClickSecondary={
          onPriceFreezeClick
            ? getFareClickHandler(onPriceFreezeClick, ClickType.Freeze)
            : undefined
        }
        showFareDetailsTitle={showFareDetailsTitle}
        fareCustomizations={fareCustomizations}
        isRefundableFaresEnabled={isRefundableFaresEnabled}
        // note: when refundable fares are displayed, they need to be sorted by the total.fiat value to have a correct order
        sortFares="total"
        airlineProps={
          isInDisruptionProtectionRebook
            ? {
                WN: {
                  hideFlightDetailsSummaryBanner: true,
                  disableFareDetailsChanges: true,
                },
              }
            : undefined
        }
        isPriceHidden={isPriceHidden}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
        {...(isFlightMultiTicketType
          ? {
              layoverDetails: layoverDetails({
                tripDetails,
                isOutgoing,
                airports,
              }),
            }
          : {})}
        flightCombinationBanner={
          showFlightCombinationBanner && (
            <FlightVICombinationBanner isMobile={matchesMobile} />
          )
        }
        missedConnectionGuarantee={
          isFlightMultiTicketType && (
            <>
              <FlightMissedConnectionGuarantee
                tripDetails={tripDetails}
                isMobile={matchesMobile}
                onClick={() => {
                  trackEvent({
                    eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                    properties: {
                      entry_point: isOutgoing
                        ? VirtualInterlineEntryPoint.Outbound
                        : VirtualInterlineEntryPoint.Return,
                    } as VirtualInterlineModalProperties,
                  });
                  setOpenModal("missedConnectionGuarantee");
                }}
              />
              {/* TODO add support for selfCheck flag once is ready */}
              <SelfTransferBanner
                iconName={IconName.BookTravel}
                onClick={() => {
                  trackEvent({
                    eventName: VIEWED_SELF_TRANSFER_MODAL,
                    properties: {
                      entry_point: isOutgoing
                        ? VirtualInterlineEntryPoint.Outbound
                        : VirtualInterlineEntryPoint.Return,
                    } as VirtualInterlineModalProperties,
                  });
                  setOpenModal("selfCheck");
                }}
              />
            </>
          )
        }
        isChatbotEnabled={isChatbotEnabled}
        openedChatbotFareID={openedChatbotFareID}
        setOpenedChatbotFareID={setOpenedChatbotFareID}
        onOpenPolicyDescriptor={onOpenPolicyDescriptor}
        policyLimit={policyLimit}
        isSpiritOrFrontierAirlinesSelected={isSpiritOrFrontierAirlinesSelected}
        showAirCXV4UI={
          isAirCXV4Experiment &&
          !isFlightMultiTicketType &&
          !isInDisruptionProtectionRebook
        }
      />
      {matchesMobile && (
        <MobileFlightDetailsModal
          {...{ openModal: openMobileFlightDetailsModal, tripDetails }}
          title={renderCardHeader(
            getSummaryPanelHeader(
              isOutgoing,
              slice?.destinationCode,
              slice?.departureTime,
              true
            )
          )}
          fareDetails={tripDetails.fareDetails.find(
            (f) => f.id === clickedFareId
          )}
          className={clsx(
            "b2b",
            "b2b-secondary",
            "flight-shop-flight-details-popover"
          )}
          plusDays={slice ? getPlusDays(slice) : 0}
          onClose={() => {
            setOpenMobileFlightDetailsModal(false);
          }}
          onClick={handleMobileModalContinue}
          departure={isOutgoing}
          isMixedCabinClass={isMixedCabinClass}
          airlineProps={
            isInDisruptionProtectionRebook
              ? {
                  WN: {
                    hideFlightDetailsSummaryBanner: true,
                    disableCallToBook: true,
                  },
                }
              : undefined
          }
          showAvailableSeats={isSeatsUXOptimizationExperiment}
          flightCombinationBanner={
            showFlightCombinationBanner && (
              <FlightVICombinationBanner isMobile={matchesMobile} />
            )
          }
          missedConnectionGuarantee={
            isFlightMultiTicketType && (
              <>
                <FlightMissedConnectionGuarantee
                  tripDetails={tripDetails}
                  isMobile={matchesMobile}
                  onClick={() => {
                    trackEvent({
                      eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                      properties: {
                        entry_point: isOutgoing
                          ? VirtualInterlineEntryPoint.Outbound
                          : VirtualInterlineEntryPoint.Return,
                      } as VirtualInterlineModalProperties,
                    });
                    setOpenModal("missedConnectionGuarantee");
                  }}
                />
                <SelfTransferBanner
                  iconName={IconName.BookTravel}
                  onClick={() => {
                    trackEvent({
                      eventName: VIEWED_SELF_TRANSFER_MODAL,
                      properties: {
                        entry_point: isOutgoing
                          ? VirtualInterlineEntryPoint.Outbound
                          : VirtualInterlineEntryPoint.Return,
                      } as VirtualInterlineModalProperties,
                    });
                    setOpenModal("selfCheck");
                  }}
                />
              </>
            )
          }
          isChatbotEnabled={isChatbotEnabled}
          setOpenedChatbotFareID={setOpenedChatbotFareID}
        />
      )}
      {openModal && (
        <VirtualIinterliningModal
          onClose={() => setOpenModal(false)}
          isMobile={matchesMobile}
          variant={openModal}
          isOpen
          airports={airports}
          segmentDetails={slice.segmentDetails}
          isOutgoing={isOutgoing}
          fareDetails={tripDetails.fareDetails}
        />
      )}
    </>
  );
};
