import { createSelector } from "@reduxjs/toolkit";
import {
  AVAILABLE,
  CFAR,
  CHFAR,
  REFUNDABLE_FARES,
  CFAR_DISCOUNT,
  CFAR_DISCOUNT_CONTROL,
  CFAR_DISCOUNT_10_PERCENT,
  CFAR_DISCOUNT_25_PERCENT,
  CFAR_DISCOUNT_75_PERCENT,
  CFAR_DISCOUNT_100_PERCENT,
  CFAR_ANCILLARY_COMERCH,
  CFAR_COMERCH_90_70_SHELF_ADV_GBV,
  CFAR_COMERCH_90_70_RANDOM,
  CFAR_COMERCH_90_80_SHELF_ADV_GBV,
  CFAR_COMERCH_90_80_RANDOM,
  AIR_REFUNDABLE_FARE_COPY,
  CFAR_ENABLE_F9_NK_AIRLINE,
  UC_AVAILABLE,
  CFAR_REFUND_COPY_Challenger1,
  CFAR_REFUND_COPY_Challenger2,
  CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT,
} from "../../../../context/experiments";
import { IStoreState } from "../../../../reducers/types";
import { ancillaryExperimentsSelector } from "./experiments";

export const cfarVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CFAR]
);

export const chfarVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CHFAR]
);

export const isCfarEnabledSelector = createSelector(
  cfarVariantSelector,
  (cfarVariant) => cfarVariant === AVAILABLE
);

export const isChfarEnabledSelector = createSelector(
  chfarVariantSelector,
  (chfarVariant) => chfarVariant === AVAILABLE
);

export const refundableFaresVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[REFUNDABLE_FARES]
);

export const isRefundableFaresEnabledSelector = createSelector(
  refundableFaresVariantSelector,
  (refundableFaresVariant) => refundableFaresVariant === AVAILABLE
);

export const cfarOffersSelector = (state: IStoreState) =>
  state.flightAncillary.cfarOffers;

export const batchCfarOffersCallStateSelector = (state: IStoreState) =>
  state.flightAncillary.batchCfarOffersCallState;

export const hasUpdatedCfarOffersForReturnFlightsSelector = (
  state: IStoreState
) => state.flightAncillary.hasUpdatedCfarOffersForReturnFlights;

export const cfarDiscountVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CFAR_DISCOUNT]
);

export const cfarDiscountPromoCopy = createSelector(
  cfarDiscountVariantSelector,
  (variant): string => {
    switch (variant) {
      case CFAR_DISCOUNT_CONTROL:
        return "0";
      case CFAR_DISCOUNT_10_PERCENT:
        return "10% off";
      case CFAR_DISCOUNT_25_PERCENT:
        return "25% off";
      case CFAR_DISCOUNT_75_PERCENT:
        return "75% off";
      case CFAR_DISCOUNT_100_PERCENT:
        return "Free";
      default:
        return "-1";
    }
  }
);

export const cfarCoMerchVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CFAR_ANCILLARY_COMERCH]
);

export const cfarRefundCopySelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT]
);

export const isCfarCoMerchEnabledSelector = createSelector(
  cfarCoMerchVariantSelector,
  (variant) =>
    variant === CFAR_COMERCH_90_70_SHELF_ADV_GBV ||
    variant === CFAR_COMERCH_90_70_RANDOM ||
    variant === CFAR_COMERCH_90_80_SHELF_ADV_GBV ||
    variant === CFAR_COMERCH_90_80_RANDOM
);

export const isAirRefundableFareCopyEnabledSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[AIR_REFUNDABLE_FARE_COPY] === AVAILABLE
);

export const isCfarF9NKEnabledSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CFAR_ENABLE_F9_NK_AIRLINE] === UC_AVAILABLE
);

export const isCfarRefundCopyExperimentEnabledSelector = createSelector(
  cfarRefundCopySelector,
  (variant) =>
    variant === CFAR_REFUND_COPY_Challenger2 ||
    variant === CFAR_REFUND_COPY_Challenger1
);

export const cfarRefundCopyChallenger1Selector = createSelector(
  cfarRefundCopySelector,
  (variant) =>
    variant === CFAR_REFUND_COPY_Challenger1
);

export const cfarRefundCopyChallenger2Selector = createSelector(
  cfarRefundCopySelector,
  (variant) =>
    variant === CFAR_REFUND_COPY_Challenger2
);