import axios, { AxiosResponse } from "axios";
import { put, putResolve, select, delay } from "redux-saga/effects";
import {
  InvalidEnum,
  ConnectionResultEnum,
  QuoteTokenKey,
  CallState,
} from "redmond";
import { actions } from "../../actions";
import { selectedTripSelector } from "../../../shop/reducer/selectors";
import {
  priceFreezeFareQuoteCallStateSelector,
  priceFreezeFareQuoteTokenKeySelector,
  priceFreezeShouldIgnoreFareQuoteSelector,
} from "../../../freeze/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import {
  getSession,
  priceQuoteParamsSelector,
  priceFreezePriceQuoteParamsSelector,
  hasSMSBeenSelected,
  hasAppNotifBeenSelected,
  hasDisruptionProtectionBeenAttachedSelector,
} from "../../reducer";
import { CipherText, Product, purchaseApi } from "@b2bportal/purchase-api";
import { MultiMethod, MessageMethodEnum } from "redmond";
import Logger from "../../../../helpers/Logger";

export function* scheduleQuoteSaga({
  agentFee,
  pollQuoteOnly,
  isPriceFreezePurchase,
}: actions.IScheduleQuote) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    const selectedTrip = selectedTripSelector(state);
    if (!selectedTrip) {
      Logger.debug(
        `Set schedule quote call state failed due to lack of selectedTrip`
      );
      yield put(
        actions.setScheduleQuoteCallStateFailed({
          Invalid: InvalidEnum.Missing,
          ConnectionResult: ConnectionResultEnum.Invalid,
        })
      );
    }
    if (session) {
      yield putResolve(
        actions.closeSession({
          resetSessionOnly: true,
        })
      );
    }

    let priceFreezeQuoteTokenKey: QuoteTokenKey | undefined = undefined;
    let shouldIgnoreQuote: boolean | undefined = undefined;
    if (isPriceFreezePurchase) {
      const result: { priceFreezeQuoteTokenKey: QuoteTokenKey | undefined, shouldIgnoreQuote: boolean | undefined } = yield getPriceFreezeQuoteTokenKeyAndShouldIgnoreQuote();

      priceFreezeQuoteTokenKey = result.priceFreezeQuoteTokenKey
      shouldIgnoreQuote = result.shouldIgnoreQuote

      if (!priceFreezeQuoteTokenKey) {
        return;
      }
    }

    const request = () => {
      if (isPriceFreezePurchase) {
        const request = priceFreezePriceQuoteParamsSelector(state);
        const requestWithShouldIgnoreQuote = {
          ...request,
          shouldIgnoreQuote: shouldIgnoreQuote
        }
        return purchaseApi(axios as any).apiV0PurchaseQuoteSchedulePost({
          emailAddress: request.emailAddress,
          request: {
            type: Product.AirPriceFreeze,
            value: {
              ...requestWithShouldIgnoreQuote,
              quoteTokenKey: priceFreezeQuoteTokenKey,
            } as object,
          },
        });
      }
      const quoteParams = priceQuoteParamsSelector(state);

      const disruptionNotificationPreferences: MultiMethod = {
        email: {
          email: quoteParams.emailAddress,
          MessageMethod: MessageMethodEnum.Email,
        },
        MessageMethod: MessageMethodEnum.MultiMethod,
      };

      const sendFlightNotificationPreferences: MultiMethod = {
        email: {
          email: quoteParams.emailAddress,
          MessageMethod: MessageMethodEnum.Email,
        },
        MessageMethod: MessageMethodEnum.MultiMethod,
      };

      if (hasSMSBeenSelected(state)) {
        if(hasDisruptionProtectionBeenAttachedSelector(state)) {
          disruptionNotificationPreferences.sms = {
            MessageMethod: MessageMethodEnum.Sms,
        };
        } else {
          sendFlightNotificationPreferences.sms = {
            MessageMethod: MessageMethodEnum.Sms,
          };
        }
      }
      if (hasAppNotifBeenSelected(state)) {
        if(hasDisruptionProtectionBeenAttachedSelector(state)) {
          disruptionNotificationPreferences.push = {
            MessageMethod: MessageMethodEnum.Push,
        };
        } else {
          sendFlightNotificationPreferences.push = {
            MessageMethod: MessageMethodEnum.Push,
        }
        }
      }

      const request = {
        ...quoteParams,
        disruptionNotificationPreferences,
        sendFlightNotificationPreferences,
      };
      return purchaseApi(axios as any).apiV0PurchaseQuoteSchedulePost({
        emailAddress: quoteParams.emailAddress,
        request: {
          type: Product.Flight,
          value: request as object,
        },
      });
    };
    const result: AxiosResponse<CipherText> = yield request();

    if (result) {
      yield put(actions.setScheduleQuoteCallStateSuccess());
      yield put(actions.setSession(result.data));
      yield put(
        actions.pollQuote(agentFee, pollQuoteOnly, isPriceFreezePurchase)
      );
    } else {
      Logger.debug(`Set schedule quote call state failed due to empty result`);
      yield put(
        actions.setScheduleQuoteCallStateFailed({
          Invalid: InvalidEnum.Missing,
          ConnectionResult: ConnectionResultEnum.Invalid,
        })
      );
    }
  } catch (e) {
    Logger.debug(`Set schedule quote call state failed: ${e}`);
    yield put(
      actions.setScheduleQuoteCallStateFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}

function* getPriceFreezeQuoteTokenKeyAndShouldIgnoreQuote() {
  let farePollTerminated = false;
  let priceFreezeQuoteTokenKey: QuoteTokenKey | undefined = undefined;
  let shouldIgnoreQuote: boolean | undefined = undefined;

  while (!farePollTerminated) {
    const state: IStoreState = yield select();
    const priceFreezeFareQuoteCallState =
      priceFreezeFareQuoteCallStateSelector(state);
    priceFreezeQuoteTokenKey =
      priceFreezeFareQuoteTokenKeySelector(state) ?? undefined;
    shouldIgnoreQuote = priceFreezeShouldIgnoreFareQuoteSelector(state) ?? undefined;

    if (priceFreezeFareQuoteCallState !== CallState.InProcess) {
      farePollTerminated = true;

      if (
        priceFreezeFareQuoteCallState === CallState.Failed ||
        !priceFreezeQuoteTokenKey
      ) {

        if (priceFreezeFareQuoteCallState === CallState.Failed) {
          Logger.debug(
            `Set schedule quote call state failed due to price freeze fare quote call state failing`
          );
        }
        if (!priceFreezeQuoteTokenKey) {
          Logger.debug(
            `Set schedule quote call state failed due to lacking price freeze quote token key`
          );
        }
        
        yield put(
          actions.setScheduleQuoteCallStateFailed({
            Invalid: InvalidEnum.Missing,
            ConnectionResult: ConnectionResultEnum.Invalid,
          })
        );
        return {priceFreezeQuoteTokenKey, shouldIgnoreQuote};
      }
    } else {
      yield delay(1000);
    }
  }

  return {priceFreezeQuoteTokenKey, shouldIgnoreQuote};
}
