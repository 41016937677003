import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceFreezeExplanationModal } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../../book/actions/actions";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeExplanationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeExplanationModal = withRouter(
  connector(PriceFreezeExplanationModal)
);
