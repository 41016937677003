import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CfarDetails } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import {
  selectedCfarIdSelector,
  cfarOfferSelector,
  viewBookingAddOnPropertiesSelector,
  cfarAddOnChoicePropertiesSelector,
  currentCfarChangePolicySelector,
  fetchAncillaryOfferCallStateSelector,
  cfarAttachPropertiesForViewBookingAddOnSelector,
  hasCfarOfferSelector,
  selectedTripSelector,
  tripDetailsSelector,
  showNoFtcOnlyInRefundableFaresSelector,
  cfarDiscountPropertiesSelector,
  selectedFareDetailsSelector,
  isSpiritOrFrontierAirlinesSelectedSelector,
} from "../../../../reducer/selectors";
import {
  setSelectedCfarId,
  updateRefundableFaresProperties,
} from "../../../../actions/actions";
import { populateFlightBookQueryParams } from "../../../../../book/actions/actions";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer/selectors";
import {
  cfarSocialVariantSelector,
  isRapidRebookRenameEnabledSelector,
  cfarDiscountPromoCopy,
  isAirRefundableFareCopyEnabledSelector,
  cfarRefundCopyChallenger1Selector,
  cfarRefundCopyChallenger2Selector,
  isCfarRefundCopyExperimentEnabledSelector,
} from "../../../../../ancillary/reducer";
import {
  isAirOfferRedesignEnabledSelector,
  isCfarCoMerchEnabledSelector,
} from "../../../../../ancillary/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  return {
    tripDetails,
    selectedCfarId: selectedCfarIdSelector(state),
    cfarOffer: cfarOfferSelector(state),
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    viewBookingAddOnProperties: viewBookingAddOnPropertiesSelector(state),
    cfarAttachPropertiesForViewBookingAddOn:
      cfarAttachPropertiesForViewBookingAddOnSelector(state),
    cfarAddOnChoiceProperties: cfarAddOnChoicePropertiesSelector(state),
    changePolicy: currentCfarChangePolicySelector(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    hasCfarOffer: hasCfarOfferSelector(state),
    showNoFtcOnlyInRefundableFares:
      showNoFtcOnlyInRefundableFaresSelector(state),
    cfarSocialVariant: cfarSocialVariantSelector(state),
    isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
    cfarDiscountPromoCopy: cfarDiscountPromoCopy(state),
    cfarDiscountProperties: cfarDiscountPropertiesSelector(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    isCfarCoMerchEnabled: isCfarCoMerchEnabledSelector(state),
    selectedFare: selectedFareDetailsSelector(state),
    isAirRefundableCopyEnabled: isAirRefundableFareCopyEnabledSelector(state),
    isSpiritOrFrontierAirlinesSelected:
      isSpiritOrFrontierAirlinesSelectedSelector(state),
    isCfarRefundCopyChallenger2Variant: cfarRefundCopyChallenger2Selector(state),
    isCfarRefundCopyChallenger1Variant: cfarRefundCopyChallenger1Selector(state),
    isCfarRefundCopyExperimentEnabled: isCfarRefundCopyExperimentEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setSelectedCfarId,
  updateRefundableFaresProperties,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CfarDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCfarDetails = connector(withRouter(CfarDetails));
