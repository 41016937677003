export const INFO_BOX_PRIMARY_TITLE = "If you experience:";
export const INFO_BOX_SECONDARY_TITLE = "You can choose between 2 options:";
export const INFO_BOX_SECONDARY_TITLE_NEW_COPY = "You can:";

export const MORE_DETAILS_TITLE = "More details";
export const MORE_DETAILS_LINE_ONE =
  "We'll monitor your flight and notify you if there is a disruption.";
export const MORE_DETAILS_LINE_TWO = `<span class="item-copy-bold">Rebooking</span>: Select a new same-day or next-day flight that matches the fare class and airports from your original reservation (must re-check any luggage for your new flight).`;
export const MORE_DETAILS_LINE_THREE = `<span class="item-copy-bold">Reimbursement</span>: If the Capital One Travel rebooking options don't work for you, then you can keep your delayed flight or the rescheduled flight provided by the airline.`;
export const MORE_DETAILS_LINE_THREE_NEW_COPY = `<span class="item-copy-bold">Refund:</span> If the Capital One Travel rebooking options don't work for you, get a 100% refund of your base fare + taxes and keep your delayed flight or the rescheduled flight provided by the airline.`;
export const MORE_DETAILS_LINE_FOUR =
  "Upgrades and baggage fees aren't included.";
export const MORE_DETAILS_LINE_TWO_OBJ = {
  title: "Rebooking",
  body: "Select a new same-day or next-day flight that matches the fare class and airports from your original reservation (must re-check any luggage for your new flight).",
};
export const MORE_DETAILS_LINE_THREE_OBJ = {
  title: "Reimbursement",
  body: "If the Capital One Travel rebooking options don't work for you, then you can keep your delayed flight or the rescheduled flight provided by the airline.",
};
export const ADD_PAYMENT_FORM_HEADER_TEXT = (cardName: string) =>
  `Add your <strong>${cardName}</strong> credit card`;
export const DATE_FORMAT = "ddd, MMM D";
export const TIME_FORMAT = "h:mm A";

export const FLIGHT_DISRUPTION_ASSISTANCE = "Flight disruption assistance";
export const OUTBOUND = "Outbound";
export const RETURN = "Return";
export const FLIGHT_NUM = (slice: number) => `Flight ${slice}`;
export const NONSTOP = "Nonstop";
export const STOP = "stop";
export const STOPS = "stops";

export const VIEW_DETAILS_CONFIRM_PURCHASE =
  "View details and confirm your purchase";

export const LEARN_ABOUT_FDA =
  "Learn about flight disruption assistance, customize your alerts and travel stress-free.";
export const T_C_COPY = "Terms & conditions";

export const UPCOMING_TRIP = "Your upcoming trip";
export const FDA_ITINERARY =
  "Flight disruption assistance will be added to this itinerary.";
export const PAYMENT_METHOD = "Payment Method";

export const SIGN_UP_ALERTS = "Sign up for mobile flight disruption alerts";
export const WELL_SEND_ALERTS = (
  emailAddress: string,
  disruptionThreshold: string
) => {
  return `We’ll send email alerts to <span class="title-bold">${emailAddress}</span> if we detect that your flight is delayed for ${disruptionThreshold}+ hours, is canceled by the airline or you’re at risk of missing a connecting flight.`;
};

export const INTERESTED_IN_ALERTS = `Interested in getting these updates sent to <span class="title-bold">your phone</span>? Select your preferred method(s) for real-time alerts:`;
export const NOTIFICATIONS_DISCLAIMER = `We will <span style="font-weight:600;">never send you marketing messages</span> by opting in to flight disruption assistance real time alerts.`;
export const SMS = "Text messages (SMS)";
export const APP_NOTIFICATIONS = "App notifications";

export const CHECKOUT_BREAKDOWN = "Checkout Breakdown";
export const TOTAL = "Total";
export const AMOUNT_DUE = "Amount due";
export const ADD_TO_TRIP = "Add to your trip and pay";

export const VIEW_DETAILS = "View details";

export const PER_TRAVELER = "per traveler";

export const OPT_IN_BANNER_COPY = `Please ensure you’ve enabled notifications on the Capital One mobile app.`;
export const INTERESTED_IN_ALERTS_PHONE_NUMBER = (phoneNumber: string) => {
  return `We'll send these messages to the phone number in your Capital One profile: <b>${phoneNumber}</b>`;
};
