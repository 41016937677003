import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { ChfarDetails } from "./component";

import { IStoreState } from "../../../../../../reducers/types";
import {
  selectedTripSelector,
  tripDetailsSelector,
  selectedChfarIdSelector,
  chfarOfferSelector,
} from "../../../../reducer/selectors";
import { setSelectedChfarId } from "../../../../actions/actions";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer/selectors";
import { populateFlightBookQueryParams } from "../../../../../book/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  return {
    tripDetails,
    selectedChfarId: selectedChfarIdSelector(state),
    chfarOffer: chfarOfferSelector(state),
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
  };
};

const mapDispatchToProps = {
  setSelectedChfarId,
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ChfarDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedChfarDetails = connector(withRouter(ChfarDetails));
