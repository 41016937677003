import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  priceFreezeOfferCapSelector,
  isPriceFreezeDurationActiveSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  priceFreezeVoidWindowSelector,
  priceFreezeVoidWindowEndSelector,
  isPriceFreezeDurationShortActiveSelector,
  isPriceFreezeDurationLongActiveSelector,
  isPriceFreezeShowDurationsVariant1Selector,
  customPriceFreezeOfferSelector,
} from "../../reducer";
import {
  priceFreezeOfferPerPaxFiatSelector,
  priceFreezeOfferDurationSelector,
  priceFreezeOfferRewardsPerPaxSelector,
  priceFreezeSummaryFrozenPriceSelector,
  getRewardsPaymentAccountReferenceIdIfRedemptionEnabled,
} from "./../../../book/reducer/selectors";
import { FlightFreezeSummary } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceFreezeCap: priceFreezeOfferCapSelector(state),
    priceFreezeFiat: priceFreezeOfferPerPaxFiatSelector(state),
    priceFreezeDuration: priceFreezeOfferDurationSelector(state),
    rewardsKey: getRewardsPaymentAccountReferenceIdIfRedemptionEnabled(state),
    priceFreezeOfferRewards: priceFreezeOfferRewardsPerPaxSelector(state),
    singlePassengerPrices: priceFreezeSummaryFrozenPriceSelector(state),
    isPriceFreezeDurationActive: isPriceFreezeDurationActiveSelector(state),
    isPriceFreezeDurationPopupEnabled:
      isPriceFreezeDurationPopupEnabledSelector(state),
    priceFreezeVoidWindow: priceFreezeVoidWindowSelector(state),
    priceFreezeVoidWindowEnd: priceFreezeVoidWindowEndSelector(state),
    isPriceFreezeDurationShortActive: isPriceFreezeDurationShortActiveSelector(state),
    isPriceFreezeDurationLongActive: isPriceFreezeDurationLongActiveSelector(state),
    isPriceFreezeShowDurationsVariant1Active: isPriceFreezeShowDurationsVariant1Selector(state),
    customPriceFreezeOffer: customPriceFreezeOfferSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezeSummaryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezeSummary = withRouter(
  connector(FlightFreezeSummary)
);
