import {
  Dealness,
  Prediction,
  TripDetails,
  UtasPolicy,
  FtcType,
  UtasPolicyHeadline,
  RewardsPrice,
  FetchCfarOfferResponseV2,
  FetchCfarOfferSuccessV2,
  FareDetails,
  POINT_TWO_FTC_WITH_FEES_TEXT,
  POINT_TWO_FTC_NO_FEES_TEXT,
  POINT_TWO_NO_FTC_TEXT,
  CREDIT_AVAILABLE_WITH_FEES_LEGAL_COPY,
  CREDIT_AVAILABLE_WITH_FEES_LEGAL_COPY_UPDATED_COPY,
  CREDIT_AVAILABLE_WITH_NO_FEES_LEGAL_COPY,
  CREDIT_AVAILABLE_WITH_NO_FEES_LEGAL_COPY_UPDATED_COPY,
  CREDIT_NOT_AVAILABLE_LEGAL_COPY,
  CREDIT_NOT_AVAILABLE_LEGAL_COPY_UPDATED_COPY,
  FTC_WITHOUT_PENALTY_AND_NO_FTC,
  FTC_WITH_PENALTY_AND_FTC_NO_PENALTY,
  FTC_WITH_PENALTY_AND_NO_FTC,
  CREDIT_AVAILABLE_SPIRIT_FRONTIER_ONLY,
} from "redmond";
import { getRewardsString } from "halifax";
import { cloneDeep, isEmpty } from "lodash";
import { getAddedLegacyPrices } from "../../book/reducer/utils/pricingHelpers";
import { getRefundableFareIdFromFareId } from "../../ancillary/utils/refundableFareHelpers";
import { ITripDetailsByTripId, FlightShopStep } from "../reducer";
import {
  AVAILABLE,
  CFAR_REMOVE_FRONTEND_ELIGIBILITY_CHECK,
  getExperimentVariant,
  useExperiments,
} from "../../../context/experiments";

const REFUNDABLE_FARE_BRAND_NAME_SUFFIX = "*";

export const getSliceFareDetails = (args: {
  tripDetailsById: ITripDetailsByTripId;
  fareTrips: any;
  cfarOffers?: {
    [tripId: string]: { [fareId: string]: FetchCfarOfferResponseV2 };
  };
  flightShopStep?: FlightShopStep;
  hasSelectedRefundableFare?: boolean;
}): TripDetails | null => {
  const {
    tripDetailsById,
    fareTrips,
    cfarOffers,
    flightShopStep,
    hasSelectedRefundableFare,
  } = args;
  if (!fareTrips.length) return null;
  const expState = useExperiments();
  const removeFrontendEligibilityCheck =
    getExperimentVariant(
      expState.experiments,
      CFAR_REMOVE_FRONTEND_ELIGIBILITY_CHECK
    ) === AVAILABLE;
  let sliceDetails: TripDetails | null = null;
  sliceDetails = { ...tripDetailsById[fareTrips[0].trip] } as TripDetails;
  sliceDetails.fareDetails = [];
  fareTrips.forEach((fareTrip: any) => {
    const fareDetailByFareId = tripDetailsById[fareTrip.trip].fareDetails.find(
      (fareDetail) => fareDetail.id === fareTrip.fare
    );
    if (fareDetailByFareId && sliceDetails)
      sliceDetails.fareDetails.push(fareDetailByFareId);
  });
  sliceDetails.fareDetails.sort(
    (fare1, fare2) =>
      (fare1?.paxPricings?.[0].pricing.baseAmount.fiat.value || 0) -
      (fare2?.paxPricings?.[0].pricing.baseAmount.fiat.value || 0)
  );

  if (cfarOffers && !isEmpty(cfarOffers)) {
    const numberOfFares = sliceDetails.fareDetails.length;
    const fareTripMap = fareTrips.reduce(
      (result: { [key in string]: string }, fareTrip: any) => {
        return {
          ...result,
          [fareTrip.fare]: fareTrip.trip,
        };
      },
      {} as { [key in string]: string }
    );
    const fareDetailsWithRefundableFares: FareDetails[] = [];
    sliceDetails.fareDetails.forEach((fare, index) => {
      // push in the base fare
      fareDetailsWithRefundableFares.push(fare);

      const cfarOffer = (
        cfarOffers[fareTripMap[fare.id]]?.[fare.id] as FetchCfarOfferSuccessV2
      )?.cfarOffer;

      if (cfarOffer !== undefined) {
        const refundableFare = cloneDeep(fare);
        refundableFare.id = getRefundableFareIdFromFareId(refundableFare.id);

        // note: only the 1st paxPricing will ever be displayed (in shop), and none of its pricing will be consumed in checkout
        if (cfarOffer.premiumPerPax && !!refundableFare?.paxPricings) {
          refundableFare.paxPricings[0].pricing.baseAmount =
            getAddedLegacyPrices(
              refundableFare.paxPricings[0].pricing.baseAmount,
              cfarOffer.premiumPerPax
            );
          if (cfarOffer.discount) {
            refundableFare.paxPricings[0].pricing.discountAdded = {
              discountedPrice: {
                fiat: {
                  currencyCode: cfarOffer.premiumPerPax.fiat.currencyCode,
                  currencySymbol: cfarOffer.premiumPerPax.fiat.currencySymbol,
                  value: cfarOffer.premiumPerPax.fiat.value,
                },
                rewards: {},
              },
              originalPrice: {
                fiat: {
                  currencyCode: cfarOffer.premiumPerPax.fiat.currencyCode,
                  currencySymbol: cfarOffer.premiumPerPax.fiat.currencySymbol,
                  value: cfarOffer.discount.originalPremiumAmount.fiat.value,
                },
                rewards: {},
              },
            };
          }

          if (refundableFare.paxPricings[0].pricing.total) {
            refundableFare.paxPricings[0].pricing.total = getAddedLegacyPrices(
              refundableFare.paxPricings[0].pricing.total,
              cfarOffer.premiumPerPax
            );
          }
        }

        /*
          note: ideally, the customization on brand name can be applied through the fareCustomization prop; given that
          the current design only requires adding an asterisk at the end, this will do until something more complicated is required
        */
        refundableFare.slices = refundableFare.slices.map((fareSlice) => {
          const fareShelf = fareSlice.fareShelf;
          return {
            ...fareSlice,
            fareShelf: fareShelf
              ? {
                  ...fareShelf,
                  shortBrandName:
                    fareShelf.shortBrandName +
                    REFUNDABLE_FARE_BRAND_NAME_SUFFIX,
                }
              : undefined,
          };
        });

        // note: RF knockout rule, see https://hopper-jira.atlassian.net/browse/BF-997
        const isRefundableFareMoreExpensiveThanNextFare = () => {
          if (!refundableFare.paxPricings) {
            return false;
          } else if (index + 1 < numberOfFares) {
            const nextFarePricing =
              sliceDetails?.fareDetails?.[index + 1]?.paxPricings?.[0]?.pricing;
            const nextFareTotalValue = nextFarePricing?.total?.fiat.value;
            const nextFareBaseValue = nextFarePricing?.baseAmount.fiat.value;
            const refundableFarePricing =
              refundableFare.paxPricings[0]?.pricing;
            const refundableFareTotalValue =
              refundableFarePricing?.total?.fiat.value;
            const refundableFareBaseValue =
              refundableFarePricing?.baseAmount.fiat.value;

            if (
              nextFareTotalValue !== undefined &&
              refundableFareTotalValue !== undefined
            ) {
              return refundableFareTotalValue > nextFareTotalValue;
            } else if (
              nextFareBaseValue !== undefined &&
              refundableFareBaseValue !== undefined
            ) {
              return refundableFareBaseValue > nextFareBaseValue;
            }
          }

          return false;
        };
        const isKnockoutRuleApplicable =
          !removeFrontendEligibilityCheck &&
          (flightShopStep === FlightShopStep.ChooseDeparture ||
            (flightShopStep === FlightShopStep.ChooseReturn &&
              !hasSelectedRefundableFare));

        if (
          !isKnockoutRuleApplicable ||
          !isRefundableFareMoreExpensiveThanNextFare()
        ) {
          // push in the refundable fare
          fareDetailsWithRefundableFares.push(refundableFare);
        }
      }
    });

    // adding in refundable fares
    sliceDetails.fareDetails = fareDetailsWithRefundableFares;
  }

  return sliceDetails as TripDetails;
};

// Forecasted price is sensible in the following two cases:
// 1) Recommendation is to Wait, and min forecasted price is lower than the current price (thus the user should wait
// for that low price to show up).
// 2) Recommendation is to Buy, and max forecasted price is higher than the current price (thus the user should NOT
// wait as the price may rise to that forecasted level).
export const isForecastedPriceSensible = (prediction: Prediction) => {
  return (
    prediction &&
    ((prediction.dealness === Dealness.Wait &&
      prediction.pricePrediction.minPrice &&
      prediction.pricePrediction.minPrice.amount.amount <=
        prediction.lowestPrice.fiat.value) ||
      (prediction.dealness !== Dealness.Wait &&
        prediction.pricePrediction.maxPrice &&
        prediction.pricePrediction.maxPrice.amount.amount >=
          prediction.lowestPrice.fiat.value))
  );
};

export const getForecastedPriceDisplayText = (prediction: Prediction) => {
  if (prediction) {
    const priceToShow =
      prediction.dealness === Dealness.Wait
        ? prediction.pricePrediction.minPrice
        : prediction.pricePrediction.maxPrice;
    const displayText = priceToShow ? priceToShow.display : "";
    return displayText;
  } else {
    return "";
  }
};

export const getFtcType = (policy: UtasPolicy | undefined): FtcType | null => {
  switch ((policy?.headline ?? "").toLowerCase()) {
    case UtasPolicyHeadline.ForFree.toLowerCase():
      return FtcType.FtcNoFees;
    case UtasPolicyHeadline.ForAFee.toLowerCase():
      return policy?.fee?.price && policy.fee.price > 0
        ? FtcType.FtcWithFees
        : FtcType.FtcNoFees;
    case UtasPolicyHeadline.NonRefundable.toLowerCase():
    case UtasPolicyHeadline.NotAllowed.toLowerCase():
      return FtcType.NoFtc;
    default:
      return null;
  }
};

export const getFtcTypeArr = (policies: UtasPolicy[]): FtcType[] => {
  const ftcTypes: FtcType[] = [];
  policies.forEach((policy) => {
    const ftcType = getFtcType(policy);
    if (ftcType) ftcTypes.push(ftcType);
  });
  return ftcTypes;
};

export const getPriceFreezeRewardsString = (
  priceFreezeRewards: { [key: string]: RewardsPrice } | undefined | null,
  selectedRewardsAccountId: string | null | undefined
) => {
  if (priceFreezeRewards && selectedRewardsAccountId) {
    const rewards = priceFreezeRewards[selectedRewardsAccountId];
    return getRewardsString(rewards);
  }
  return "";
};

export const secondBulletText = ({
  ftcTypes,
  cashCoveragePercentage,
  useStrong,
  coMerch,
  refundAmount,
  airlineFTCAmount,
  punctuation,
  excludeWithFeesCopy,
  concise,
  isSpiritOrFrontierAirlinesSelected,
  outboundAirlineCode,
  returnAirlineCode,
  showRefundAmountInSecondBulletText,
}: {
  ftcTypes: FtcType[];
  cashCoveragePercentage: number | undefined;
  useStrong: boolean;
  coMerch?: boolean;
  refundAmount?: number;
  airlineFTCAmount?: number;
  punctuation?: string;
  excludeWithFeesCopy?: boolean;
  concise?: boolean;
  isSpiritOrFrontierAirlinesSelected?: boolean;
  outboundAirlineCode?: string;
  returnAirlineCode?: string;
    showRefundAmountInSecondBulletText?: boolean;
}): string => {
  if (outboundAirlineCode === "AA" || returnAirlineCode == "AA") {
    return POINT_TWO_NO_FTC_TEXT({
      percent: cashCoveragePercentage,
      useStrong,
      coMerch,
      refundAmount,
      punctuation,
      showRefundAmountInSecondBulletText
    });;
  }
  if (isSpiritOrFrontierAirlinesSelected) {
    return POINT_TWO_NO_FTC_TEXT({
      percent: cashCoveragePercentage,
      useStrong,
      coMerch,
      refundAmount,
      punctuation,
      showRefundAmountInSecondBulletText
    });
  }
  if (ftcTypes.length === 2) {
    if (
      ftcTypes.includes(FtcType.FtcWithFees) &&
      ftcTypes.includes(FtcType.FtcNoFees)
    ) {
      if (excludeWithFeesCopy) {
        return POINT_TWO_FTC_NO_FEES_TEXT({
          percent: cashCoveragePercentage,
          useStrong,
          coMerch,
          refundAmount,
          airlineFTCAmount,
          punctuation,
          concise,
          showRefundAmountInSecondBulletText
        });
      }
      // Since one flight has fees show POINT_TWO_FTC_WITH_FEES_TEXT
      return POINT_TWO_FTC_WITH_FEES_TEXT({
        percent: cashCoveragePercentage,
        useStrong,
        coMerch,
        refundAmount,
        airlineFTCAmount,
        punctuation,
        concise,
        showRefundAmountInSecondBulletText
      });
    }
    if (
      ftcTypes.includes(FtcType.FtcWithFees) &&
      ftcTypes.includes(FtcType.NoFtc)
    ) {
      return POINT_TWO_NO_FTC_TEXT({
        percent: cashCoveragePercentage,
        useStrong,
        coMerch,
        refundAmount,
        punctuation,
        showRefundAmountInSecondBulletText
      });
    }
    if (
      ftcTypes.includes(FtcType.FtcNoFees) &&
      ftcTypes.includes(FtcType.NoFtc)
    ) {
      return POINT_TWO_NO_FTC_TEXT({
        percent: cashCoveragePercentage,
        useStrong,
        coMerch,
        refundAmount,
        punctuation,
        showRefundAmountInSecondBulletText
      });
    }
  } else if (ftcTypes.length === 1) {
    switch (ftcTypes[0]) {
      case FtcType.FtcNoFees:
        return POINT_TWO_FTC_NO_FEES_TEXT({
          percent: cashCoveragePercentage,
          useStrong,
          coMerch,
          refundAmount,
          airlineFTCAmount,
          punctuation,
          concise,
          showRefundAmountInSecondBulletText
        });
      case FtcType.FtcWithFees:
        if (excludeWithFeesCopy) {
          return POINT_TWO_FTC_NO_FEES_TEXT({
            percent: cashCoveragePercentage,
            useStrong,
            coMerch,
            refundAmount,
            airlineFTCAmount,
            punctuation,
            concise,
            showRefundAmountInSecondBulletText
          });
        }
        return POINT_TWO_FTC_WITH_FEES_TEXT({
          percent: cashCoveragePercentage,
          useStrong,
          coMerch,
          refundAmount,
          airlineFTCAmount,
          punctuation,
          concise,
          showRefundAmountInSecondBulletText
        });
    }
  }
  return POINT_TWO_NO_FTC_TEXT({
    percent: cashCoveragePercentage,
    useStrong,
    coMerch,
    refundAmount,
    punctuation,
    showRefundAmountInSecondBulletText
  });
};

export const lastSentenceText = (
  ftcTypes: FtcType[],
  outboundAirline: string,
  returnAirline: string,
  updatedCopy: boolean = false,
  isSpiritOrFrontierAirlinesSelected: boolean
): string => {
  if (isSpiritOrFrontierAirlinesSelected) {
    return CREDIT_AVAILABLE_SPIRIT_FRONTIER_ONLY;
  }
  // BF-1552 This currently assumes at most 2 FTC Types (corresponding to two slices).
  // If we later support multi-city bookings, then
  // 1. We need to support 2+ FTC Types.
  // 2. Existing string templates below will need to support multiple airlines per FTC type.
  if (ftcTypes.length === 2) {
    if (
      ftcTypes.includes(FtcType.FtcWithFees) &&
      ftcTypes.includes(FtcType.FtcNoFees)
    ) {
      // Since one flight has fees show POINT_TWO_FTC_WITH_FEES_TEXT
      return ftcTypes[0] === FtcType.FtcWithFees
        ? FTC_WITH_PENALTY_AND_FTC_NO_PENALTY(outboundAirline, returnAirline)
        : FTC_WITH_PENALTY_AND_FTC_NO_PENALTY(returnAirline, outboundAirline);
    }
    if (
      ftcTypes.includes(FtcType.FtcWithFees) &&
      ftcTypes.includes(FtcType.NoFtc)
    ) {
      return ftcTypes[0] === FtcType.FtcWithFees
        ? FTC_WITH_PENALTY_AND_NO_FTC(outboundAirline, returnAirline)
        : FTC_WITH_PENALTY_AND_NO_FTC(returnAirline, outboundAirline);
    }
    if (
      ftcTypes.includes(FtcType.FtcNoFees) &&
      ftcTypes.includes(FtcType.NoFtc)
    ) {
      return ftcTypes[0] === FtcType.FtcNoFees
        ? FTC_WITHOUT_PENALTY_AND_NO_FTC(outboundAirline, returnAirline)
        : FTC_WITHOUT_PENALTY_AND_NO_FTC(returnAirline, outboundAirline);
    }
  } else if (ftcTypes.length === 1) {
    switch (ftcTypes[0]) {
      case FtcType.FtcNoFees:
        return updatedCopy
          ? CREDIT_AVAILABLE_WITH_NO_FEES_LEGAL_COPY_UPDATED_COPY
          : CREDIT_AVAILABLE_WITH_NO_FEES_LEGAL_COPY;
      case FtcType.FtcWithFees:
        return updatedCopy
          ? CREDIT_AVAILABLE_WITH_FEES_LEGAL_COPY_UPDATED_COPY
          : CREDIT_AVAILABLE_WITH_FEES_LEGAL_COPY;
    }
  }
  return updatedCopy
    ? CREDIT_NOT_AVAILABLE_LEGAL_COPY_UPDATED_COPY
    : CREDIT_NOT_AVAILABLE_LEGAL_COPY;
};
