import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import { PriceFreezeOverviewPage } from "./component";
import { fetchPriceFreeze } from "../../actions/actions";
import {
  getCurrentPriceFreezeDataForOverviewPage,
  priceFreezeCallStateSelector,
} from "../../reducer";
import {
  isCfarEnabledSelector,
  isCustomizePageMarketplaceEnabledSelector,
} from "../../../ancillary/reducer";
import {
  transferToSimilarFlightsResponseSelector,
  cfarOfferSelector,
  currentCfarChangePolicySelector,
  isAddOnOptionAvailableSelector,
  fetchAncillaryOfferCallStateSelector,
} from "../../../shop/reducer";
import {
  fetchAncillaryOffer,
  resetFetchAncillaryOfferState,
} from "../../../shop/actions/actions";
import { getIsPriceFreezeExerciseEnabled } from "../../../book/reducer";
import { populateFlightBookQueryParams } from "../../../book/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    currentPriceFreezeOverview: getCurrentPriceFreezeDataForOverviewPage(state),
    priceFreezeCallState: priceFreezeCallStateSelector(state),
    transferToSimilarFlightsResponse:
      transferToSimilarFlightsResponseSelector(state),
    cfarOffer: cfarOfferSelector(state),
    changePolicy: currentCfarChangePolicySelector(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    isCfarEnabled: isCfarEnabledSelector(state),
    isCustomizePageMarketplaceEnabled:
      isCustomizePageMarketplaceEnabledSelector(state),
    disableChangeFlight: getIsPriceFreezeExerciseEnabled(state),
  };
};

const mapDispatchToProps = {
  fetchPriceFreeze,
  fetchAncillaryOffer,
  resetFetchAncillaryOfferState,
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeOverviewPageConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeOverviewPage = withRouter(
  connector(PriceFreezeOverviewPage)
);
