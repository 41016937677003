import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  EntryButtonEnum,
  TripDetails,
  CONTINUE_DURATION_SLIDER,
} from "redmond";
import { ActionButton, Icon, IconName, useDeviceTypes } from "halifax";
import clsx from "clsx";
import * as constants from "../textConstants";
import "./styles.scss";
import { PriceFreezeEntryButtonConnectorProps } from "./container";
import {
  IPriceFreezeFlightDetailsModalOwnProps,
  PriceFreezeFlightDetailsModal,
} from "../PriceFreezeFlightDetailsModal";
import { PriceFreezeSelectedDurationModal } from "../PriceFreezeSelectedDurationModal";
import {
  MobilePriceFreezeDurationModal,
  getPriceFreezeDurationSelectionPageProperty,
} from "../../../../freeze/components/priceFreezeDurationComponents";
import {
  handleFreezePrice,
  goToPriceFreezeCheckout,
  IGoToPriceFreezeCheckoutProps,
} from "../sharedUtils";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PATH_PRICE_FREEZE } from "../../../../../utils/urlPaths";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { FlightShopStep } from "../../../reducer";
import dayjs from "dayjs";

export interface IPriceFreezeEntryButtonProps
  extends PriceFreezeEntryButtonConnectorProps,
  IPriceFreezeEntryButtonOwnProps,
  RouteComponentProps { }

export interface IPriceFreezeEntryButtonOwnProps {
  showPriceFreezeDetails?: boolean;
  tripDetails?: TripDetails;
  fareId?: string;
  handleGoToPriceFreezeCheckout?: () => void;
  longerTextInButton?: boolean;
  useChevronButton?: boolean;
  highlightedButton?: boolean;
  largeButton?: boolean;
  useLockPriceLanguage?: boolean;
  showFreezeIcon?: boolean;
  priceFreezeFlightDetailsModalProps: IPriceFreezeFlightDetailsModalOwnPropsForButton;
  flightShopStep?: FlightShopStep,
  setCheapestEligibleOfferData: boolean,
}

export type IPriceFreezeFlightDetailsModalOwnPropsForButton = Omit<
  IPriceFreezeFlightDetailsModalOwnProps,
  "priceFreezeFlightDetailsModalOpen" | "setPriceFreezeFlightDetailsModalOpen"
>;

export const PriceFreezeEntryButton = (props: IPriceFreezeEntryButtonProps) => {
  const {
    showPriceFreezeDetails = false,
    history,
    tripDetails,
    fareId,
    // note: this prop is used to overwrite the default behaviour of goToPriceFreezeCheckout
    handleGoToPriceFreezeCheckout,
    priceDropProtectionCandidateId,
    longerTextInButton,
    useChevronButton,
    highlightedButton,
    largeButton,
    useLockPriceLanguage,
    showFreezeIcon,
    priceFreezeFlightDetailsModalProps,
    isPriceFreezeDurationActive,
    isPriceFreezeDurationPopupEnabled,
    changedPriceFreezeDurationProperties,
    flightShopStep,
    setPriceFreezeEntryPointStep,
    departureDate,
    priceFreezeOfferCheapestTripFareId,
    priceFreezeOfferCheapestTripTripId,
    initializeOfferDataAndCustomOffer,
    setChosenOutgoingSlice,
    flights,
    setCheapestEligibleOfferData,
  } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [
    priceFreezeFlightDetailsModalOpen,
    setPriceFreezeFlightDetailsModalOpen,
  ] = useState<boolean>(false);
  const [priceFreezeDurationModalOpen, setPriceFreezeDurationModalOpen] =
    useState<boolean>(false);
  // note: the PF duration selection modal is only shown on mobile (in flights shop)
  const isPriceFreezeDurationMobileModalEnabled =
    matchesMobile && isPriceFreezeDurationActive;
  const isPriceFreezeDurationDesktopPopupEnabled =
    matchesDesktop && isPriceFreezeDurationPopupEnabled;
  const showPriceFreezeDurationModal =
    isPriceFreezeDurationMobileModalEnabled ||
    isPriceFreezeDurationDesktopPopupEnabled;


  /**
   * Originally, the cheapest flight returned in shopSummary had the pf offer presented
   * by the prediction and pf entry banner entries. Sometimes this lead to offering
   * PF on flights from restricted carriers such as United.
   * 
   * We diverged from this by building an offer on the "cheapest eligible flight".
   * This created an issue where the prediction and pf banner entry point would sometimes default back to the actual
   * cheapest flight offer. e,g what could be the United offer.
   * 
   * This effect below is an unfortunate work around needed to re-calculate the correct offer.
   */

  useEffect(() => {
    if (setCheapestEligibleOfferData) {
      if (flights) {
        initializeOfferDataAndCustomOffer({
          departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
          tripId: priceFreezeOfferCheapestTripTripId,
          fareId: priceFreezeOfferCheapestTripFareId,
          history: history,
        })
        setChosenOutgoingSlice({
          outgoingFareId: priceFreezeOfferCheapestTripFareId,
          outgoingSliceId: flights.fareSlices[flights.fares[priceFreezeOfferCheapestTripFareId].outbound].id,
          outgoingFareRating:
            flights.fareSlices[flights.fares[priceFreezeOfferCheapestTripFareId].outbound].fareShelf.value,
          tripId: priceFreezeOfferCheapestTripTripId,
          resetReturnIds: true,
        });
      }
    }
  }, []);

  const getButtonMessage = () => {
    let buttonMessage;
    if (useChevronButton) {
      buttonMessage = (
        <FontAwesomeIcon
          className="mobile-right-chevron"
          icon={faChevronRight}
          size={"2x"}
        />
      );
    } else {
      buttonMessage = longerTextInButton
        ? constants.PRICE_FREEZE_INFO_BUTTON_LONGER(useLockPriceLanguage)
        : constants.PRICE_FREEZE_INFO_BUTTON(useLockPriceLanguage);
    }

    return buttonMessage;
  };

  const openPriceFreezeFlightDetailsModal = () => {
    setPriceFreezeDurationModalOpen(false);
    setPriceFreezeFlightDetailsModalOpen(true);
  };

  const openPriceFreezeDurationModal = () => {
    setPriceFreezeFlightDetailsModalOpen(false);
    setPriceFreezeDurationModalOpen(true);
  };

  let handleContinue = showPriceFreezeDetails
    ? openPriceFreezeFlightDetailsModal
    : showPriceFreezeDurationModal
      ? openPriceFreezeDurationModal
      : undefined;

  if (flightShopStep === FlightShopStep.ReviewItinerary) {
    handleContinue = undefined
  }

  const goToPriceFreezeCheckoutProps: IGoToPriceFreezeCheckoutProps = {
    tripDetails,
    fareId,
    handleGoToPriceFreezeCheckout,
    priceDropProtectionCandidateId,
    history,
    entryButton: EntryButtonEnum.FREEZE_PRICE,
  };

  return (
    <>
      <Box className="price-freeze-button-section">
        <ActionButton
          className={clsx("freeze-price-button", {
            "large-button": largeButton,
            "icon-button": useChevronButton,
            "highlighted-button": highlightedButton,
          })}
          onClick={() => {
            setPriceFreezeEntryPointStep(FlightShopStep.PricePrediction);
            handleFreezePrice({
              ...goToPriceFreezeCheckoutProps,
              handleContinue,
              flightShopStep,
            })
          }
          }
          message={getButtonMessage()}
          defaultStyle={highlightedButton ? "h4r-primary" : "h4r-secondary"}
          ariaLabelText={constants.PRICE_FREEZE_INFO_BUTTON(
            useLockPriceLanguage
          )}
          icon={
            showFreezeIcon ? (
              <Icon
                className="price-freeze-icon"
                name={
                  useLockPriceLanguage
                    ? IconName.LockWithKeyhole
                    : IconName.B2bPriceFreezeNoCircleSmall
                }
              />
            ) : undefined
          }
          iconPosition="left"
        />
      </Box>
      <PriceFreezeFlightDetailsModal
        {...priceFreezeFlightDetailsModalProps}
        handleContinue={
          showPriceFreezeDurationModal
            ? openPriceFreezeDurationModal
            : undefined
        }
        priceFreezeFlightDetailsModalOpen={priceFreezeFlightDetailsModalOpen}
        setPriceFreezeFlightDetailsModalOpen={
          setPriceFreezeFlightDetailsModalOpen
        }
      />
      {isPriceFreezeDurationDesktopPopupEnabled && (
        <PriceFreezeSelectedDurationModal
          priceFreezeSelectedDurationModalOpen={priceFreezeDurationModalOpen}
          setPriceFreezeSelectedDurationModalOpen={
            setPriceFreezeDurationModalOpen
          }
          termsProps={{
            content: constants.PRICE_FREEZE_DETAILS_READ_TERMS_TEXT,
            ariaLabelText: constants.PRICE_FREEZE_DETAILS_READ_TERMS_LABEL,
            onClick: () => window.open(`${PATH_PRICE_FREEZE}`, "_blank"),
          }}
          handleContinue={() => {
            // note: goToPriceFreezeCheckout mutates history, so it needs to stay in this order
            const { page } =
              getPriceFreezeDurationSelectionPageProperty(history) ?? {};

            goToPriceFreezeCheckout(goToPriceFreezeCheckoutProps);
            trackEvent({
              eventName: CONTINUE_DURATION_SLIDER,
              properties: {
                ...changedPriceFreezeDurationProperties,
                page,
              },
            });
          }}
          disableGenerateOfferOnChange={true}
        />
      )}
      {isPriceFreezeDurationMobileModalEnabled && (
        <MobilePriceFreezeDurationModal
          onContinue={() =>
            goToPriceFreezeCheckout(goToPriceFreezeCheckoutProps)
          }
          priceFreezeDurationModalOpen={priceFreezeDurationModalOpen}
          setPriceFreezeDurationModalOpen={setPriceFreezeDurationModalOpen}
        />
      )}
    </>
  );
};
