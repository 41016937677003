import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  mapStateToProps as baseMapStateToProps,
  mapDispatchToProps as baseMapDispatchToProps,
} from "../../capone/MobileFlightBookWorkflow/container";
import { CorpPriceQuoteData, TripCategory } from "redmond";
import { CorpMobileFlightBookWorkflow } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { isSinglePageMarketplaceEnabledSelector } from "../../../../ancillary/reducer";
import { getTripCategory } from "../../../../search/reducer";
import {
  getCorporateTravel,
  selectedFareDetailsSelector,
  getShopRequest,
} from "../../../../shop/reducer";
import {
  setSubmitForApproval,
  setSubmitForApprovalFailure,
} from "../../../actions/actions";
import {
  getPriceQuoteWithUpdatedAncillary,
  getCardPaymentAccount,
  getPolicyLimit,
  getUserSelectedPassengerIds,
} from "../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    ...baseMapStateToProps(state),
    corporateTravel: getCorporateTravel(state),
    priceQuote: getPriceQuoteWithUpdatedAncillary(state) as CorpPriceQuoteData,
    isSinglePageMarketplaceEnabled:
      isSinglePageMarketplaceEnabledSelector(state),
    cardPaymentAccount: getCardPaymentAccount(state),
    isRoundTrip: getTripCategory(state) === TripCategory.ROUND_TRIP,
    policyLimit: getPolicyLimit(state),
    fareDetails: selectedFareDetailsSelector(state),
    selectedPaxIds: getUserSelectedPassengerIds(state),
    shopRequest: getShopRequest(state),
  };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CorpMobileFlightBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const CorpConnectedMobileFlightBookWorkflow = withRouter(
  connector(CorpMobileFlightBookWorkflow)
);
