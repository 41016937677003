import { PassengerAncillaryPricing } from "@b2bportal/air-booking-api";
import {
  /*
    TODO: delete once old prices is fully deprecated, see https://hopchat.slack.com/archives/C02LJBJTY67/p1676057816240079;
    here's its associated ticket https://hopper-jira.atlassian.net/browse/BF-1524
  */
  getAddedLegacyRewards,
  getAddedLegacyPrices,
  getModifiedLegacyRewards,
  getMultipliedLegacyPrices,
  // new prices helpers
  getAddedRewards,
  getAddedPrices,
  getSubtotalWithAncillaries,
} from "halifax";

export const getCfarFee = (
  ancillaries: PassengerAncillaryPricing[] | undefined
): number | undefined =>
  ancillaries?.find(
    (ancillary: PassengerAncillaryPricing) => ancillary?.kind == "Cfar"
  )?.premium?.fiat?.value;

export const getDisruptionProtectionFee = (
  ancillaries: PassengerAncillaryPricing[] | undefined
): number | undefined =>
  ancillaries?.find(
    (ancillary: PassengerAncillaryPricing) =>
      ancillary?.kind == "Delay" || ancillary?.kind == "MissedConnection"
  )?.premium?.fiat?.value;

export {
  getAddedLegacyRewards,
  getAddedLegacyPrices,
  getModifiedLegacyRewards,
  getMultipliedLegacyPrices,
  getAddedRewards,
  getAddedPrices,
  getSubtotalWithAncillaries,
};
