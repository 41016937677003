import React, { FC, useState } from "react";
import {
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import {
  VirtualIinterliningModal,
  IVirtualInterliningVariant,
} from "../VirtualIinterliningModal";
import * as textConstants from "./textConstants";
import { DesktopSelfTransferReminder } from "./DesktopSelfTransferReminder";
import { MobileSelfTransferReminder } from "./MobileSelfTransferReminder";
import { IMobileStep, ISelfTransferReminderModalProps, ITripLeg } from "./types";
import { VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL, VirtualInterlineEntryPoint, VirtualInterlineModalProperties } from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export const SelfTransferReminderModal: FC<ISelfTransferReminderModalProps> = ({
  isOpen,
  onClose,
  slices,
  airports,
  onClickContinue,
  onClickDifferentFlight,
  fareDetails,
  tripDetails,
}) => {
  const { matchesMobile } = useDeviceTypes();
  const [variant, setVariant] = useState<IVirtualInterliningVariant | false>(
    false
  );
  const [selectedTripLeg, setSelectedTripLeg] = useState<ITripLeg>(ITripLeg.OUTBOUND);
  const [modalStep, setModalStep] = useState<IMobileStep>("reminder");

  const buttons = [
    {
      buttonText: !matchesMobile ? textConstants.PRIMARY_BTN : textConstants.CONTINUE,
      defaultStyle: "h4r-primary",
      buttonWrapperClassName: clsx(
        "self-transfer-reminder-modal-button",
        "primary"
      ),
      onClick: () => {
        matchesMobile ? setModalStep("fares") : onClickContinue()
      },
    },
    {
      buttonText: textConstants.SECONDARY_BTN,
      defaultStyle: "h4r-secondary",
      buttonWrapperClassName: clsx(
        "self-transfer-reminder-modal-button",
        "secondary"
      ),
      onClick: () => onClickDifferentFlight(),
    },
  ];

  const handleSetVariant = (variant: IVirtualInterliningVariant) => {
    trackEvent({
      eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
      properties: {
        entry_point: VirtualInterlineEntryPoint.Details
      } as VirtualInterlineModalProperties,
    });
    setVariant(variant)
  }

  return (
    <>
      {matchesMobile ? (
        <MobileSelfTransferReminder
          isOpen={isOpen}
          onClose={onClose}
          slices={slices}
          fareDetails={fareDetails}
          buttons={buttons}
          airports={airports}
          setVariant={handleSetVariant}
          selectedTripLeg={selectedTripLeg}
          setSelectedTripLeg={setSelectedTripLeg}
          modalStep={modalStep}
          setModalStep={setModalStep}
          onClickContinue={onClickContinue}
          tripDetails={tripDetails}
        />
      ) : (
        <DesktopSelfTransferReminder
          isOpen={isOpen}
          onClose={onClose}
          slices={slices}
          fareDetails={fareDetails}
          airports={airports}
          setVariant={handleSetVariant}
          buttons={buttons}
          selectedTripLeg={selectedTripLeg}
          setSelectedTripLeg={setSelectedTripLeg}
        />
      )}
      {variant && (
        <VirtualIinterliningModal
          variant={variant}
          isMobile={matchesMobile}
          isOpen
          onClose={() => setVariant(false)}
        />
      )}
    </>
  );
};
