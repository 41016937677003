export enum AddOnCustomizeStep {
  GoBack = "GoBack",
  DisruptionProtection = "DisruptionProtection",
  CFAR = "CFAR",
  CHFAR = "CHFAR",
  GoToCheckout = "GoToCheckout",
}

export enum AddOnCustomizeStepAlias {
  GoBack,
  AddOn1,
  AddOn2,
  AddOn3,
  GoToCheckout,
}
