import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { flightsByFlightShopTypeSelector, getPriceFreezeOfferCheapestTripFareId, getPriceFreezeOfferCheapestTripTripId, priceDropProtectionCandidateIdSelector } from "../../../reducer/selectors";
import {
  isPriceFreezeDurationActiveSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  changedPriceFreezeDurationPropertiesSelector,
} from "../../../../freeze/reducer/selectors";
import { PriceFreezeEntryButton } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../../book/actions/actions";
import { initializeOfferDataAndCustomOffer, setPriceFreezeEntryPointStep } from "../../../../freeze/actions/actions";
import { getDepartureDate } from "../../../../search/reducer";
import { setChosenOutgoingSlice } from "../../../actions/actions";
import { Flights } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceDropProtectionCandidateId:
      priceDropProtectionCandidateIdSelector(state),
    isPriceFreezeDurationActive: isPriceFreezeDurationActiveSelector(state),
    isPriceFreezeDurationPopupEnabled:
      isPriceFreezeDurationPopupEnabledSelector(state),
    changedPriceFreezeDurationProperties:
      changedPriceFreezeDurationPropertiesSelector(state),
    departureDate: getDepartureDate(state),
    priceFreezeOfferCheapestTripFareId:
      getPriceFreezeOfferCheapestTripFareId(state),
    priceFreezeOfferCheapestTripTripId:
      getPriceFreezeOfferCheapestTripTripId(state),
    flights: flightsByFlightShopTypeSelector(state) as Flights | null,
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setPriceFreezeEntryPointStep,
  initializeOfferDataAndCustomOffer,
  setChosenOutgoingSlice,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeEntryButtonConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeEntryButton = withRouter(
  connector(PriceFreezeEntryButton)
);
