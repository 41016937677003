import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceFreezeEntryInfo } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../../book/actions/actions";
import {
  isPriceFreezeDurationActiveSelector,
  isPriceFreezeDurationDefault12HrCopyActiveSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  isPriceFreezeDurationNewCopyActiveSelector,
  changedPriceFreezeDurationPropertiesSelector,
  priceFreezeMinOfferSelector,
  priceFreezeMiddleOfferSelector,
  priceFreezeDefaultDurationsSelector,
  priceFreezeOfferDataLengthSelector,
  isCheapestPriceFreezeOfferSelector,
  isPriceFreezeShowDurationsVariant1Selector,
} from "../../../../freeze/reducer/selectors";
import { priceDropProtectionCandidateIdSelector } from "../../../reducer/selectors";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    isPriceFreezeDurationActive: isPriceFreezeDurationActiveSelector(state),
    isPriceFreezeDurationNewCopyActive:
      isPriceFreezeDurationNewCopyActiveSelector(state),
    isPriceFreezeDurationDefault12HrCopyActiveSelector:
      isPriceFreezeDurationDefault12HrCopyActiveSelector(state),
    isPriceFreezeDurationPopupEnabled:
      isPriceFreezeDurationPopupEnabledSelector(state),
    priceDropProtectionCandidateId:
      priceDropProtectionCandidateIdSelector(state),
    changedPriceFreezeDurationProperties:
      changedPriceFreezeDurationPropertiesSelector(state),
    priceFreezeMinOffer: priceFreezeMinOfferSelector(state),
    selectedRewardsAccountId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    priceFreezeMiddleOffer: priceFreezeMiddleOfferSelector(state),
    priceFreezeDefaultDurationsVariant: priceFreezeDefaultDurationsSelector(state),
    priceFreezeOfferDataLength: priceFreezeOfferDataLengthSelector(state),
    isCheapestPriceFreezeOffer: isCheapestPriceFreezeOfferSelector(state),
    isPriceFreezeShowDurationsVariant1Active: isPriceFreezeShowDurationsVariant1Selector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeEntryInfoConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeEntryInfo = withRouter(
  connector(PriceFreezeEntryInfo)
);
