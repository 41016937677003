import { getRewardText, twoDecimalFormatter } from "halifax";
import { IPerson, RewardsPrice } from "redmond";
import { Prices } from "@b2bportal/air-booking-api";

export const SEAT_SELECTION_TITLE = "Choose seats";
export const SEAT_SELECTION_SUBTITLE = "Book seats ahead for your journey.";
export const SELECT_SEATS_CTA_TEXT = "Select Your Seats";
export const SKIP_SEAT_SELECTION_TITLE = "Skip seat selection";
export const SKIP_SEAT_SELECTION_SUBTITLE =
  "Skip seats. Seats can be added on the airline website after booking, or will be assigned at check-in.";
export const UNAVAIL_SEAT_SELECTION_WARNING =
  "Seat selection is currently unavailable for your journey.";

export const SEAT_MAP_OPTIONS = {
  modal: false,
  fontFamily: "Optimist",
  theme: {
    textPrimary: "#141414",
    textSecondary: "#676868",
    title: "#141414",
    primary: "#0276B1",
    secondary: "#0276B1",
    alert: "#CC2427",
    buttonBorderRadius: "4px",
    seats: {
      1: {
        // Extra Legroom
        stopColor: "#008140",
        labelColor: "#FFFFFF",
        borderColor: "#FFFFFF",
      },
      2: {
        // Exit row
        stopColor: "#7BB475",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      3: {
        // Preferred
        stopColor: "#BAD4B4",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      4: {
        // Standard on CQ
        stopColor: "#8AB0C7",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      5: {
        // Standard on UA
        stopColor: "#B3CDDE",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      not_available: {
        fill: "#CECFCD",
        stopColor: "#CECFCD",
        borderColor: "#CECFCD",
      },
    },
    selectedSeat: {
      color: "#141414",
      background: "#00C0E5",
      borderColor: "#FFFFFF",
    },
    confirmedSeat: {
      color: "white",
      background: "#0276B1",
      borderColor: "#FFFFFF",
    },
  },
};

export const SEAT_MAP_MODAL_TITLE = "Choose Seats";

export const getCheapestSeatString = (
  cheapestSeat: Prices,
  selectedRewardsAccountId?: string
) => {
  if (cheapestSeat.fiat.value === 0) {
    return `<strong>Free</strong> seat selection`;
  }
  if (selectedRewardsAccountId) {
    return `Starting from <strong>${cheapestSeat.fiat.currencySymbol}${
      cheapestSeat.fiat.value
    }</strong> / ${getRewardText({
      reward: cheapestSeat.accountSpecific[selectedRewardsAccountId],
    })} per traveler`;
  } else {
    return `Starting from <strong>${cheapestSeat.fiat.currencySymbol} ${cheapestSeat.fiat.value}</strong> per traveler`;
  }
};
export const getTotalSeatPricingString = (
  passengers: IPerson[],
  totalSeatPricing: number,
  totalSeatRewards?: RewardsPrice
): string => {
  if (totalSeatRewards) {
    return `You've reserved seats for ${passengers.length} ${
      passengers.length > 1 ? "passengers" : "passenger"
    } for <span class="total-pricing-fiat"><strong>$${twoDecimalFormatter(
      totalSeatPricing
    )}</strong></span> / ${getRewardText({
      reward: totalSeatRewards,
    })}.`;
  } else {
    return `You've reserved seats for ${passengers.length} ${
      passengers.length > 1 ? "passengers" : "passenger"
    } for <span class="total-pricing-fiat"><strong>$${twoDecimalFormatter(
      totalSeatPricing
    )}</strong></span>.`;
  }
};
