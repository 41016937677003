import { formatInterval, removeTimezone } from "halifax";
import dayjs from "dayjs";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { Airport, TripDetails } from "redmond";

import { IStoreState } from "../../../../reducers/types";
import { getSliceIndex } from "../../../../utils/flights";
import { getReviewCardHeaderText } from "../../../book/components/BookingSuccessModal/textConstants";
import { populateFlightShopQueryParams } from "../../../shop/actions/actions";
import { getStopsString } from "../../../shop/components/FlightList/components/FlightListInfo/textConstants";
import {
  airportsSelector,
  ISelectedTrip,
  isOutgoingMultiTicketSelector,
  isReturnMultiTicketSelector,
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer";
import { getPlusDays } from "../../../shop/reducer/utils";
import { FlightFreezeReviewItinerary } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state) as ISelectedTrip;
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const airports = airportsSelector(state, tripId);
  // Return Fare ID is used if it exists (i.e. return slice exists)
  const tripFareId = selectedTrip.returnFareId || selectedTrip.outgoingFareId;
  const fareDetails = tripDetails?.fareDetails.find((f) => f.id === tripFareId);
  return {
    departureFlight: tripDetails
      ? getSliceDetailsFromTripDetails(true, tripDetails, airports)
      : null,
    returnFlight:
      tripDetails && tripDetails.slices.length > 1
        ? getSliceDetailsFromTripDetails(false, tripDetails, airports)
        : null,
    tripDetails,
    fareDetails,
    airports,
    isOutgoingMultiTicket: isOutgoingMultiTicketSelector(state),
    isReturnMultiTicket: isReturnMultiTicketSelector(state),
    isCurrentLowestPrice: fareDetails?.fareScore?.isCheapest,
  };
};

const mapDispatchToProps = {
  populateFlightShopQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezeReviewItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezeReviewItinerary = withRouter(
  connector(FlightFreezeReviewItinerary)
);

export const getSliceDetailsFromTripDetails = (
  isOutgoing: boolean,
  tripDetails: TripDetails,
  airports: { [key: string]: Airport }
) => {
  const sliceIndex = getSliceIndex(isOutgoing, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const airport = airports[tripSlice.destinationCode];

  const { bold, standard } = getReviewCardHeaderText(
    isOutgoing,
    airport ? airport.cityName : tripSlice.destinationName,
    removeTimezone(tripSlice.departureTime),
    airport?.code ?? tripSlice.destinationCode
  );

  return {
    airlineCode: tripSlice!.segmentDetails[0].airlineCode,
    airline: tripSlice!.segmentDetails[0].airlineName,
    departureDescriptionBold: bold,
    departureDescription: standard,
    formattedDepartureTime: dayjs(
      removeTimezone(tripSlice!.departureTime)
    ).format("h:mm A"),
    formattedArrivalTime: dayjs(removeTimezone(tripSlice!.arrivalTime)).format(
      "h:mm A"
    ),
    destinationCode: tripSlice!.destinationCode,
    duration: formatInterval(tripSlice!.totalDurationMinutes || 0),
    stopString: getStopsString(tripSlice!.stops),
    plusDays: getPlusDays(tripSlice!),
  };
};
