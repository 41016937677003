import React, { useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import {
  FlightGridRow,
  FlightShopRow,
  formatInterval,
  getPricesWithComma,
  getTotalPriceText,
  B2BFlightCardV2,
  TriangleIcon,
  useDeviceTypes,
  getRewardText,
  getRewardsString,
  IconName,
  Icon,
  ButtonWrap,
  CorpPolicyBanner,
} from "halifax";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import {
  AlgomerchTag,
  FlightRatingsEnum,
  Prices,
  getTags,
  IFlightGridFares,
  SelectedTravelOfferScreen,
  SELECTED_TRAVEL_OFFER,
  VIEWED_OFFER_FAQ,
  Slice,
  TagType,
  TravelWalletOffer,
  RewardsPrice,
  TravelWalletCredit,
  RewardsAccount,
  ENGAGED_OFFER_CTA,
  CorporateTravel,
  PolicyDescriptorEntryPoints,
  PolicyViolation,
  TripCategory,
} from "redmond";
import { MulticityFlights } from "@b2bportal/air-shopping-api";
import { isInDisruptionProtectionRebookSelector } from "../../../../../reducer";
import { FareclassOptionFilter } from "../../../../../../search/reducer";
import { IFlightListData } from "../../component";
import {
  AIR_CX_V3_1,
  AIR_CX_V3_1_VARIANTS,
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  INTERNATIONAL_NGS_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../../../../context/experiments";
import { trackEvent } from "../../../../../../../api/v0/analytics/trackEvent";
import { ClientContext } from "../../../../../../../App";
import "./styles.scss";

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}
interface IFlightListInfoBaseProps {
  className?: string;
  type: FlightCardType;
}

export interface IFlightListInfoContentProps extends IFlightListInfoBaseProps {
  selectedFare: any;
  slice: Slice;
  onClick?: (selectedFareClass: string) => void;
  onFareClick?: (fareId: string) => void;
  isExpanded?: boolean;
  type: FlightCardType.content;
  multicityFlights: MulticityFlights;
  flight: IFlightListData;
  rewardsKey: string;
  fareClassFilter: FareclassOptionFilter;
  onAlgomerchClick: (val: string) => void;
  maxFlightPriceFilter: number;
  offersByTripId?: { [key: string]: TravelWalletOffer };
  showPriceAndTags?: boolean;
  useRowFlightListInfoOnly?: boolean;
  credit?: TravelWalletCredit;
  largestValueAccount?: RewardsAccount;
  onOpenPolicyDescriptor?: (
    entryPoint: string,
    policyReasons: PolicyViolation[]
  ) => void;
  isInPolicyFilter?: boolean;
}

export interface IFlightListInfoSkeletonProps extends IFlightListInfoBaseProps {
  type: FlightCardType.skeleton;
}

interface GridFaresPerShelf {
  basic: number;
  standard: number;
  premium: number;
  enhanced: number;
  luxury: number;
}

const FARE_GRID_TAG_LIMIT = 2;

export const FlightListInfoContent = (props: IFlightListInfoContentProps) => {
  const {
    selectedFare,
    slice,
    isExpanded,
    flight,
    onClick,
    multicityFlights,
    rewardsKey,
    fareClassFilter,
    onAlgomerchClick,
    onFareClick,
    maxFlightPriceFilter,
    offersByTripId,
    showPriceAndTags = true,
    useRowFlightListInfoOnly,
    credit,
    largestValueAccount,
    onOpenPolicyDescriptor,
    isInPolicyFilter,
  } = props;
  const isInDisruptionProtectionRebook = useSelector(
    isInDisruptionProtectionRebookSelector
  );

  const getFareSliceId = (fare: any) => {
    return fare?.fareSlice || fare.return;
  };

  const getFareId = (fare: any) => {
    return fare.example?.fare || fare.id;
  };

  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();

  const expState = useExperiments();
  const ngsEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        INTERNATIONAL_NGS_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );
  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const isAirCXV3Experiment = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        AIR_CX_V3_1,
        AIR_CX_V3_1_VARIANTS
      ) !== CONTROL,
    [expState]
  );

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.flightsMultiplier;

  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;

  const airline = multicityFlights.airlines[slice.marketingAirline];
  const airlineCode = airline ? airline.code : slice.marketingAirline;
  const airlineName = airline ? airline.displayName : slice.marketingAirline;

  function getFlightGridFares(flight: any, rewardsKey?: string) {
    const fares: IFlightGridFares = {
      basic: null,
      standard: null,
      premium: null,
      enhanced: null,
      luxury: null,
    };

    const faresPerShelf = flight.fares.reduce(
      (result: GridFaresPerShelf, fare: any) => {
        const fareSlice = multicityFlights.fareSlices[getFareSliceId(fare)];
        result[FlightRatingsEnum[fareSlice.fareShelf.value]] += 1;
        return result;
      },
      {
        basic: 0,
        standard: 0,
        premium: 0,
        enhanced: 0,
        luxury: 0,
      }
    );

    flight.fares.forEach((fare: any) => {
      const fareSlice = multicityFlights.fareSlices[getFareSliceId(fare)];
      const tags = getTags(fareSlice.tags as any);
      let reward: RewardsPrice | undefined;

      const gridFare = {
        fareId: getFareId(fare),
        tripId: fare.tripId,
        fareName: fareSlice.fareBrandName ?? "",
        // note: rawPrice is used in the price-range filtering logic, so it shouldn't include the CFAR price
        rawPrice: fare.amount?.fiat.value,
        tags,
      };

      reward = rewardsKey ? fare.amount?.rewards[rewardsKey] : undefined;
      gridFare["price"] = getTotalPriceText({ price: fare.amount?.fiat });
      gridFare["prices"] = fare.amount;

      //  When there are multiple fares per shelf, set additional fares count to display
      if (faresPerShelf[FlightRatingsEnum[fareSlice.fareShelf.value]] > 1) {
        gridFare["additionalFares"] =
          faresPerShelf[FlightRatingsEnum[fareSlice.fareShelf.value]] - 1;
      }

      if (reward) {
        gridFare["reward"] = getRewardsString(reward, true);
      }

      const fareInFlights = multicityFlights.fares[getFareId(fare)];

      if (fareInFlights && "corporateTravel" in fareInFlights) {
        gridFare["corporateTravel"] = fareInFlights.corporateTravel;
      }

      // When there are multiple fares per shelf, display the lowest on the grid level
      fares[FlightRatingsEnum[fareSlice.fareShelf.value]] = getLowerGridFare(
        fares[FlightRatingsEnum[fareSlice.fareShelf.value]],
        gridFare
      );
    });
    return fares;
  }

  function getLowerGridFare(existingFare: any, newFare: any) {
    const existingPrice = existingFare?.rawPrice;
    return existingPrice && existingPrice < newFare.rawPrice
      ? existingFare
      : newFare;
  }

  const gridFares = React.useMemo(
    () => getFlightGridFares(flight, rewardsKey),
    [flight, rewardsKey]
  );

  const offerToShow = React.useMemo(() => {
    const bestOffer =
      offersByTripId?.[
        flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
      ];

    return bestOffer?.amount.amount === credit?.amount.amount
      ? undefined
      : bestOffer;
  }, [offersByTripId, credit]);

  const fareSlice = multicityFlights.fareSlices[getFareSliceId(selectedFare)];

  const showListView =
    (!slice?.domestic && !ngsEnabled) || useRowFlightListInfoOnly;

  const tags = [
    fareSlice?.tags.isBest ? AlgomerchTag.BestFlight : null,
    fareSlice?.tags.isCheapest ? AlgomerchTag.Cheapest : null,
    fareSlice?.tags.isBestQuality ? AlgomerchTag.BestQuality : null,
    fareSlice?.tags.isFastest ? AlgomerchTag.Fastest : null,
  ]
    .filter((t): t is AlgomerchTag => t !== null)
    .map((value) => ({ value, type: TagType.Algomerch }));

  const fareAmount: Prices | undefined = selectedFare.amount as Prices;
  const fareFiat = fareAmount?.fiat;
  const fareReward: RewardsPrice | undefined = fareAmount?.rewards[rewardsKey];
  const currentPriceText = fareFiat
    ? getPricesWithComma(
        getTotalPriceText({
          price: fareFiat,
        })
      )
    : "";
  const rewardsPriceText =
    !!rewardsKey && !!fareReward ? getRewardText({ reward: fareReward }) : "";

  const trackViewedOfferFaq = () => {
    trackEvent({
      eventName: VIEWED_OFFER_FAQ,
      properties: {
        screen: SelectedTravelOfferScreen.FLIGHT_SHOP,
      },
    });
  };

  const trackOfferEngagementEvent = () => {
    trackEvent({
      eventName: ENGAGED_OFFER_CTA,
      properties: {
        location: SelectedTravelOfferScreen.FLIGHT_SHOP,
        entry_type: "tooltip",
        funnel: offerToShow?.funnels.join(","),
        offer_name: offerToShow?.trackingPropertiesV2?.properties?.offer_name,
      },
      encryptedProperties: [
        offerToShow?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    });
  };

  const corporateTravel: CorporateTravel | undefined =
    selectedFare?.corporateTravel ??
    multicityFlights.fares[selectedFare.example?.fare]?.corporateTravel;

  const renderRowDesktopFlightListInfo = () => {
    return (
      <ButtonWrap
        className="flight-row-wrapper"
        aria-label="More flight details"
        onClick={handleOnClick}
        aria-expanded={isExpanded}
      >
        <FlightShopRow
          isAgentPortal={isAgentPortal}
          airlineCode={airlineCode}
          airlineName={airlineName}
          arrivalTime={slice.arrival}
          className={clsx("small-flight-shop-row", "b2b")}
          currentPriceText={showPriceAndTags ? currentPriceText : undefined}
          rewardsPriceText={
            rewardsPriceText && showPriceAndTags ? rewardsPriceText : undefined
          }
          departureTime={slice.departure}
          destinationCode={slice.destination}
          duration={formatInterval(slice.totalDurationMinutes)}
          key={slice.id}
          layoverString={textConstants.getStopsString(slice.stops)}
          onClickTag={(label: string) => onAlgomerchClick(label)}
          originCode={slice.origin}
          tags={showPriceAndTags ? tags : undefined}
          tagsLimit={FARE_GRID_TAG_LIMIT}
          airlineProps={
            isInDisruptionProtectionRebook
              ? {
                  WN: {
                    hideFlightShopRowBanner: true,
                  },
                }
              : undefined
          }
          isAirCXV3Experiment={isAirCXV3Experiment}
        />
        <TriangleIcon
          className={clsx("expand-flight-row-icon", {
            "is-expanded": isExpanded,
          })}
        />
      </ButtonWrap>
    );
  };

  const renderGridDesktopFlightListInfo = () => {
    return (
      <FlightGridRow
        key={slice.id}
        isAgentPortal={isAgentPortal}
        tripType={TripCategory.MULTI_CITY}
        className={clsx("flight-grid-row", `row-${slice.id}`)}
        airlineCode={airlineCode}
        airlineName={airlineName}
        fareClassFilter={fareClassFilter}
        selectedMaxPrice={maxFlightPriceFilter}
        fares={gridFares}
        departureTime={slice.departure}
        arrivalTime={slice.arrival}
        originCode={slice.origin}
        destinationCode={slice.destination}
        duration={formatInterval(slice.totalDurationMinutes)}
        layoverString={textConstants.getStopsString(slice.stops)}
        onFareClick={(fareId) => {
          onFareClick && onFareClick(fareId);
        }}
        algomerchModalOpen={false}
        onAlgomerchInfoClick={(label: string) => onAlgomerchClick(label)}
        tagsLimit={FARE_GRID_TAG_LIMIT}
        isExpanded={isExpanded}
        fareCardClassName="b2b"
        type="content"
        bestOffer={offerToShow}
        showOffer={isTravelWalletOfferExperiment}
        onOfferTooltipSetModalOpen={(open) => {
          if (open) {
            trackEvent({
              eventName: SELECTED_TRAVEL_OFFER,
              properties: {
                screen: SelectedTravelOfferScreen.FLIGHT_SHOP,
                ...offersByTripId?.[
                  flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                ]?.trackingPropertiesV2?.properties,
              },
              encryptedProperties: [
                offersByTripId?.[
                  flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                ]?.trackingPropertiesV2?.encryptedProperties ?? "",
              ],
            });

            trackOfferEngagementEvent();
          }
        }}
        onFirstTravelOfferCardChange={trackViewedOfferFaq}
        earnString={
          showEarnEnhancement ? (
            <>
              <Icon name={IconName.StarIcon} />
              <Typography
                className="earn-tag-text"
                dangerouslySetInnerHTML={{
                  __html: textConstants.getEarnTagText(
                    largestValueAccount!.earn.flightsMultiplier
                  ),
                }}
              />
              {}
            </>
          ) : undefined
        }
        isAirCXV3Experiment={isAirCXV3Experiment}
        {...(onOpenPolicyDescriptor &&
          corporateTravel && {
            onOpen: () =>
              onOpenPolicyDescriptor(
                PolicyDescriptorEntryPoints.FLIGHTS_LIST,
                corporateTravel.policyCompliance.reasons
              ),
          })}
        isInPolicyFilter={isInPolicyFilter}
      />
    );
  };

  const renderMobileFlightListInfo = () => {
    const airports = slice.segments
      .slice(0, slice.segments.length - 1)
      .map((s) => s.destination);

    return (
      <Box className="flight-card-wrapper">
        <CorpPolicyBanner
          variant="base"
          corporateTravel={corporateTravel}
          productType="flight"
          {...(onOpenPolicyDescriptor &&
            corporateTravel && {
              onOpen: () =>
                onOpenPolicyDescriptor(
                  PolicyDescriptorEntryPoints.FLIGHTS_LIST,
                  corporateTravel.policyCompliance.reasons
                ),
            })}
        />
        <B2BFlightCardV2
          duration={formatInterval(slice.totalDurationMinutes)}
          currentPriceText={currentPriceText}
          rewardText={rewardsPriceText}
          originCode={slice.origin}
          destinationCode={slice.destination}
          departureTime={slice.departure}
          arrivalTime={slice.arrival}
          airports={airports}
          brandName={fareSlice?.fareBrandName ?? ""}
          primaryCarrier={airlineCode}
          isAgentPortal={isAgentPortal}
          airlineName={airlineName}
          tags={tags}
          bestOffer={offerToShow}
          showOffer={isTravelWalletOfferExperiment}
          showHeader={showPriceAndTags}
          onOfferTooltipSetModalOpen={(open) => {
            if (open) {
              trackEvent({
                eventName: SELECTED_TRAVEL_OFFER,
                properties: {
                  screen: SelectedTravelOfferScreen.FLIGHT_SHOP,
                  ...offersByTripId?.[
                    flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                  ]?.trackingPropertiesV2?.properties,
                },
                encryptedProperties: [
                  offersByTripId?.[
                    flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                  ]?.trackingPropertiesV2?.encryptedProperties ?? "",
                ],
              });

              trackOfferEngagementEvent();
            }
          }}
          onFirstTravelOfferCardChange={trackViewedOfferFaq}
          earnString={
            showEarnEnhancement ? (
              <>
                <Icon name={IconName.StarIcon} />
                <Typography
                  className="earn-tag-text"
                  dangerouslySetInnerHTML={{
                    __html: textConstants.getEarnTagText(
                      largestValueAccount!.earn.flightsMultiplier
                    ),
                  }}
                />
                {}
              </>
            ) : undefined
          }
          earnTagClassName="b2b"
          isAirCXV3Experiment={isAirCXV3Experiment}
        />
      </Box>
    );
  };

  const handleOnClick = () => {
    onClick && onClick(fareSlice.fareBrandName);
    if (!matchesLargeDesktop) {
      onFareClick && onFareClick(getFareId(selectedFare));
    }
  };

  const showPolicyBanner =
    matchesMediumDesktopOnly || (matchesLargeDesktop && showListView);

  return (
    <>
      {showPolicyBanner && (
        <CorpPolicyBanner
          variant="base"
          corporateTravel={corporateTravel}
          productType="flight"
        />
      )}
      <Box
        className={clsx("flight-list-info-root", "multicity")}
        onClick={
          (matchesLargeDesktop && !showListView) || matchesMobile
            ? () => handleOnClick()
            : undefined
        }
      >
        {matchesLargeDesktop &&
          !showListView &&
          renderGridDesktopFlightListInfo()}
        {matchesLargeDesktop &&
          showListView &&
          renderRowDesktopFlightListInfo()}
        {matchesMediumDesktopOnly && renderRowDesktopFlightListInfo()}
        {matchesMobile && renderMobileFlightListInfo()}
      </Box>
    </>
  );
};

export type IFlightListInfoProps =
  | IFlightListInfoContentProps
  | IFlightListInfoSkeletonProps;

export const FlightListInfo = (props: any) => {
  switch (props.type) {
    case "content":
      return <FlightListInfoContent {...props} />;
    case "skeleton":
      return (
        <Box className={clsx("flight-list-info-root", "multicity")}>
          <FlightGridRow {...props} />
        </Box>
      );
    default:
      return <></>;
  }
};
