import { Prices } from "@b2bportal/air-booking-api";

export const SEAT_SELECTION_TITLE = "Choose seats";
export const SEAT_SELECTION_SUBTITLE = "Book seats ahead for your journey.";
export const SELECT_SEATS_MOBILE_CTA_TEXT = "Select Your Seats";
export const SKIP_SEAT_SELECTION_SUBTITLE =
  "Skip seats. Seats can be added on the airline website after booking, or will be assigned at check-in.";
export const SKIP_SEAT_SELECTION_CTA = "Continue without seats";
export const MOBILE_SEAT_SELECTION_TITLE = "Seat Selection";
export const MOBILE_SKIP_SEAT_SELECTION_SUBTITLE =
  "If you decide to skip this step, seats will be assigned at check-in";

export const SEAT_MAP_MODAL_TITLE = "Choose Seats";

export const getSeatSelectionCTAText = (cheapestSeat: Prices | null) => {
  if (cheapestSeat?.fiat.value === 0) {
    return `Select seats for free`;
  } else {
    return `Select your seats`;
  }
};
