import axios, { AxiosResponse } from "axios";
import { delay, put, putResolve, select, take } from "redux-saga/effects";
import {
  CallState,
  FlightBookType,
  GeneralPurchaseErrorEnum,
  GetPriceFreezeRequestEnum,
  GetPriceFreezeResponse,
  PRICE_QUOTE_AIR,
  PRICE_QUOTE_MULTICITY_AIR,
  PriceFreezePriceQuote,
  RewardsPrice,
  TripCategory,
  UncategorizedPurchaseError,
} from "redmond";
import { DO_NOT_APPLY_REWARDS_KEY, isCorpTenant } from "@capone/common";
import { getTripCategory } from "../../../search/reducer";
import { PriceQuoteData } from "@b2bportal/air-booking-api";
import {
  Product,
  QuoteFailure,
  QuoteResponse,
  QuoteResponseEnum,
  QuoteSuccess,
  purchaseApi,
} from "@b2bportal/purchase-api";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import {
  getAncillaries,
  getFlightBookType,
  getIsSimilarFlightsEnabled,
  getPaymentsV2,
  getPriceQuoteAirProperties,
  getSession,
  getTravelOfferProperties,
  getTripPricing,
  getPurchaseError,
  isRefetchingPriceQuoteSelector,
  getPriceQuoteWithUpdatedAncillary,
  activePriceFreezeQuoteDataSelector,
  getReviewFlightDetailsProperties,
} from "../../reducer";
import { currentPriceFreezeSelector } from "../../../freeze/reducer/selectors";
import Logger from "../../../../helpers/Logger";
import {
  setPollQuoteCallStateFailure,
  setPollQuoteCallStateSuccess,
  setQuote,
  setQuotedRewardsTotal,
} from "../../actions/actions";
import {
  setPriceFreeze,
  setPriceFreezeQuoteData,
} from "../../../freeze/actions/actions";
import { actions } from "../../actions";
import { fetchSimilarFlights } from "../../../shop/actions/actions";
import {
  SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
  SET_SIMILAR_FLIGHTS_RESPONSE,
} from "../../../shop/actions/constants";
import { getPriceFreeze } from "../../../../api/v0/price-freeze/getPriceFreeze";
import { convertUsdToRewards } from "../../../../api/v0/rewards/convertUsdToRewards";
import {
  getRewardsAccounts,
  getSelectedAccountReferenceId,
} from "../../../rewards/reducer";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";
import { getViewedTripSummaryProperties } from "../../../shop/reducer";

export const delayTimes = [1000];

const defaultRewardsAccountRefId = isCorpTenant(config.TENANT)
  ? DO_NOT_APPLY_REWARDS_KEY
  : null;

export function* pollQuoteSaga({
  agentFee,
  pollQuoteOnly,
  isPriceFreezePurchase,
}: actions.IPollQuote) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const isMulticity = getTripCategory(state) == TripCategory.MULTI_CITY;
    const eventName = isMulticity ? PRICE_QUOTE_MULTICITY_AIR : PRICE_QUOTE_AIR;
    const priceQuoteAirProps = getPriceQuoteAirProperties(state);
    const travelOfferProperties = getTravelOfferProperties(state);
    const viewedTripSummaryProperties = getViewedTripSummaryProperties(state);
    
    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    let pollFailed = false;
    let index = 0;
    const startTime = dayjs();

    while (!pollFailed) {
      yield delay(delayTimes[index]);

      const priceQuoteCheckedResponse: AxiosResponse<QuoteResponse> = 
        yield purchaseApi(axios as any).apiV0PurchaseQuotePollPost(
          sessionToken
        );

      switch (priceQuoteCheckedResponse.data.QuoteResponse) {
        case QuoteResponseEnum.Failure:
          pollFailed = true;
          const failedResponse = priceQuoteCheckedResponse.data as QuoteFailure;
          if (failedResponse.errors.length > 0) {
            const flightBookType = getFlightBookType(state);
            switch (flightBookType) {
              case FlightBookType.PRICE_FREEZE_EXERCISE:
                const isSimilarFlightsEnabled =
                  getIsSimilarFlightsEnabled(state);

                if (isSimilarFlightsEnabled) {
                  // note: when it's on PF exercise, check for NoAvailability error code,
                  // and call /pricefreeze/similarflight/list endpoint to check for similar-flight availability
                  const noAvailabilityError = failedResponse.errors.find(
                    (error) =>
                      getPurchaseError(error) ===
                      GeneralPurchaseErrorEnum.NoAvailability
                  );

                  if (noAvailabilityError) {
                    // note: /pricefreeze/get is called on start, so this field should already be populated
                    const priceFreezeId =
                      currentPriceFreezeSelector(state)?.priceFreeze.id ?? "";

                    yield put(
                      fetchSimilarFlights({
                        id: priceFreezeId,
                        refreshPriceFreeze: false,
                      })
                    );
                    // note: wait for fetchSimilarFlights to finish
                    yield take([
                      SET_SIMILAR_FLIGHTS_RESPONSE,
                      SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED,
                    ]);
                  }
                }
                break;
              default:
                break;
            }

            yield putResolve(
              setPollQuoteCallStateFailure(
                failedResponse.errors.map(getPurchaseError)
              )
            );

            trackEvent({
              eventName: eventName,
              properties: {
                ...priceQuoteAirProps,
                ...travelOfferProperties.properties,
                failure_reason:
                  failedResponse.errors.length > 0
                    ? failedResponse.errors
                        .map((e) => {
                          const error = getPurchaseError(e);
                          if (!!(error as UncategorizedPurchaseError)?.code) {
                            return (error as UncategorizedPurchaseError)?.code;
                          } else {
                            return error;
                          }
                        })
                        .join(" - ")
                    : "Poll quote checked response returned an error and the given error code is not handleable.",
              },
              encryptedProperties: [
                ...travelOfferProperties.encryptedProperties,
              ],
            });
            return;
          }
          trackEvent({
            eventName: eventName,
            properties: {
              ...priceQuoteAirProps,
              ...travelOfferProperties.properties,
              failure_reason:
                "Poll quote checked response returned an error and the given error code is not handleable.",
            },
            encryptedProperties: [...travelOfferProperties.encryptedProperties],
          });
          yield putResolve(setPollQuoteCallStateFailure([]));
          throw new Error(
            "Poll quote checked response returned an error and the given error code is not handleable."
          );

        case QuoteResponseEnum.Pending:
          break;
        case QuoteResponseEnum.Success:
          const priceQuoteResponse =
            priceQuoteCheckedResponse.data as QuoteSuccess;
          yield put(actions.setPriceQuoteSuccessTime(dayjs().toISOString()));
          // failed customer validation code is unreachable so always mark customer validation as successful
          yield put(actions.setPassengersValid());
          yield put(actions.setPassengerValidationCallStateSuccess());
          trackEvent({
            eventName: eventName,
            properties: {
              ...priceQuoteAirProps,
              ...travelOfferProperties.properties,
              success: true,
              load_time: dayjs().diff(startTime, "seconds", true),
            },
            encryptedProperties: [...travelOfferProperties.encryptedProperties],
          });

          let readyToProceedPayment = false;
          switch (priceQuoteResponse.quote.type) {
            case Product.Flight:
              if (isPriceFreezePurchase) {
                throw new Error("unexpected quote product type");
              }

              const isRefetchingPriceQuote =
                isRefetchingPriceQuoteSelector(state);
              const quote = priceQuoteResponse.quote.value as PriceQuoteData;
              const amountToPay =
                quote.itinerary.sellingPricing.totalPricing.total.fiat.value;
              // note: in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting, the estimate can be based on the persisted priceQuote + ancillaries from tripPrice
              const estimate = isRefetchingPriceQuote
                ? getPriceQuoteWithUpdatedAncillary(state)?.itinerary
                    .sellingPricing.totalPricing.total.fiat.value
                : getTripPricing(state)?.totalPricing.total.fiat.value;
              readyToProceedPayment = amountToPay === estimate;

              yield putResolve(
                setQuote(
                  quote,
                  /*
                    note: while it's refetching priceQuote (and it's on the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting),
                    preserve the selected payment when the priceQuote total is equal to the tripPrice total.
                  */
                  isRefetchingPriceQuote ? readyToProceedPayment : false
                )
              );
              const accountId = getSelectedAccountReferenceId(state);
              const accounts = getRewardsAccounts(state) || [];
              const account = accounts.find(
                (a) => a.accountReferenceId === accountId
              );

              const priceFreezeId =
                quote.creditSummary?.credits.totalCredits
                  .priceFreezeExerciseCredit?.priceFreezeId;
              const flightBookType = getFlightBookType(state);

              // note: when priceFreezeId is given in the response and it's not already on the PF exercise flow, switch the user to exercise
              if (priceFreezeId && flightBookType === FlightBookType.DEFAULT) {
                const priceFreeze: GetPriceFreezeResponse =
                  yield getPriceFreeze({
                    GetPriceFreezeRequest:
                      GetPriceFreezeRequestEnum.ByPriceFreezeId,
                    id: priceFreezeId,
                  });

                yield putResolve(
                  setPriceFreeze({
                    priceFreeze,
                    priceFreezeCallState: CallState.Success,
                  })
                );

                yield putResolve(
                  actions.setFlightBookType(
                    FlightBookType.PRICE_FREEZE_EXERCISE
                  )
                );
              }

              // if the selected account is tiered get the rewards total from the BE
              if (account?.isTiered && accountId) {
                const rewardsTotal: RewardsPrice = yield convertUsdToRewards({
                  amount: amountToPay,
                  accountReferenceId: accountId,
                });
                yield putResolve(setQuotedRewardsTotal(rewardsTotal));
              } else {
                yield putResolve(setQuotedRewardsTotal(null));
              }
              break;

            case Product.AirPriceFreeze:
              if (!isPriceFreezePurchase) {
                throw new Error("unexpected quote product type");
              }

              const priceFreezeQuote = priceQuoteResponse.quote
                .value as PriceFreezePriceQuote;
              const currentPriceFreezeQuote =
                activePriceFreezeQuoteDataSelector(state);

              readyToProceedPayment =
                priceFreezeQuote.totalAmount.fiat.value ===
                currentPriceFreezeQuote?.totalAmount.fiat.value;

              /*
                note: this condition technically will never be true, because the PF fee is expected to be consistent in shop & price quote;
                when the condition does somehow become true, we should reset the selected payment so that the user gets to prepare payment again
              */
              if (!readyToProceedPayment) {
                yield putResolve(actions.resetPaymentCardSelectedAccounts());
                yield putResolve(
                  actions.setSelectedRewardsPaymentTotal(
                    "",
                    null,
                    null,
                    undefined
                  )
                );
              }

              yield putResolve(setPriceFreezeQuoteData(priceFreezeQuote));
              break;
            default:
              break;
          }

          yield putResolve(setPollQuoteCallStateSuccess());
          if (readyToProceedPayment) {
            const session = getSession(state);
            const payments = getPaymentsV2(state);
            const ancillaries = getAncillaries(state);

            if (session && payments && ancillaries && !pollQuoteOnly) {
              yield put(
                actions.schedulePayment({
                  session,
                  payments,
                  ancillaries,
                  agentFee,
                })
              );
            }
          } else {
            yield put(
              actions.setSelectedPaymentMethodId({
                paymentMethodId: "",
                accountId: undefined,
                multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
              })
            );
            yield put(
              actions.setSelectedRewardsAccountReferenceId(
                defaultRewardsAccountRefId
              )
            );
          }
          return;
      }
      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    const state: IStoreState = yield select();
    const priceQuoteAirProps = getPriceQuoteAirProperties(state);
    const reviewFlightDetailsProperties =
      getReviewFlightDetailsProperties(state);
    const travelOfferProperties = getTravelOfferProperties(state);
    trackEvent({
      eventName: PRICE_QUOTE_AIR, // default to normal price_quote event (not multicity)
      properties: {
        ...priceQuoteAirProps,
        ...reviewFlightDetailsProperties,
        ...travelOfferProperties.properties,
        failure_reason: "Poll quote saga failed",
      },
      encryptedProperties: [...travelOfferProperties.encryptedProperties],
    });
    Logger.debug(`Poll quote saga failed: ${e}`);
    yield putResolve(setPollQuoteCallStateFailure([]));
  }
}
