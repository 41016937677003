import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import queryStringParser from "query-string";
import {
  ContactInfoWorkflow,
  IContactInfo,
  TravelerSelectStep,
  ContactInfoStep,
  Header,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  LoadingPopup,
  B2BSpinner,
  emailRegex,
  phoneRegex,
  Icon,
  IconName,
  TreesConfirmation,
  InformationalModal as TreesModal,
  TravelWalletSingleSelectionStep,
  HotelCrossSellAwarenessCard,
  VoidWindowNotice,
} from "halifax";

import "./styles.scss";
import { FlightShopSummaryPanel } from "../../../../shop/components/FlightShopSummaryPanel";
import { MobileFlightBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  FlightBookPassengerSelection,
  PaymentCard,
  FlightBookMobileButton,
  PriceBreakdownDropdown,
  MobileSeatSelection,
  PriceDropProtection,
} from "../..";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_SUBTITLE,
  REVIEW_MY_TRIP_TEXT,
  TITLE,
  SUBTITLE,
  PRICE_QUOTE_PASSENGER_VALIDATION_MESSAGE,
  SEAT_SELECTION_TITLE,
  PRICE_QUOTE_MESSAGE,
  CHECKOUT_PRICE_BREAKDOWN_HEADER,
  UPDATE_TRAVELERS,
  EDIT_TRAVELERS,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
} from "./textConstants";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_BOOK_WITH_PF_CONFIRMATION,
  PATH_BOOK_WITH_VI_CONFIRMATION,
  PATH_PRICE_FREEZE_CUSTOMIZE,
} from "../../../../../utils/urlPaths";
import { RouteComponentProps } from "react-router";
import { ClientContext } from "../../../../../App";
import {
  PriceDropProtectionEnum,
  PriceDropViewedProperties,
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
  ADD_CONTACT_INFO,
  FlightShopStep,
  FlightBookType,
  PRICE_FREEZE_ID_QUERY_PARAM,
  ProductFeedLoadingProperties,
  PRODUCT_FEED_LOADING,
  TripCategory,
} from "redmond";
import { AGENT_FEE } from "../../index";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import clsx from "clsx";
import { SeatSelectionStep } from "../../MobileSeatSelection";
import {
  AVAILABLE,
  SEAT_SELECTION,
  getExperimentVariant,
  useExperiments,
  TREES_MODAL_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  getExperimentVariantCustomVariants,
  VOID_WINDOW_EXPERIMENT,
  CREDIT_OFFER_STACKING_V1,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  SEATS_UX_OPTIMIZATION,
  PASSPORT_ENHANCEMENT,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  CONTROL,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
} from "../../../../../context/experiments";
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import { usePrevious } from "../../../../../hooks/usePrevious";
import { MobileFlightBookWorkflowStep } from "../../../component";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import { useSelector } from "react-redux";
import { getPriceDropRefundTypeSelector } from "../../../../ancillary/reducer/selectors";
import { MulticityFlightShopStep } from "../../../../shop/reducer";
import {
  IVirtualInterliningVariant,
  VirtualIinterliningModal,
} from "../../../../shop/components/VirtualIinterliningModal";
import { MultipleAirlinesFareModal } from "../../../../shop/components/MultipleAirlinesFareModal";

export interface IMobileFlightBookWorkflowProps
  extends RouteComponentProps,
    MobileFlightBookWorkflowConnectorProps {
  checkoutStep: MobileFlightBookWorkflowStep;
  setCheckoutStep: (step: any) => void;
  localTravelerIds: String[];
  setLocalTravelerIds: (ids: string[]) => void;
  localLapInfantIds: String[];
  setLocalLapInfantIds: (ids: string[]) => void;
}

export const MobileFlightBookWorkflow = ({
  isWaitingValidation,
  selectedTrip,
  hasNoUserPassengers,
  history,
  finalizedItinerary,
  isBookingValid,
  scheduleQuote,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDropProtection,
  priceDropViewedProperties,
  populateFlightShopQueryParams,
  priceQuote,
  schedulePayment,
  currentSession,
  payments,
  ancillaries,
  seatMapAvailability,
  isWaitingPriceQuote,
  seatMapLoading,
  resetPaymentCardSelectedAccounts,
  hasError,
  errorTitles,
  setFlightShopProgress,
  missingPassport,
  checkoutStep,
  setCheckoutStep,
  flightBookType,
  fetchApplicableTravelWalletItems,
  offers,
  localLapInfantIds,
  setLocalLapInfantIds,
  localTravelerIds,
  setLocalTravelerIds,
  credit,
  fetchInitialCrossSellHotelAvailability,
  airports,
  isVoidWindowEligible,
  isTravelWalletPaymentOnly,
  priceQuoteInProgress,
  creditToApply,
  fetchTravelWalletCreditHistory,
  hasSelectedDisruption,
  smsBeenSelected,
  appNotifBeenSelected,
  toggleAppNotifOptIn,
  toggleSMSOptIn,
  disruptionOptInEnabled,
  tcpaConsentPhoneNumber,
  setUserTcpaConsentPhoneNumber,
  isInternationalFlight,
  setMulticityFlightShopProgress,
  tripCategory,
  isCfarMulticityEnabled,
  tripDetails,
  disruptionDelayThreshold,
  potentialCrossSellOffers,
}: IMobileFlightBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;

  const expState = useExperiments();
  const seatSelectionGroup = getExperimentVariant(
    expState.experiments,
    SEAT_SELECTION
  );
  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );

  const isSeatSelection = useMemo(() => {
    return seatSelectionGroup === AVAILABLE;
  }, [seatSelectionGroup]);

  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const isVoidWindowExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, VOID_WINDOW_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );
  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = tripDetails.fareDetails[0]?.slices.some((slice) =>
    slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  );

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openVIVariantModal, setOpenVIVariantModal] = useState<
    IVirtualInterliningVariant | "reminder" | false
  >(false);

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openMultipleAirlinesFares, setOpenMultipleAirlinesFares] =
    useState(false);

  const passportEnhancementExperiment = getExperimentVariant(
    expState.experiments,
    PASSPORT_ENHANCEMENT
  );
  const isPassportEnhancementExperiment = useMemo(() => {
    return passportEnhancementExperiment === AVAILABLE;
  }, [passportEnhancementExperiment]);

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  const priceDropRefundType = useSelector(getPriceDropRefundTypeSelector);

  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);

  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [seatSelectionStep, setSeatSelectionStep] = useState<SeatSelectionStep>(
    SeatSelectionStep.Main
  );
  const [travelWalletSelectStep, setTravelWalletSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [updatedInfoOnReview, setUpdatedInfoOnReview] = useState(false);

  const isMulticity: boolean = tripCategory === TripCategory.MULTI_CITY;

  const disruptionDelayThresholdStringInHours =
    disruptionDelayThreshold === 180 ? "3" : "2";

  useEffect(() => {
    if (isCfarMulticityEnabled && isMulticity) {
      // Multi-city tracks flight shop progress a little differently. Book is a step in itself.
      setMulticityFlightShopProgress(MulticityFlightShopStep.BookTrip);
    }
  }, []);

  const isPDPEligible =
    priceDropProtection &&
    priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;

  const onBookFlight = () => {
    if (selectedTrip.tripId) {
      priceQuote && currentSession && payments && ancillaries
        ? schedulePayment({
            session: currentSession,
            payments,
            ancillaries,
            agentFee: isAgentPortal ? AGENT_FEE : 0,
          })
        : scheduleQuote({ agentFee: isAgentPortal ? AGENT_FEE : 0 });
    }
  };

  const openFlightBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openSeatSelectionWorkflow = () => {
    setSeatSelectionStep(SeatSelectionStep.SeatSelection);
  };

  const openTravelOffersSelection = () => {
    setTravelWalletSelectStep(
      TravelWalletSingleSelectionStep.TravelWalletSelection
    );
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  const incrementCheckoutStep = () => {
    setCheckoutStep((step: any) => {
      switch (step) {
        case MobileFlightBookWorkflowStep.TravelerInformation:
          return step + 2;
        case MobileFlightBookWorkflowStep.Review:
          return step;
        case MobileFlightBookWorkflowStep.ContactInformation:
          if (!isSeatSelection || !seatMapAvailability) {
            if (
              !isCreditAndOfferStackingExperimentV1 &&
              ((isTravelWalletCreditsExperiment ? !!credit : false) ||
                (isTravelWalletOfferExperiment ? !!offers?.length : false))
            ) {
              return step + 2;
            }
            return step + 3;
          } else {
            return step + 1;
          }
        case MobileFlightBookWorkflowStep.SeatSelection:
          if (
            !isCreditAndOfferStackingExperimentV1 &&
            ((isTravelWalletCreditsExperiment ? !!credit : false) ||
              (isTravelWalletOfferExperiment ? !!offers?.length : false))
          ) {
            return step + 1;
          }
          return step + 2;
        case MobileFlightBookWorkflowStep.TravelOfferSelection:
          if (isTravelWalletPaymentOnly) {
            return step + 2;
          } else {
            return step + 1;
          }
        default:
          return step + 1;
      }
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case MobileFlightBookWorkflowStep.TravelerInformation:
        switch (flightBookType) {
          case FlightBookType.PRICE_FREEZE_EXERCISE:
            const priceFreezeIdFromQuery = new URLSearchParams(
              history.location.search
            ).get(PRICE_FREEZE_ID_QUERY_PARAM);
            history.push({
              pathname: PATH_PRICE_FREEZE_CUSTOMIZE,
              search: queryStringParser.stringify({
                [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeIdFromQuery,
              }),
              state: {
                prevPath: PATH_BOOK,
              },
            });
            break;
          case FlightBookType.DEFAULT:
          default:
            populateFlightShopQueryParams({
              history,
              prevPath: PATH_BOOK,
              useHistoryPush: true,
              newQueryParams: isMulticity
                ? {
                    multicityFlightShopProgress:
                      MulticityFlightShopStep.ReviewItinerary,
                  }
                : {
                    flightShopProgress: FlightShopStep.ReviewItinerary,
                  },
            });
            if (isMulticity) {
              setMulticityFlightShopProgress(
                MulticityFlightShopStep.ReviewItinerary
              );
            } else {
              setFlightShopProgress(FlightShopStep.ReviewItinerary);
            }
            break;
        }
        break;
      case MobileFlightBookWorkflowStep.ContactInformation:
        // note: skip PricingAndPassengerValidation step when going back from ContactInformation
        setCheckoutStep(MobileFlightBookWorkflowStep.TravelerInformation);
        break;
      case MobileFlightBookWorkflowStep.SeatSelection:
        // note: skip PricingAndPassengerValidation step when going back from ContactInformation
        setCheckoutStep(MobileFlightBookWorkflowStep.ContactInformation);
        break;
      case MobileFlightBookWorkflowStep.TravelOfferSelection:
        if (!isSeatSelection || !seatMapAvailability) {
          setCheckoutStep((step: any) => step - 2);
        } else {
          setCheckoutStep((step: any) => step - 1);
        }
        break;
      case MobileFlightBookWorkflowStep.RewardsAndPayment:
        if (!isSeatSelection || !seatMapAvailability) {
          setCheckoutStep((step: any) =>
            !isCreditAndOfferStackingExperimentV1 &&
            ((isTravelWalletCreditsExperiment ? !!credit : false) ||
              (isTravelWalletOfferExperiment ? !!offers?.length : false))
              ? step - 1
              : step - 3
          );
        } else {
          setCheckoutStep((step: any) =>
            !isCreditAndOfferStackingExperimentV1 &&
            ((isTravelWalletCreditsExperiment ? !!credit : false) ||
              (isTravelWalletOfferExperiment ? !!offers?.length : false))
              ? step - 1
              : step - 2
          );
        }
        break;
      case MobileFlightBookWorkflowStep.Review:
        if (isTravelWalletPaymentOnly) {
          setCheckoutStep((step: any) => step - 2);
        } else {
          setCheckoutStep((step: any) => step - 1);
        }
        break;
      default:
        setCheckoutStep((step: any) => step - 1);
        break;
    }
  };

  const closeAllPopovers = () => {
    if (isSeatSelection) {
      setTravelerWorkflowStep(TravelerSelectStep.Main);
      setContactInfoStep(ContactInfoStep.Main);
      setSeatSelectionStep(SeatSelectionStep.Main);
      setTravelWalletSelectStep(TravelWalletSingleSelectionStep.Main);
      setOpenPaymentCard(false);
    } else {
      setTravelerWorkflowStep(TravelerSelectStep.Main);
      setContactInfoStep(ContactInfoStep.Main);
      setTravelWalletSelectStep(TravelWalletSingleSelectionStep.Main);
      setOpenPaymentCard(false);
    }
  };

  const handlePriceDropTracking = () =>
    trackEvent({
      eventName: VIEWED_PRICE_DROP_DETAILS,
      properties: {
        page:
          checkoutStep === MobileFlightBookWorkflowStep.Review
            ? "trip_review"
            : "payment_summary",
        refund_type: priceDropRefundType,
      },
    });

  const startSchedulePriceQuoteAndResetPayment = () => {
    scheduleQuote({
      agentFee: isAgentPortal ? AGENT_FEE : 0,
      pollQuoteOnly: true,
    });
    // note: reset selected rewards account and payment method ids
    resetPaymentCardSelectedAccounts();
    setUpdatedInfoOnReview(true);
  };

  const renderPriceBreakdowndropdown = (popoverClassName?: string) => {
    return (
      <PriceBreakdownDropdown
        handlePriceDropTracking={handlePriceDropTracking}
        popoverClassName={popoverClassName}
      />
    );
  };

  useEffect(() => {
    switch (checkoutStep) {
      case MobileFlightBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openFlightBookPassengerSelection();
        break;
      case MobileFlightBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case MobileFlightBookWorkflowStep.SeatSelection:
        closeAllPopovers();
        openSeatSelectionWorkflow();
        break;
      case MobileFlightBookWorkflowStep.TravelOfferSelection:
        closeAllPopovers();
        openTravelOffersSelection();
        break;
      case MobileFlightBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep === MobileFlightBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  const prevMissingPassportError = usePrevious(missingPassport);
  useEffect(() => {
    if (
      hasError &&
      (errorTitles.primaryButtonText === UPDATE_TRAVELERS ||
        errorTitles.secondaryButtonText === EDIT_TRAVELERS) &&
      !missingPassport
    ) {
      setCheckoutStep(MobileFlightBookWorkflowStep.TravelerInformation);
    }
    if (prevMissingPassportError && !missingPassport) {
      if (!!priceQuoteInProgress && isPassportEnhancementExperiment) {
        return;
      } else {
        setCheckoutStep(MobileFlightBookWorkflowStep.TravelerInformation); // goes back selection AFTER passport info is updated from the missing passport info error
      }
    }
  }, [
    hasError,
    errorTitles,
    missingPassport,
    priceQuote,
    priceQuoteInProgress,
  ]);

  useEffect(() => {
    if (finalizedItinerary) {
      if (isPDPEligible) {
        const properties: PriceDropViewedProperties = {
          ...priceDropViewedProperties,
          page:
            checkoutStep === MobileFlightBookWorkflowStep.Review
              ? "trip_review"
              : "payment_summary",
        };
        trackEvent({
          eventName: PRICE_DROP_VIEWED,
          properties,
        });
      }

      const properties: ProductFeedLoadingProperties = {
        feed_type: "cross_sell_hotels",
        feed_placement: "flight_confirmation",
      };
      trackEvent({ eventName: PRODUCT_FEED_LOADING, properties });
      fetchInitialCrossSellHotelAvailability(finalizedItinerary, airports);

      if (history.location.search.includes(PRICE_FREEZE_ID_QUERY_PARAM)) {
        history.push(PATH_BOOK_WITH_PF_CONFIRMATION);
      } else if (isVITripSelected) {
        history.push(PATH_BOOK_WITH_VI_CONFIRMATION);
      } else {
        history.push(PATH_BOOK_CONFIRMATION);
      }
    }
  }, [finalizedItinerary]);

  useEffect(() => {
    if (
      checkoutStep === MobileFlightBookWorkflowStep.Review &&
      !!priceQuote &&
      !priceQuoteInProgress &&
      updatedInfoOnReview
    ) {
      if (isSeatSelection && seatMapAvailability) {
        setCheckoutStep(MobileFlightBookWorkflowStep.SeatSelection); // if seats are avail after PQ is run again, bring user back to step to select seats
      } else if (creditToApply) {
        setCheckoutStep(MobileFlightBookWorkflowStep.TravelOfferSelection); // if user has selected a travel wallet item before PQ is run again on pax/contact info update, bring them back to that step
      } else {
        handleGoBack();
      }
      setUpdatedInfoOnReview(false);
    }
  }, [
    checkoutStep,
    priceQuote,
    priceQuoteInProgress,
    updatedInfoOnReview,
    seatMapAvailability,
  ]);

  useEffect(() => {
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setUserTcpaConsentPhoneNumber(details?.phoneNumber || "");
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
  }, []);

  useEffect(() => {
    if (
      checkoutStep === MobileFlightBookWorkflowStep.ContactInformation &&
      !!priceQuote
    ) {
      incrementCheckoutStep(); // Increment checkout step AFTER PQ comes back to see if user has seats or not to determine next checkout step
    }
  }, [priceQuote]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, [checkoutStep]);

  return (
    <>
      <Box
        className={clsx("mobile-flight-book-workflow-root", {
          "mobile-review-flight-book":
            checkoutStep === MobileFlightBookWorkflowStep.Review,
          "global-mobile-nav": isGlobalMobileNavExperiment,
        })}
      >
        <Header
          className="mobile-flight-book-workflow-header"
          left={
            <BackButton
              className="mobile-flight-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={
            checkoutStep === MobileFlightBookWorkflowStep.Review
              ? renderPriceBreakdowndropdown(
                  "mobile-review-flight-book-price-breakdown"
                )
              : renderPriceBreakdowndropdown()
          }
          isMobile={true}
          fullWidth={true}
        />
        {isVoidWindowExperiment && isVoidWindowEligible && <VoidWindowNotice />}
        <Box className="title-summary-container">
          <Typography variant="h6" className="title">
            {TITLE}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {SUBTITLE}
          </Typography>
        </Box>
        <FlightShopSummaryPanel
          showViewDetailsSection={true}
          prevPath={PATH_BOOK}
          isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
          isVITripSelected={isVITripSelected}
          setOpenVIVariantModal={setOpenVIVariantModal}
          setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
        />
        {isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0 ? (
          <HotelCrossSellAwarenessCard
            isMobile
            offerText={potentialCrossSellOffers[0].mainDescription}
            offerPillText={potentialCrossSellOffers[0].subDescription}
          />
        ) : undefined}
        <FlightBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileFlightBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case MobileFlightBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue; this is to avoid tricky cases such as having deleted
                // a selected traveler from list -> requires selected travelers to be updated -> needs to re-schedule price quote
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileFlightBookWorkflowStep.Review:
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={renderPriceBreakdowndropdown()}
          className="b2b"
          onReviewStep={checkoutStep === MobileFlightBookWorkflowStep.Review}
          isMobile={true}
          useLocalIds={true}
          localLapInfantIds={localLapInfantIds}
          setLocalLapInfantIds={setLocalLapInfantIds}
          localTravelerIds={localTravelerIds}
          setLocalTravelerIds={setLocalTravelerIds}
          showVoidWindowNotice={isVoidWindowExperiment && isVoidWindowEligible}
          showPassportAwareness={
            isPassportEnhancementExperiment && isInternationalFlight
          }
          hiddenTravelerIds={[]}
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE,
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === MobileFlightBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          isMobile={true}
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          contactInfo={contactInfo}
          hasSelectedDisruption={hasSelectedDisruption}
          smsBeenSelected={smsBeenSelected}
          appNotifBeenSelected={appNotifBeenSelected}
          toggleAppNotifOptIn={toggleAppNotifOptIn}
          toggleSMSOptIn={toggleSMSOptIn}
          disruptionOptInEnabled={disruptionOptInEnabled}
          tcpaConsentPhoneNumber={tcpaConsentPhoneNumber}
          onGoBack={() => {
            if (
              checkoutStep === MobileFlightBookWorkflowStep.ContactInformation
            ) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(contactInfoUpdate) => {
            setContact(contactInfoUpdate);
            setContactInfo(
              contactInfoUpdate.email,
              contactInfoUpdate.phoneNumber
            );
            if (
              contactInfoUpdate.email &&
              contactInfoUpdate.phoneNumber &&
              emailRegex.test(contactInfoUpdate.email) &&
              phoneRegex.test(contactInfoUpdate.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
          }}
          mobileHeaderElement={renderPriceBreakdowndropdown()}
          className="b2b"
          onContinueClick={() => {
            switch (checkoutStep) {
              case MobileFlightBookWorkflowStep.Review:
                startSchedulePriceQuoteAndResetPayment();
                break;
              default:
                scheduleQuote({
                  agentFee: isAgentPortal ? AGENT_FEE : 0,
                  pollQuoteOnly: true,
                });
                break;
            }
          }}
          loading={customerDetailsLoading}
          bottomContent={
            isVoidWindowExperiment && isVoidWindowEligible ? (
              <VoidWindowNotice />
            ) : undefined
          }
          disruptionDelayThresholdStringInHours={
            disruptionDelayThresholdStringInHours
          }
        />
        {isSeatSelection && (
          <MobileSeatSelection
            title={SEAT_SELECTION_TITLE}
            progressStep={seatSelectionStep}
            setProgressStep={setSeatSelectionStep}
            mobileHeaderElement={renderPriceBreakdowndropdown()}
            onGoBack={() => {
              if (checkoutStep === MobileFlightBookWorkflowStep.SeatSelection) {
                handleGoBack();
              }
            }}
            onContinue={() => incrementCheckoutStep()}
          />
        )}
        {isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelWalletSelectStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  MobileFlightBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelWalletSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <Box className="pricing-breakdown-header-wrapper">
          <Box className="pricing-breakdown-header-container">
            <Typography className="header" variant="h6">
              {CHECKOUT_PRICE_BREAKDOWN_HEADER}
            </Typography>
          </Box>
        </Box>
        {priceDropProtection && isPDPEligible && (
          <PriceDropProtection
            priceDropProtection={priceDropProtection}
            onClick={handlePriceDropTracking}
          />
        )}
        <PriceBreakdown
          isMobile
          className={clsx({ "less-padding": isTreesModalExperiment })}
        />
        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}
        <FlightBookMobileButton
          hasContactInfo={!!contactInfo}
          openContactInfo={() =>
            setContactInfoStep(ContactInfoStep.ContactInfoForm)
          }
          isBookingValid={isBookingValid}
          onConfirmAndBook={() => onBookFlight()}
          // TODO: simplify/trim FlightBookMobileButton logic
          showPaymentStep={true}
          onApplyRewards={() => {}}
          paymentCardOpen={openPaymentCard}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (checkoutStep === MobileFlightBookWorkflowStep.RewardsAndPayment) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={renderPriceBreakdowndropdown()}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={isWaitingPriceQuote || seatMapLoading}
        message={
          isWaitingValidation &&
          checkoutStep ===
            MobileFlightBookWorkflowStep.PricingAndPassengerValidation
            ? PRICE_QUOTE_PASSENGER_VALIDATION_MESSAGE
            : PRICE_QUOTE_MESSAGE
        }
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen={true}
      />

      {openVIVariantModal && openVIVariantModal !== "reminder" && (
        <VirtualIinterliningModal
          isMobile={true}
          variant={openVIVariantModal}
          fareDetails={tripDetails.fareDetails}
          airports={airports}
          isOpen
          onClose={() => setOpenVIVariantModal(false)}
        />
      )}

      {/*openVIVariantModal === "reminder" && (
        <SelfTransferReminderModal
           isOpen
           fareDetails={tripDetails.fareDetails}
           tripDetails={tripDetails}
           onClickContinue={() => {
             trackEvent({
               eventName: CONFIRMED_VI_BAGGAGE_WARNING,
               properties: {},
             });
             populateFlightBookQueryParams({ history });
           }}
           onClickDifferentFlight={() => {
             trackEvent({
               eventName: DECLINED_VI_BAGGAGE_WARNING,
               properties: {},
             });
             setFlightShopProgress(FlightShopStep.ChooseDeparture);
           }}
           onClose={() => setOpenVIVariantModal(false)}
           slices={tripDetails.slices}
           airports={airports}
        />
      )*/}

      {openMultipleAirlinesFares && (
        <MultipleAirlinesFareModal
          isOpen={openMultipleAirlinesFares}
          tripDetails={tripDetails}
          airports={airports}
          onClose={() => setOpenMultipleAirlinesFares(false)}
        />
      )}
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("flight-payment-card-popup", "b2b")}
      fullScreen={true}
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="flight-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className="flight-book-review-trip-button"
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {REVIEW_MY_TRIP_TEXT}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
