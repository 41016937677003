import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { FlightFreezePriceBreakdownDropdown } from "./component";
import {
  priceFreezeOfferTotalAmountSelector,
  priceFreezeOfferTotalPassengerSelector,
} from "../../../book/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    offerTotal: priceFreezeOfferTotalAmountSelector(state),
    totalPassenger: priceFreezeOfferTotalPassengerSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezePriceBreakdownDropdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezePriceBreakdownDropdown = connector(
  FlightFreezePriceBreakdownDropdown
);
