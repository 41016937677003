import { select, putResolve, take } from "redux-saga/effects";
import { PriceFreezeOfferDataWithRewards } from "redmond";
import dayjs from "dayjs";
import { History } from "history";
import { actions } from "../actions";
import {
  SET_PRICE_FREEZE_OFFER_DATA,
  SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED,
  SET_CUSTOM_PRICE_FREEZE_OFFER,
  SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED,
} from "../actions/constants";
import {
  isPriceFreezeDurationEnabledSelector,
  selectedAndValidPriceFreezeOfferDataSelector,
  priceFreezeDefaultDurationsKnockoutSelector,
  isPriceFreezeShowDurationsVariant1Selector,
} from "../reducer/selectors";
import Logger from "../../../helpers/Logger";

export function* initializeOfferDataAndCustomOfferSaga(
  action: actions.IInitializeOfferDataAndCustomOffer
) {
  try {
    yield initializeOfferDataAndCustomOffer({
      departureDate: action.departureDate,
      tripId: action.tripId,
      fareId: action.fareId,
      history: action.history,
      isFromFetchTripSummariesV3: false,
    });
  } catch (e) {
    Logger.debug(e);
  }
}

export function* initializeOfferDataAndCustomOffer({
  departureDate,
  tripId,
  fareId,
  history,
  isFromFetchTripSummariesV3,
}: {
  departureDate: string;
  tripId: string;
  fareId: string;
  history: History;
  isFromFetchTripSummariesV3: boolean;
}) {
  const isPriceFreezeDurationEnabled: boolean = yield select(
    isPriceFreezeDurationEnabledSelector
  );

  const knockOutDurations: boolean = yield select (
    priceFreezeDefaultDurationsKnockoutSelector
  );

  const showPriceFreezeEntryPointDurations: boolean = yield select (
    isPriceFreezeShowDurationsVariant1Selector    
  );

  if (isPriceFreezeDurationEnabled) {
    yield putResolve(
      actions.getPriceFreezeOfferData(
        dayjs(departureDate).format("YYYY-MM-DD"),
        tripId,
        fareId,
        knockOutDurations,
        showPriceFreezeEntryPointDurations,
        history,
      )
    );
    // note: wait for getPriceFreezeOfferData to finish
    yield take([
      SET_PRICE_FREEZE_OFFER_DATA,
      SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED,
    ]);
    const priceFreezeOfferData: PriceFreezeOfferDataWithRewards | null =
      yield select(selectedAndValidPriceFreezeOfferDataSelector);
    if (priceFreezeOfferData) {
      yield putResolve(
        actions.generateCustomPriceFreezeOffer(priceFreezeOfferData, history, isFromFetchTripSummariesV3)
      );
      // note: wait for generateCustomPriceFreezeOffer to finish
      yield take([
        SET_CUSTOM_PRICE_FREEZE_OFFER,
        SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED,
      ]);
    }
  }
}
