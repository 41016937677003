import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../reducers/types";
import { tripCategoryFromSelectedTripDetailsSelector } from "../../../../shop/reducer";
import { generateCustomPriceFreezeOffer } from "../../../actions/actions";
import {
  selectedPriceFreezeOfferCapSelector,
  selectedAndValidPriceFreezeOfferDataSelector,
  priceFreezeMaxDurationSelector,
  priceFreezeMinOfferSelector,
  priceFreezeUserSelectedDurationSelectionMethodSelector,
  selectedAndValidPriceFreezeOfferDataIndexSelector,
  priceFreezeVoidWindowSelector,
  priceFreezeVoidWindowEndSelector,
} from "../../../reducer";
import { MobilePriceFreezeDurationModal } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    tripCategory: tripCategoryFromSelectedTripDetailsSelector(state),
    selectedPriceFreezeOfferCap: selectedPriceFreezeOfferCapSelector(state),
    selectedPriceFreezeOfferData:
      selectedAndValidPriceFreezeOfferDataSelector(state),
    priceFreezeMaxDuration: priceFreezeMaxDurationSelector(state),
    priceFreezeUserSelectedDurationSelectionMethod:
      priceFreezeUserSelectedDurationSelectionMethodSelector(state),
    selectedPriceFreezeOfferDataIndex:
      selectedAndValidPriceFreezeOfferDataIndexSelector(state),
    priceFreezeVoidWindow: priceFreezeVoidWindowSelector(state),
    priceFreezeVoidWindowEnd: priceFreezeVoidWindowEndSelector(state),
    priceFreezeMinOffer: priceFreezeMinOfferSelector(state),
  };
};

const mapDispatchToProps = {
  generateCustomPriceFreezeOffer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePriceFreezeDurationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePriceFreezeDurationModal = withRouter(
  connector(MobilePriceFreezeDurationModal)
);
