import { FiatPrice, RewardsPrice } from "redmond";
import { getTotalPriceText, getRewardText, twoDecimalFormatter } from "halifax";

export const LOCATION_TEXT = "Tasmania, Australia";
export const CFAR_DETAILS_SUBTITLE =
  "Add the flexibilty to cancel your flight for any reason.";
export const REFUNDABLE_FARES_TITLE = `Make your flight <strong>refundable</strong>.`;
export const REFUNDABLE_FARES_TITLE_SEPARATE_LINES = `Make your flight<br><strong>refundable</strong>.`;
export const REFUNDABLE_FARES_SUBTITLE = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined
) =>
  `Plan your trip worry-free. Add the flexibility to cancel your flight for any reason for a fee of <strong>${getTotalPriceText(
    {
      price: fiat,
      priceFormatter: twoDecimalFormatter,
    }
  )}</strong>${
    reward
      ? ` / ${getRewardText({
          reward,
        })}`
      : ""
  } per traveler.`;

export const YES_OPTION_COPY = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined
) =>
  `Yes, add this option for <strong>${getTotalPriceText({
    price: fiat,
    priceFormatter: twoDecimalFormatter,
  })}</strong>${
    reward
      ? ` / ${getRewardText({
          reward,
        })}`
      : ""
  } per traveler.`;
export const NO_OPTION_COPY = `No, thanks. I don’t want to add this option.`;
export const NO_OPTION_COPY_FOR_REFUND_AMOUNT_COPY_VARIANT = `No, continue with my nonrefundable flight.`;

export const YES_OPTION_COPY_DISCOUNT = (
  origFiat: FiatPrice,
  discountFiat: FiatPrice,
  reward: RewardsPrice | undefined
) =>
  `Yes, add this option for <span class="cross-out">${getTotalPriceText({
    price: origFiat,
    priceFormatter: twoDecimalFormatter,
  })}</span> <span class="green-text">${getTotalPriceText({
    price: discountFiat,
    priceFormatter: twoDecimalFormatter,
  })}${reward ? ` / ${getRewardText({ reward })}` : ""}</span>`;

export const ADD_TO_MY_TRIP = "Add to my trip";
export const PER_TRAVELER = "per traveler";

export const CONTINUE_BUTTON_COPY = "Continue";
export const UPGRADE_AND_CONTINUE_BUTTON_COPY = "Upgrade and continue";
export const TERMS_AND_CONDITIONS_COPY = "Terms and conditions";
export const VIEW_TERMS_AND_CONDITIONS_COPY = "View Terms and Conditions";

export const CFAR_RADIO_BUTTONS_GROUP =
  "Cancel-for-any-reason attachment radio buttons group";

export const CFAR_NO_OPTION_SELECTED_COPY =
  "You must add or decline this option to continue.";

export const HOW_IT_WORKS_COPY = "<strong>How it works:</strong>";

// note: confirmed with Akhil that this constant will technically never be used (because policyData should technically always be returned).
export const DEFAULT_CASH_COVERAGE_PERCENTAGE = 80;

/*
  note: ESTIMATED_CFAR_CONTENT_HEIGHT is actually an upper bound to the height of CFAR content (with image-background header);
  in the future, it can be removed if we were to find a better solution. DIALOG_MARGIN is the same as the margin in MuiDialog-paperScrollPaper,
  which comes from material-ui.Dialog; MuiDialog-paperScrollPaper has max-height set to calc(100% - 2 * 32px)
*/
export const ESTIMATED_CFAR_CONTENT_HEIGHT = 750;
export const DIALOG_MARGIN = 32;
export const PARTIAL_SCROLL_THRESHOLD = `(max-height: ${
  ESTIMATED_CFAR_CONTENT_HEIGHT + 2 * DIALOG_MARGIN
}px)`;

export const THE_AIRLINE = "the airline";
export const AIR_OFFER_REDESIGN_CLASSNAME = "air-offer-redesign";
export const AIR_OFFER_REDESIGN_SUBTITLE =
  "Add the flexibility to cancel your flight for any reason. How it works:";

export const NO_THANKS = "No, thanks";
export const CHOOSE_BETWEEN = "Choose between:";
