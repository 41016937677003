import { createSelector } from "reselect";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import {
  HotelEntryProperties,
  ITrackingProperties,
  Lodging,
  StatementCreditDetail,
  LodgingCollectionEnum,
  ViewedHotelListProperties,
} from "redmond";
import { orderByPriceLowToHigh } from "./processSort";
import {
  getAgentEmail,
  getIsFirstLaunch,
  getRewardsAccounts,
  getSelectedAccount,
  getSelectedAccountIndex,
} from "../../../rewards/reducer";
import {
  getCreditBreakdown,
  getTravelWalletCredit,
} from "../../../travel-wallet/reducer";

export const getHotelAvailabilityTrackingProperties = (state: IStoreState) =>
  state.flightToHotelCrossSell.availabilityResponse?.trackingPropertiesV2;

export const getCrossSellLatency = (state: IStoreState) =>
  state.flightToHotelCrossSell.latency;

export const getHotelAvailabilityLodgings = (state: IStoreState) =>
  state.flightToHotelCrossSell.availabilityResponse?.lodgings;

export const getHotelAvailabilityNextPageToken = (state: IStoreState) =>
  state.flightToHotelCrossSell.availabilityResponse?.nextPageToken;

export const getHotelBestOfferOverall = (state: IStoreState) =>
  state.flightToHotelCrossSell.availabilityResponse?.bestOverallOffer;

export const getHotelAvailabilityCallState = (state: IStoreState) =>
  state.flightToHotelCrossSell.hotelAvailabilityCallState;

export const getHotelAvailabilitySearchLocationResult = (state: IStoreState) =>
  state.flightToHotelCrossSell.searchLocationResult;

export const getHotelAvailabilityFromDate = (state: IStoreState) =>
  state.flightToHotelCrossSell.searchFromDate;

export const getHotelAvailabilityUntilDate = (state: IStoreState) =>
  state.flightToHotelCrossSell.searchUntilDate;

export const getSearchedNightCount = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  (fromDate, untilDate) => {
    if (!fromDate || !untilDate) {
      return null;
    }

    return dayjs(untilDate).diff(fromDate, "days");
  }
);

export const getHotelAvailabilityAdultsCount = (state: IStoreState) =>
  state.flightToHotelCrossSell.searchAdultsCount;

export const getHotelAvailabilityChildren = (state: IStoreState) =>
  state.flightToHotelCrossSell.searchChildrenCount;

export const getSelectedLodgingIndex = (state: IStoreState) =>
  state.flightToHotelCrossSell.selectedLodgingIndex;

export const getHotelShopParams = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getSelectedAccountIndex,
  (fromDate, untilDate, adultsCount, children, selectedAccountIndex) => ({
    fromDate,
    untilDate,
    adultsCount,
    children,
    selectedAccountIndex,
  })
);

export const getAvailableCrossSellHotels = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings) => lodgings?.filter((lodging) => !!lodging.available)
);

export const getSortedLodgingsByPricing = createSelector(
  getAvailableCrossSellHotels,
  (lodgings): Lodging[] => {
    if (lodgings) {
      const { availableLodgings, unavailableLodgings } = lodgings.reduce(
        (prev, curr) => {
          const available =
            typeof curr.available === "undefined" ? true : curr.available;

          if (available) prev.availableLodgings.push(curr);
          else prev.unavailableLodgings.push(curr);

          return prev;
        },
        {
          availableLodgings: [] as Lodging[],
          unavailableLodgings: [] as Lodging[],
        }
      );
      const sortedAvailableHotels = orderByPriceLowToHigh(availableLodgings);
      return sortedAvailableHotels.concat(unavailableLodgings);
    }
    return [];
  }
);

export const getAirportMap = (state: IStoreState) =>
  state.flightToHotelCrossSell.airports;

export const getAirlinesMap = (state: IStoreState) =>
  state.flightToHotelCrossSell.airlines;

export const getHotelEntryProperties = createSelector(
  getAgentEmail,
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityFromDate,
  getIsFirstLaunch,
  getRewardsAccounts,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getTravelWalletCredit,
  getCreditBreakdown,
  getAvailableCrossSellHotels,
  (
    agentEmail,
    location,
    fromDate,
    isFirstLaunch,
    rewardsAccounts,
    numAdults,
    children,
    credit,
    creditBreakdown,
    lodgings
  ): ITrackingProperties<HotelEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        market: location?.label || "",
        country: location?.subLabel || "",
        number_of_properties: lodgings?.length ?? 0,
        number_of_guests: numAdults + children.length,
        hotel_advance: fromDate
          ? `${dayjs(fromDate).diff(dayjs(), "day")} days`
          : "",
        rooms_searched: 1,
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        ...location?.trackingPropertiesV2?.properties,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getUnavailableLodgingCount = createSelector(
  getAvailableCrossSellHotels,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : !lodging.available
    ).length || 0
);

export const getAvailableLodgingCount = createSelector(
  getAvailableCrossSellHotels,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : lodging.available
    ).length || 0
);

export const getLuxuryCollectionLodgingCount = createSelector(
  getAvailableCrossSellHotels,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Premier
    ).length
);

export const getLifestyleCollectionLodgingCount = createSelector(
  getAvailableCrossSellHotels,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
    ).length
);

export const getViewedHotelListProperties = createSelector(
  getHotelEntryProperties,
  getSelectedAccount,
  getHotelAvailabilityTrackingProperties,
  getUnavailableLodgingCount,
  getAvailableLodgingCount,
  getHotelBestOfferOverall,
  getLuxuryCollectionLodgingCount,
  getLifestyleCollectionLodgingCount,
  (
    properties,
    account,
    trackingProperties,
    unavailableLodgingCount,
    availableLodgingCount,
    bestOverallOffer,
    luxuryLodgingCount,
    lifestyleLodgingCount
  ): ITrackingProperties<ViewedHotelListProperties> => {
    return {
      properties: {
        ...properties.properties,
        account_type_selected: account?.productDisplayName || "",
        unavailable_hotels: unavailableLodgingCount,
        available_hotels: availableLodgingCount,
        ...bestOverallOffer?.trackingPropertiesV2?.properties,
        has_offer: !!bestOverallOffer,
        account_use_type: account?.accountUseType,
        customer_account_role: account?.customerAccountRole,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        pc_shown: luxuryLodgingCount || 0,
        lc_shown: lifestyleLodgingCount || 0,

        ...trackingProperties?.properties,
        rooms_searched: 1,
      },
      encryptedProperties: [
        bestOverallOffer?.trackingPropertiesV2?.encryptedProperties ?? "",
        trackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getPotentialCrossSellOffers = (state: IStoreState) =>
  state.flightToHotelCrossSell.potentialXSellOffers;
