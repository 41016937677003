import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  airportsSelector,
  isOutgoingMultiTicketSelector,
  isReturnMultiTicketSelector,
  selectedTripSelector,
  tripDetailsSelector,
  flightShopMulticityProgressSelector,
} from "../../reducer/selectors";
import { FlightShopSummaryPanel } from "./component";
import { populateFlightShopQueryParams } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { ISelectedMulticityTrip, ISelectedTrip } from "../../reducer";
import { getTripCategory } from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const selectedOWRTrip = selectedTrip as ISelectedTrip;
  const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
  const tripId = selectedTrip.tripId || "";
  const airports = airportsSelector(state, tripId);
  const tripDetails = tripDetailsSelector(state, tripId);
  const fareDetails = tripDetails
    ? tripDetails.fareDetails.find(
        (f) =>
          f.id ===
          (selectedOWRTrip.returnFareId ||
            selectedOWRTrip.outgoingFareId ||
            selectedMcTrip.departureFareId)
      )
    : undefined;

  return {
    multicityProgress: flightShopMulticityProgressSelector(state),
    tripCategory: getTripCategory(state),
    tripDetails: tripDetailsSelector(state, tripId),
    fareDetails: fareDetails,
    airports,
    isOutgoingMultiTicket: isOutgoingMultiTicketSelector(state),
    isReturnMultiTicket: isReturnMultiTicketSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightShopQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopSummaryPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopSummaryPanel = connector(
  withRouter(FlightShopSummaryPanel)
);
