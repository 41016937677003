import { takeLeading, takeLatest } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { setUpFlightFreezeParamsSaga } from "./setUpFlightFreezeParamsSaga";
import { getPriceFreezeOfferSaga } from "./getPriceFreezeOfferSaga";
import { getPriceFreezeOfferDataSaga } from "./getPriceFreezeOfferDataSaga";
import { generateCustomPriceFreezeOfferSaga } from "./generateCustomPriceFreezeOfferSaga";
import { initializeOfferDataAndCustomOfferSaga } from "./initializeOfferDataAndCustomOfferSaga";
import { fetchPriceFreezeSaga } from "./fetchPriceFreezeSaga";
import { refundPriceFreezeSaga } from "./refundPriceFreezeSaga";
import { fetchPriceFreezeFareQuoteSaga } from "./fetchPriceFreezeFareQuoteSaga";
import { fetchApplicablePriceFreezeTravelWalletItemsSaga } from "./fetchApplicablePriceFreezeTravelWalletItems";

export function* watchSetUpFlightFreezeParams() {
  yield takeLeading(
    actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS,
    setUpFlightFreezeParamsSaga
  );
}

export function* watchGetPriceFreezeOffer() {
  yield takeLatest(actionTypes.GET_PRICE_FREEZE_OFFER, getPriceFreezeOfferSaga);
}

export function* watchGetPriceFreezeOfferData() {
  yield takeLatest(
    actionTypes.GET_PRICE_FREEZE_OFFER_DATA,
    getPriceFreezeOfferDataSaga
  );
}

export function* watchGenerateCustomPriceFreezeOffer() {
  yield takeLatest(
    actionTypes.GENERATE_CUSTOM_PRICE_FREEZE_OFFER,
    generateCustomPriceFreezeOfferSaga
  );
}

export function* watchInitializeOfferDataAndCustomOffer() {
  yield takeLatest(
    actionTypes.INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER,
    initializeOfferDataAndCustomOfferSaga
  );
}

export function* watchFetchPriceFreeze() {
  yield takeLatest(actionTypes.FETCH_PRICE_FREEZE, fetchPriceFreezeSaga);
}

export function* watchRefundPriceFreeze() {
  yield takeLatest(actionTypes.REFUND_PRICE_FREEZE, refundPriceFreezeSaga);
}

export function* watchFetchPriceFreezeFareQuote() {
  yield takeLatest(
    actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE,
    fetchPriceFreezeFareQuoteSaga
  );
}

export function* watchFetchTravelWalletCreditHistorySaga() {
  yield takeLeading(
    actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
    fetchApplicablePriceFreezeTravelWalletItemsSaga
  );
}
