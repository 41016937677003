import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AddOnPricingBreakdown } from "./component";
import {
  predictionSelector,
  selectedFareDetailsSelector,
  selectedCfarOfferPricesSelector,
  selectedDisruptionProtectionOfferPricesSelector,
  customizeCheckoutBreakdownTotalPricesSelector,
  selectedDiscountedCfarOfferPricesSelector,
  selectedCfarOfferCoverageSelector,
} from "../../../reducer";
import {
  priceFreezeChargeAmountPricesSelector,
  getTripCategoryFromPriceFreeze,
  priceFreezeCustomizeCheckoutBreakdownTotalPricesSelector,
  currentPriceFreezePriceDropProtectionSelector,
} from "../../../../freeze/reducer";
import { getTripCategory } from "../../../../search/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../rewards/reducer";
import { IStoreState } from "../../../../../reducers/types";
import {
  isRapidRebookRenameEnabledSelector,
  isAirOfferRedesignEnabledSelector,
  isCfarCoMerchEnabledSelector,
} from "../../../../ancillary/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedFare: selectedFareDetailsSelector(state),
    priceFreezeChargeAmount: priceFreezeChargeAmountPricesSelector(state),
    totalPrices: customizeCheckoutBreakdownTotalPricesSelector(state),
    priceFreezeTotalPrices:
      priceFreezeCustomizeCheckoutBreakdownTotalPricesSelector(state),
    tripCategory: getTripCategory(state),
    tripCategoryFromPriceFreeze: getTripCategoryFromPriceFreeze(state),
    prediction: predictionSelector(state),
    priceDropProtectionFromPriceFreeze:
      currentPriceFreezePriceDropProtectionSelector(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    cfarOfferPrices: selectedCfarOfferPricesSelector(state),
    cfarOfferCoverage: selectedCfarOfferCoverageSelector(state),
    discountedCfarOffer: selectedDiscountedCfarOfferPricesSelector(state),
    disruptionProtectionOfferPrices:
      selectedDisruptionProtectionOfferPricesSelector(state),
    isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    isCfarCoMerchEnabled: isCfarCoMerchEnabledSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AddOnPricingBreakdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAddOnPricingBreakdown = withRouter(
  connector(AddOnPricingBreakdown)
);
