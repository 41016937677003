import React, { useContext, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useMediaQuery } from "@material-ui/core";
import {
  GenericDetailsCard,
  GenericDetailsCardComponent,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardTopSectionComponent,
  ErrorOutsideComponentType,
  CfarImageDesktop,
  CfarImageMobile,
  IconName,
  useDeviceTypes,
  GenericDetailsCardTopSectionComponentEnum,
} from "halifax";
import clsx from "clsx";

import { ChfarOffer, FLIGHT_CFAR_TERMS_BODY_OLD } from "redmond";

import { DO_NOT_APPLY_OPTION_KEY } from "../../../../reducer";

import { ClientContext } from "../../../../../../App";
import * as constants from "./constants";
import { ChfarDetailsConnectorProps } from "./container";
import { PATH_CHFAR } from "../../../../../../utils/urlPaths";

enum ChfarOption {
  No,
  Yes,
}

interface IChfarDetailsProps
  extends ChfarDetailsConnectorProps,
    RouteComponentProps {
  contentOnly?: boolean;
  cardContentProps?: {
    disablePartialScroll?: boolean;
    readTermsAndConditionsLinkPosition?: "left" | "center" | "right";
    onSelectValueCallback?: () => void;
    onConfirmSelectedValueCallback?: () => void;
    error?: ErrorOutsideComponentType;
    hideTopContent?: boolean;
    hideContinueCta?: boolean;
  };
  disabled?: boolean;
  selectedChfarOffer?: ChfarOffer;
  // Note: Overwrite the default continue action
  onContinue?: () => void;
  onNoCfarOffer?: () => void;
}

export enum ErrorFromInsideComponentType {
  NoOptionSelected,
}

export const ChfarDetails = (props: IChfarDetailsProps) => {
  const {
    contentOnly,
    cardContentProps,
    selectedChfarId,
    setSelectedChfarId,
    selectedChfarOffer,
    chfarOffer,
    selectedAccountReferenceId,
    history,
    onContinue = () => {
      populateFlightBookQueryParams({ history });
      handleChfarAddOnChoiceEvent();
    },
    populateFlightBookQueryParams,
  } = props;

  const {
    disablePartialScroll,
    onSelectValueCallback,
    onConfirmSelectedValueCallback,
    error,
    readTermsAndConditionsLinkPosition,
    hideTopContent,
    hideContinueCta,
  } = cardContentProps || {};

  const { isAgentPortal } = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();

  // todo add chfar events tracking. https://hopper-jira.atlassian.net/browse/FLEX-2968
  const handleChfarAddOnChoiceEvent = () => {};

  const usePartialScroll =
    !disablePartialScroll &&
    useMediaQuery(constants.PARTIAL_SCROLL_THRESHOLD, { noSsr: true });
  const scrollOption = usePartialScroll ? "partial-scroll" : "default-scroll";

  const [errorFromInsideComponent, setErrorFromInsideComponent] =
    useState<ErrorFromInsideComponentType | null>(null);

  let selectedValue: ChfarOption | undefined;

  if (isAgentPortal) {
    selectedValue = ChfarOption.No;
  } else if (selectedChfarId !== null) {
    if (selectedChfarId.policyId !== DO_NOT_APPLY_OPTION_KEY) {
      selectedValue = ChfarOption.Yes;
    } else {
      selectedValue = ChfarOption.No;
    }
  }

  const isUnselected = selectedValue === undefined;

  const currentOffer = useMemo(
    () => selectedChfarOffer ?? (chfarOffer && chfarOffer[0]),
    [selectedChfarOffer, chfarOffer]
  );

  const handleViewTerms = () => {
    window.open(PATH_CHFAR, "_blank")?.focus();
  };

  const topContent = !hideTopContent
    ? ({
        image: matchesMobile ? CfarImageMobile : CfarImageDesktop,
        location: constants.LOCATION_TEXT,
        header: constants.TOP_CONTENT_HEADER_TEST,
        component:
          GenericDetailsCardTopSectionComponentEnum.HeaderWithImageBackground,
      } as GenericDetailsCardTopSectionComponent)
    : undefined;

  const MainContent = useMemo((): GenericDetailsCardComponent[] => {
    if (!currentOffer) {
      return [];
    }

    const hasError =
      error === ErrorOutsideComponentType.NoOptionSelected ||
      errorFromInsideComponent ===
        ErrorFromInsideComponentType.NoOptionSelected;

    const mainContent: GenericDetailsCardComponent[] = [];

    mainContent.push({
      className: clsx("chfar-details-check-mark-icon", {
        fade: false,
      }),
      type: "secondary",
      copy: constants.CHANGE_DESCRIPTION_COPY,
      icon: IconName.PencilEdit,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });
    mainContent.push({
      className: clsx("chfar-details-check-mark-icon", {
        fade: false,
      }),
      type: "secondary",
      copy: constants.WE_COVER_COPY,
      icon: IconName.DoubleCheck,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });
    mainContent.push({
      className: clsx("chfar-details-check-mark-icon", {
        fade: false,
      }),
      type: "secondary",
      copy: constants.CHANGE_NO_CONTACT_COPY,
      icon: IconName.SimpleLaptopBlue,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });
    mainContent.push({
      className: clsx("chfar-details-last-sentence", {
        fade: false,
      }),
      type: "secondary",
      copy: constants.CHANGE_WITHIN_ONE_YEAR_COPY,
      component: GenericDetailsCardComponentEnum.GenericCopy,
      newSection: true,
    });

    if (hasError) {
      mainContent.push({
        className: "chfar-details-error-message",
        type: "error",
        copy: constants.CHFAR_NO_OPTION_SELECTED_COPY,
        component: GenericDetailsCardComponentEnum.GenericCopy,
        newSection: true,
      });
    }

    mainContent.push({
      radioButtons: [
        {
          value: ChfarOption.Yes,
          copy: constants.YES_OPTION_COPY(
            currentOffer.premiumPerPax.fiat,
            selectedAccountReferenceId
              ? currentOffer.premiumPerPax.rewards[selectedAccountReferenceId]
              : undefined
          ),
          disabled: isAgentPortal,
        },
        {
          value: ChfarOption.No,
          copy: constants.NO_OPTION_COPY,
        },
      ],
      selectedValue,
      setSelectedValue: (value: number) => {
        switch (value) {
          case ChfarOption.Yes:
            !isAgentPortal && setSelectedChfarId(currentOffer.id);
            break;
          case ChfarOption.No:
            setSelectedChfarId({
              policyId: DO_NOT_APPLY_OPTION_KEY,
              productId: DO_NOT_APPLY_OPTION_KEY,
            });
            break;
          default:
            break;
        }

        setErrorFromInsideComponent(null);

        if (onSelectValueCallback) {
          onSelectValueCallback();
        }
      },
      onConfirmSelectedValue: () => {
        if (onConfirmSelectedValueCallback) {
          onConfirmSelectedValueCallback();
        }
      },
      component: GenericDetailsCardComponentEnum.BoxedRadioGroup,
      hasError,
    });

    const continueButton = ((): GenericDetailsCardComponent => {
      const buttonMessage = "Continue";

      return {
        message: buttonMessage,
        onClick: onContinue,
        onClickWhenDisabled: () => {
          if (!selectedValue) {
            setErrorFromInsideComponent(
              ErrorFromInsideComponentType.NoOptionSelected
            );
          }
        },
        ariaLabel: buttonMessage,
        disabled: isUnselected,
        floating: false,
        hidden: matchesMobile && isUnselected,
        fill: "blue",
        component: GenericDetailsCardComponentEnum.GenericCta,
      };
    })();

    if (!hideContinueCta && (!usePartialScroll || matchesMobile)) {
      mainContent.push(continueButton);
    }

    // todo: change this to CHFAR versions. will be done in https://hopper-jira.atlassian.net/browse/FLEX-2965
    if (matchesMobile) {
      mainContent.push({
        content: [
          {
            title: constants.READ_TERMS_AND_CONDITIONS_COPY,
            body: FLIGHT_CFAR_TERMS_BODY_OLD,
          },
        ],
        component: GenericDetailsCardComponentEnum.AccordionGroup,
      });
    } else if (!usePartialScroll) {
      mainContent.push({
        message: constants.READ_TERMS_AND_CONDITIONS_COPY,
        onClick: handleViewTerms,
        position: readTermsAndConditionsLinkPosition,
        component: GenericDetailsCardComponentEnum.ClickableLink,
      });
    }

    return mainContent;
  }, [
    currentOffer,
    selectedValue,
    isAgentPortal,
    onSelectValueCallback,
    onConfirmSelectedValueCallback,
    selectedAccountReferenceId,
    isUnselected,
    error,
    isAgentPortal,
  ]);

  return (
    <GenericDetailsCard
      topContent={topContent}
      mainContent={MainContent}
      openModal={matchesMobile}
      contentOnly={contentOnly ?? matchesMobile}
      isMobile={matchesMobile}
      scrollOption={scrollOption}
    />
  );
};
