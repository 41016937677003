import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { LoadingIndicator, B2BSpinner, PricingBreakdown } from "halifax";
import { CallState } from "redmond";
import { PriceBreakdownConnectorProps } from "./container";
import clsx from "clsx";
import "./styles.scss";
import { CFAR } from "../../reducer/selectors/textConstants";
export interface IPriceBreakdownProps
  extends PriceBreakdownConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  className?: string;
}

export const PriceBreakdown = ({
  tripPricingCallState,
  currency,
  lineItems,
  summaryLineItems,
  pricingParams,
  fetchTripPricing,
  className,
  isRapidRebookRenameEnabled,
  isCfarCoMerchEnabled,
  cfarOfferCoverage,
  isVITripSelected,
}: IPriceBreakdownProps) => {
  useEffect(() => {
    fetchTripPricing();
  }, [pricingParams, pricingParams.ancillaryIds, fetchTripPricing]);

  const cfarCoMerchTitle =
    isCfarCoMerchEnabled && cfarOfferCoverage?.cashCoveragePercentage
      ? {
          cfarText: `${CFAR}, ${cfarOfferCoverage?.cashCoveragePercentage}% coverage`,
        }
      : { cfarText: CFAR };
  return (
    <>
      {tripPricingCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={"Fetching latest price"}
        ></LoadingIndicator>
      ) : (
        <PricingBreakdown
          className={clsx("price-breakdown", className)}
          pricingItems={lineItems}
          summaryItems={summaryLineItems}
          currencySymbol={currency.currencySymbol}
          useFDACopy={isRapidRebookRenameEnabled}
          isVITripSelected={isVITripSelected}
          titles={cfarCoMerchTitle}
        />
      )}
    </>
  );
};
