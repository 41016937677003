import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useMediaQuery, Box } from "@material-ui/core";
import {
  GenericDetailsCard,
  GenericDetailsCardComponent,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardTopSectionComponent,
  GenericDetailsCardTopSectionComponentEnum,
  ErrorOutsideComponentType,
  CfarImageDesktop,
  CfarImageMobile,
  IconName,
  useDeviceTypes,
  B2BSpinner,
  LoadingPopup,
  getTotalPriceText,
  twoDecimalFormatter,
  roundToTwoDecimals,
  getRewardText,
  IBoxedRadio,
} from "halifax";
import {
  CallState,
  FLIGHT_CFAR_TERMS_BODY_NEW,
  FLIGHT_CFAR_TERMS_BODY_OLD,
  CFAR_ADD_ON_CHOICE,
  CfarOffer,
  UtasPolicy,
  CFAR_POINT_ONE,
  REFUNDABLE_FARES_POINT_ONE,
  CFAR_POINT_THREE,
  REFUNDABLE_FARES_POINT_THREE,
  CFAR_HEADER,
  FtcType,
  CFAR_SOCIAL_PROOFING_COPY,
  DISCOUNT_PROMO_TERMS_CONDITIONS,
  FiatPrice,
} from "redmond";
import clsx from "clsx";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import { ClientContext } from "../../../../../../App";
import { CFAR_SOCIAL_SOCIAL_PROOF } from "../../../../../../context/experiments";
import { PATH_CFAR } from "../../../../../../utils/urlPaths";
import {
  getFtcTypeArr,
  lastSentenceText,
  secondBulletText,
} from "../../../../utils/helpers";
import { DO_NOT_APPLY_OPTION_KEY } from "../../../../reducer";
import { CfarDetailsConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { getCoMerchPriceWithRewardsUILabel } from "../../common";

enum CfarOption {
  No,
  Yes,
  // for comerch where there are multiple yes options
  Yes_option_1,
  Yes_option_2,
}

export enum ErrorFromInsideComponentType {
  NoOptionSelected,
}

interface IFtcVariantCopies {
  secondBullet: string;
  lastSentence: string;
}

interface ICfarDetailsProps
  extends CfarDetailsConnectorProps,
    RouteComponentProps {
  openCfarDetails?: boolean;
  onClose?: () => void;
  // Note: Action to call when the call to fetch CFAR offer is finished and there is no CFAR offer returned.
  // This will overwrite the default action taken.
  onNoCfarOffer?: () => void;
  // Note: Overwrite the default continue action
  onContinue?: () => void;
  contentOnly?: boolean;
  cardContentProps?: {
    hideTopContent?: boolean;
    hideSubtitle?: boolean;
    hideContinueCta?: boolean;
    hideRadioButtons?: boolean;
    disablePartialScroll?: boolean;
    readTermsAndConditionsLinkPosition?: "left" | "center" | "right";
    onSelectValueCallback?: () => void;
    onConfirmSelectedValueCallback?: () => void;
    error?: ErrorOutsideComponentType;
  };
  disabled?: boolean;
  selectedCfarOffer?: CfarOffer;
  selectedChangePolicy?: UtasPolicy[];
  selectedRefundAmount?: number;
  airlineFTCAmount?: number;
  modalType?: "refundable-fare" | "cfar";
  contentClassName?: string;
  topBannerEnabled?: boolean;
}

export const getRefundAmount = ({
  price,
  tax,
  coverage,
}: {
  price: FiatPrice;
  tax: FiatPrice | undefined;
  coverage: number;
}) => {
  const totalAmount = price && tax ? price.value + tax.value : price.value;
  return Math.round(totalAmount * coverage * 0.01)

};

export const getRefundString = ({
  price,
  tax,
  coverage,
}: {
  price: FiatPrice;
  tax: FiatPrice | undefined;
  coverage: number;
}) => {
  return `${price.currencySymbol}${roundToTwoDecimals(
    getRefundAmount({ price, tax, coverage }))}`;
};
export const CfarDetails = (props: ICfarDetailsProps) => {
  const {
    openCfarDetails,
    onClose,
    onContinue = () => {
      populateFlightBookQueryParams({ history });
      handleCfarAddOnChoiceEvent();
    },
    onNoCfarOffer = () => {
      populateFlightBookQueryParams({ history });
    },
    selectedCfarOffer,
    cfarOffer,
    fetchAncillaryOfferCallState,
    tripDetails,
    selectedCfarId,
    setSelectedCfarId,
    selectedAccountReferenceId,
    populateFlightBookQueryParams,
    cfarAddOnChoiceProperties,
    selectedChangePolicy,
    changePolicy,
    history,
    contentOnly,
    cardContentProps,
    hasCfarOffer,
    isRapidRebookRenameEnabled,
    disabled,
    modalType,
    contentClassName,
    cfarSocialVariant,
    showNoFtcOnlyInRefundableFares,
    cfarDiscountPromoCopy,
    topBannerEnabled,
    cfarDiscountProperties,
    isAirOfferRedesignEnabled,
    updateRefundableFaresProperties,
    isCfarCoMerchEnabled,
    selectedFare,
    isAirRefundableCopyEnabled,
    selectedRefundAmount,
    airlineFTCAmount,
    isSpiritOrFrontierAirlinesSelected,
    isCfarRefundCopyChallenger1Variant,
    isCfarRefundCopyChallenger2Variant,
    isCfarRefundCopyExperimentEnabled,
  } = props;
  const {
    hideTopContent,
    hideSubtitle,
    hideContinueCta,
    hideRadioButtons,
    disablePartialScroll,
    readTermsAndConditionsLinkPosition,
    onSelectValueCallback,
    onConfirmSelectedValueCallback,
    error,
  } = cardContentProps ?? {};
  const { isAgentPortal } = useContext(ClientContext);
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const usePartialScroll =
    !disablePartialScroll &&
    useMediaQuery(constants.PARTIAL_SCROLL_THRESHOLD, {
      noSsr: true,
    });

  const DEFAULT_COVERAGE = 80;
  const basefare = selectedFare?.paxPricings?.[0].pricing.baseAmount.fiat;
  const taxFare = selectedFare?.paxPricings?.[0].pricing.taxAmount.fiat;

  const [errorFromInsideComponent, setErrorFromInsideComponent] =
    useState<ErrorFromInsideComponentType | null>(null);

  // note: useMemo is needed because currentOffer and currentChangePolicy are dependencies of another hook
  // note: although multiple cfar offers are returned to the FE, we would simply consume the first one
  // given the design that we currently have.
  const currentOffer = useMemo(
    () =>
      selectedCfarOffer ??
      (cfarOffer && cfarOffer.length > 0 ? cfarOffer[0] : undefined),
    [selectedCfarOffer, cfarOffer]
  );

  // 1st one is going to be the higher coverage
  // 2nd one is going to be the lower coverage
  const cfarCoMerchOffers = useMemo(
    () =>
      cfarOffer && cfarOffer.length == 2
        ? cfarOffer.sort(
            (a, b) =>
              (b.policyData?.cashCoveragePercentage || DEFAULT_COVERAGE) -
              (a.policyData?.cashCoveragePercentage || DEFAULT_COVERAGE)
          )
        : undefined,
    [cfarOffer]
  );

  const cfarDiscountEnabled = useMemo(
    () => !!currentOffer?.discount,
    [currentOffer]
  );
  const showTopBanner =
    cfarDiscountEnabled && topBannerEnabled && !isAirOfferRedesignEnabled;
  const currentChangePolicy = useMemo(
    () => selectedChangePolicy ?? changePolicy,
    [selectedChangePolicy, changePolicy]
  );

  let selectedValue: CfarOption | undefined;

  if (isAgentPortal) {
    selectedValue = CfarOption.No;
  } else if (selectedCfarId !== null) {
    if (selectedCfarId.policyId === DO_NOT_APPLY_OPTION_KEY) {
      selectedValue = CfarOption.No;
    } else {
      if (cfarCoMerchOffers) {
        selectedValue =
          selectedCfarId.policyId === cfarCoMerchOffers[0].id.policyId
            ? CfarOption.Yes_option_1
            : CfarOption.Yes_option_2;
      } else {
        selectedValue = CfarOption.Yes;
      }
    }
  }

  const isUnselected = selectedValue === undefined;

  const handleCfarAddOnChoiceEvent = () => {
    trackEvent({
      eventName: CFAR_ADD_ON_CHOICE,
      properties: {
        ...cfarAddOnChoiceProperties,
        ...{
          cfar_discount_original_premium:
            cfarDiscountProperties.original_premium,
          cfar_discount_percentage: cfarDiscountProperties.discount_percentage,
        },
        // note: since populateFlightBookQueryParams is called to push the user to the checkout page, it's alway true
        success: true,
      },
    });
  };

  const getTopContent = (): GenericDetailsCardTopSectionComponent => {
    const header = (() => {
      switch (modalType) {
        case "refundable-fare":
          return matchesMobile
            ? constants.REFUNDABLE_FARES_TITLE_SEPARATE_LINES
            : constants.REFUNDABLE_FARES_TITLE;
        case "cfar":
        default:
          // note: by default, the CFAR modal has been using the v2-disrupted variant of header copy since the beginning
          return CFAR_HEADER({
            textVersion: "long",
            useStrong: true,
            useBreak: matchesMobile,
            punctuation: matchesDesktop ? "." : undefined,
          });
      }
    })();

    return {
      image: matchesMobile ? CfarImageMobile : CfarImageDesktop,
      location: constants.LOCATION_TEXT,
      header,
      component:
        GenericDetailsCardTopSectionComponentEnum.HeaderWithImageBackground,
    };
  };

  const getTopBanner = (): GenericDetailsCardComponent => {
    return {
      iconName: IconName.CheckShieldBlue,
      bannerText: `${cfarDiscountPromoCopy} limited-time promotion`,
      component: GenericDetailsCardComponentEnum.TopBanner,
    };
  };

  const handleViewTerms = () => {
    window.open(PATH_CFAR, "_blank")?.focus();
  };

  const MainContent = useMemo((): GenericDetailsCardComponent[] => {
    if (!currentOffer) {
      return [];
    }

    const ftcTypes = getFtcTypeArr(currentChangePolicy);
    const mainContent: GenericDetailsCardComponent[] = [];
    const continueCta = ((): GenericDetailsCardComponent => {
      const ctaMessage =
        modalType === "refundable-fare"
          ? constants.UPGRADE_AND_CONTINUE_BUTTON_COPY
          : constants.CONTINUE_BUTTON_COPY;
      const isSelectionEnabled = getIsSelectionEnabled(modalType);

      return {
        message: ctaMessage,
        onClick: onContinue,
        onClickWhenDisabled: () => {
          if (isSelectionEnabled && !selectedValue) {
            setErrorFromInsideComponent(
              ErrorFromInsideComponentType.NoOptionSelected
            );
          }
        },
        ariaLabel: ctaMessage,
        disabled: isSelectionEnabled ? isUnselected : false,
        floating: matchesMobile,
        hidden: isSelectionEnabled ? matchesMobile && isUnselected : false,
        fill: "blue",
        component: GenericDetailsCardComponentEnum.GenericCta,
      };
    })();

    const ftcVariantCopies = ((): IFtcVariantCopies => {
      const outboundAirline =
        tripDetails?.slices[0]?.segmentDetails[0]?.airlineName ??
        constants.THE_AIRLINE;
      const returnAirline =
        tripDetails?.slices[1]?.segmentDetails[0]?.airlineName ??
        constants.THE_AIRLINE;

      const outboundAirlineCode =
        tripDetails?.slices[0]?.segmentDetails[0]?.airlineCode;
      const returnAirlineCode =
        tripDetails?.slices[1]?.segmentDetails[0]?.airlineCode;

      const coverage = currentOffer.policyData?.cashCoveragePercentage

      var refundValForRFModal = modalType === "refundable-fare" && selectedRefundAmount ? selectedRefundAmount : undefined

      var refundValForCFAR = basefare && isCfarRefundCopyExperimentEnabled ? getRefundAmount({
        price: basefare,
        tax: taxFare,
        coverage: coverage
          ? coverage
          : DEFAULT_COVERAGE,
      }) : undefined

      //Show refundValForRFModal if its RF modal otherwise show refundValForCFAR when isCfarRefundCopyExperimentEnabled is ON
      var refundAmountVal = refundValForRFModal && modalType === "refundable-fare" ? refundValForRFModal : refundValForCFAR
      return {
        secondBullet: secondBulletText({
          ftcTypes:
            modalType === "refundable-fare" && showNoFtcOnlyInRefundableFares
              ? ftcTypes.map((_) => FtcType.NoFtc)
              : ftcTypes,
          cashCoveragePercentage: coverage,
          useStrong: true,
          coMerch: isCfarCoMerchEnabled,
          //isAirRefundableCopyEnabled is enabled 100% for all users
          refundAmount: refundAmountVal,
          airlineFTCAmount: airlineFTCAmount,
          punctuation: ".",
          excludeWithFeesCopy: false,
          concise:
            modalType === "refundable-fare"
              ? isAirRefundableCopyEnabled
              : false,
          isSpiritOrFrontierAirlinesSelected,
          outboundAirlineCode,
          returnAirlineCode,
          showRefundAmountInSecondBulletText: isCfarRefundCopyExperimentEnabled && modalType != "refundable-fare", // Show refundcopy text only for CFAR and not for RF modal
        }),
        lastSentence: lastSentenceText(
          ftcTypes,
          outboundAirline,
          returnAirline,
          isAirOfferRedesignEnabled,
          isSpiritOrFrontierAirlinesSelected
        ),
      };
    })();

    // Error can be tracked from:
    // 1) The continue button outside of this modal clicked. This is signaled via the error
    //    passed along in the props
    // 2) The continue button of this modal clicked.
    const hasError =
      error === ErrorOutsideComponentType.NoOptionSelected ||
      errorFromInsideComponent ===
        ErrorFromInsideComponentType.NoOptionSelected;

    const radioTitleId = `Cfar-Radio-Group`;

    const pushSocialProofingBanner = () => {
      if (cfarSocialVariant === CFAR_SOCIAL_SOCIAL_PROOF) {
        mainContent.push({
          className: "cfar-social-proofing",
          copy: CFAR_SOCIAL_PROOFING_COPY,
          leftIcon: IconName.DoubleCheck,
          hideRightIcon: true,
          fill: disabled ? "grey" : "green",
          component: GenericDetailsCardComponentEnum.NotificationBanner,
        });
      }
    };

    const discountInfo = () => (
      <div>
        Plan your trip worry-free. Add the flexibility to cancel your flight for
        any reason for a fee of{" "}
        <span style={{ textDecoration: "line-through" }}>
          {currentOffer.discount &&
            getTotalPriceText({
              price: currentOffer.discount.originalPremiumAmount.fiat,
              priceFormatter: twoDecimalFormatter,
            })}
        </span>{" "}
        <span style={{ color: "green" }}>
          <strong>
            {getTotalPriceText({
              price: currentOffer.premiumPerPax.fiat,
              priceFormatter: twoDecimalFormatter,
            })}
            {selectedAccountReferenceId
              ? ` / ${getRewardText({
                  reward:
                    currentOffer.premiumPerPax.rewards[
                      selectedAccountReferenceId
                    ],
                })}`
              : ""}
          </strong>
        </span>{" "}
        per traveler (available for 1 booking per account).
      </div>
    );

    switch (modalType) {
      case "refundable-fare":
        if (cfarDiscountEnabled) {
          mainContent.push({
            id: radioTitleId,
            content: discountInfo(),
            component: GenericDetailsCardComponentEnum.Custom,
          });
        } else {
          mainContent.push({
            id: radioTitleId,
            className: clsx(
              "cfar-details-generic-copy",
              "refundable-fare-subtitle",
              "bold"
            ),
            type: "secondary",
            copy: constants.REFUNDABLE_FARES_SUBTITLE(
              currentOffer.premiumPerPax.fiat,
              selectedAccountReferenceId
                ? currentOffer.premiumPerPax.rewards[selectedAccountReferenceId]
                : undefined
            ),
            component: GenericDetailsCardComponentEnum.GenericCopy,
          });
        }

        if (!cfarDiscountEnabled) {
          pushSocialProofingBanner();
        }
        break;
      case "cfar":
      default:
        if (!hideSubtitle) {
          mainContent.push({
            id: radioTitleId,
            className: clsx("cfar-details-generic-copy", "fade"),
            type: "secondary",
            copy: constants.CFAR_DETAILS_SUBTITLE,
            component: GenericDetailsCardComponentEnum.GenericCopy,
          });
        }
        if (!cfarDiscountEnabled) {
          pushSocialProofingBanner();
        }

        mainContent.push({
          className: clsx("cfar-details-generic-copy", "bold", {
            fade: disabled,
            strong: isAirOfferRedesignEnabled,
          }),
          type: "secondary",
          copy: constants.HOW_IT_WORKS_COPY,
          component: GenericDetailsCardComponentEnum.GenericCopy,
        });

        break;
    }

    mainContent.push({
      className: clsx("cfar-details-check-mark-icon", {
        fade: disabled,
      }),
      type: "secondary",
      copy:
        modalType === "refundable-fare"
          ? REFUNDABLE_FARES_POINT_ONE({
              variant: "v1",
              useStrong: true,
              punctuation: ".",
            })
          : CFAR_POINT_ONE,
      icon: IconName.NotAllowedSignBlue,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });

    mainContent.push({
      className: clsx("cfar-details-check-mark-icon", "with-money-icon", {
        fade: disabled,
      }),
      type: "secondary",
      copy: ftcVariantCopies.secondBullet,
      icon: IconName.MoneyCounterClockwiseBlue,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });

    mainContent.push({
      className: clsx("cfar-details-check-mark-icon", {
        fade: disabled,
      }),
      type: "secondary",
      copy:
        modalType === "refundable-fare"
          ? REFUNDABLE_FARES_POINT_THREE({ punctuation: "." })
          : CFAR_POINT_THREE,
      icon: IconName.SimpleLaptopBlue,
      component: GenericDetailsCardComponentEnum.GenericCopy,
    });

    mainContent.push({
      className: clsx("cfar-details-last-sentence", {
        fade: disabled,
      }),
      type: "secondary",
      copy: ftcVariantCopies.lastSentence,
      component: GenericDetailsCardComponentEnum.GenericCopy,
      newSection: true,
    });

    if (hasError && !isAirOfferRedesignEnabled) {
      mainContent.push({
        className: "cfar-details-error-message",
        type: "error",
        copy: constants.CFAR_NO_OPTION_SELECTED_COPY,
        component: GenericDetailsCardComponentEnum.GenericCopy,
        newSection: true,
      });
    }

    if (!hideRadioButtons) {
      const getLeftContentForCfarRefundCopyChallenger1 = (coveragePercentage?: number) => (
        <Box>
          <Box>Yes, add this option</Box>
          <Box style={{ fontSize: "12px" }}>
            Get {" "}
            <strong>
              {basefare
                ? getRefundString({
                  price: basefare,
                  tax: taxFare,
                  coverage: coveragePercentage
                    ? coveragePercentage
                    : DEFAULT_COVERAGE,
                })
                : "partial"}
            </strong>{" "}
            refund per traveler if you cancel.
          </Box>
        </Box>
      );
      const getLeftContentForCfarRefundCopyChallenger2 = (coveragePercentage?: number) => (
        <Box>
          <Box>Yes, make my flight {coveragePercentage}% refundable</Box>
          <Box style={{ fontSize: "12px" }}>
            Get {" "}
            <strong>
              {basefare
                ? getRefundString({
                  price: basefare,
                  tax: taxFare,
                  coverage: coveragePercentage
                    ? coveragePercentage
                    : DEFAULT_COVERAGE,
                })
                : "partial"}
            </strong>{" "}
            refund per traveler if you cancel.
          </Box>
        </Box>
      );
      const getLeftContent = (coveragePercentage?: number) => (
        <Box>
          <Box>Yes, add {coveragePercentage}% coverage</Box>
          <Box style={{ fontSize: "12px" }}>
            Receive a{" "}
            <strong>
              {basefare
                ? getRefundString({
                    price: basefare,
                    tax: taxFare,
                    coverage: coveragePercentage
                      ? coveragePercentage
                      : DEFAULT_COVERAGE,
                  })
                : "partial"}
            </strong>{" "}
            refund per traveler if you cancel.
          </Box>
        </Box>
      );
      const getRadioButtonOptions = (): IBoxedRadio[] => {
        if (isCfarRefundCopyChallenger1Variant) {
          return [
            {
              value: CfarOption.Yes,
              disabled: isAgentPortal,
              leftContent: getLeftContentForCfarRefundCopyChallenger1(
                currentOffer.policyData?.cashCoveragePercentage
              ),
              rightContent: (
                <>
                  {getCoMerchPriceWithRewardsUILabel({
                    price: currentOffer.premiumPerPax.fiat,
                    priceFormatter: twoDecimalFormatter,
                    label: constants.PER_TRAVELER,
                    rewards: selectedAccountReferenceId
                      ? currentOffer.premiumPerPax.rewards[
                      selectedAccountReferenceId
                      ]
                      : undefined,
                    mobile: matchesMobile,
                  })}
                </>
              ),
            },
          ];
        }
        if (isCfarRefundCopyChallenger2Variant) {
          return [
            {
              value: CfarOption.Yes,
              disabled: isAgentPortal,
              leftContent: getLeftContentForCfarRefundCopyChallenger2(
                currentOffer.policyData?.cashCoveragePercentage
              ),
              rightContent: (
                <>
                  {getCoMerchPriceWithRewardsUILabel({
                    price: currentOffer.premiumPerPax.fiat,
                    priceFormatter: twoDecimalFormatter,
                    label: constants.PER_TRAVELER,
                    rewards: selectedAccountReferenceId
                      ? currentOffer.premiumPerPax.rewards[
                      selectedAccountReferenceId
                      ]
                      : undefined,
                    mobile: matchesMobile,
                  })}
                </>
              ),
            },
          ];
        }
        if (isCfarCoMerchEnabled && cfarCoMerchOffers) {
          return [
            {
              value: CfarOption.Yes_option_1,
              disabled: isAgentPortal,
              leftContent: getLeftContent(
                cfarCoMerchOffers[0].policyData?.cashCoveragePercentage
              ),
              rightContent: (
                <>
                  {getCoMerchPriceWithRewardsUILabel({
                    price: cfarCoMerchOffers[0].premiumPerPax.fiat,
                    priceFormatter: twoDecimalFormatter,
                    label: constants.PER_TRAVELER,
                    rewards: selectedAccountReferenceId
                      ? cfarCoMerchOffers[0].premiumPerPax.rewards[
                          selectedAccountReferenceId
                        ]
                      : undefined,
                    mobile: matchesMobile,
                  })}
                </>
              ),
            },
            {
              value: CfarOption.Yes_option_2,
              disabled: isAgentPortal,
              leftContent: getLeftContent(
                cfarCoMerchOffers[1].policyData?.cashCoveragePercentage
              ),
              rightContent: (
                <>
                  {getCoMerchPriceWithRewardsUILabel({
                    price: cfarCoMerchOffers[1].premiumPerPax.fiat,
                    priceFormatter: twoDecimalFormatter,
                    label: constants.PER_TRAVELER,
                    rewards: selectedAccountReferenceId
                      ? cfarCoMerchOffers[1].premiumPerPax.rewards[
                          selectedAccountReferenceId
                        ]
                      : undefined,
                    mobile: matchesMobile,
                  })}
                </>
              ),
            },
          ];
        }

        return [
          {
            value: CfarOption.Yes,
            copy:
              cfarDiscountEnabled && currentOffer.discount
                ? constants.YES_OPTION_COPY_DISCOUNT(
                    currentOffer.discount.originalPremiumAmount.fiat,
                    currentOffer.premiumPerPax.fiat,
                    selectedAccountReferenceId
                      ? currentOffer.premiumPerPax.rewards[
                          selectedAccountReferenceId
                        ]
                      : undefined
                  )
                : constants.YES_OPTION_COPY(
                    currentOffer.premiumPerPax.fiat,
                    selectedAccountReferenceId
                      ? currentOffer.premiumPerPax.rewards[
                          selectedAccountReferenceId
                        ]
                      : undefined
                  ),
            disabled: isAgentPortal,
          },
        ];
      };

      mainContent.push({
        radioButtons: [
          ...getRadioButtonOptions(),
          {
            value: CfarOption.No,
            copy: isCfarRefundCopyChallenger2Variant ? constants.NO_OPTION_COPY_FOR_REFUND_AMOUNT_COPY_VARIANT : constants.NO_OPTION_COPY
          },
        ],
        selectedValue,
        setSelectedValue: (value: number) => {
          switch (value) {
            case CfarOption.Yes:
              !isAgentPortal && setSelectedCfarId(currentOffer.id);
              updateRefundableFaresProperties({
                cfar_choice: 1,
              });
              break;
            case CfarOption.Yes_option_1:
              !isAgentPortal &&
                cfarCoMerchOffers &&
                setSelectedCfarId(cfarCoMerchOffers[0].id);
              updateRefundableFaresProperties({
                cfar_choice: 1,
              });
              break;
            case CfarOption.Yes_option_2:
              !isAgentPortal &&
                cfarCoMerchOffers &&
                setSelectedCfarId(cfarCoMerchOffers[1].id);
              updateRefundableFaresProperties({
                cfar_choice: 2,
              });
              break;
            case CfarOption.No:
              setSelectedCfarId({
                policyId: DO_NOT_APPLY_OPTION_KEY,
                productId: DO_NOT_APPLY_OPTION_KEY,
              });
              updateRefundableFaresProperties({
                cfar_choice: 0,
              });
              break;
            default:
              break;
          }

          setErrorFromInsideComponent(null);

          if (onSelectValueCallback) {
            onSelectValueCallback();
          }
        },
        // Collapse only after activation to allow keyboard users
        // to still hear the option selected and possibly navigate to
        // other options.
        onConfirmSelectedValue: () => {
          if (onConfirmSelectedValueCallback) {
            onConfirmSelectedValueCallback();
          }
        },
        hasError: hasError,
        radioGroupName: constants.CFAR_RADIO_BUTTONS_GROUP,
        ariaLabelledBy: radioTitleId,
        disabled: disabled,
        component: GenericDetailsCardComponentEnum.BoxedRadioGroup,
      });
    }

    if (!hideContinueCta && (!usePartialScroll || matchesMobile)) {
      mainContent.push(continueCta);
    }

    if (matchesMobile) {
      mainContent.push({
        content: [
          {
            title: constants.TERMS_AND_CONDITIONS_COPY,
            body: isRapidRebookRenameEnabled
              ? FLIGHT_CFAR_TERMS_BODY_NEW
              : FLIGHT_CFAR_TERMS_BODY_OLD,
          },
        ],
        component: GenericDetailsCardComponentEnum.AccordionGroup,
      });
    } else if (!usePartialScroll) {
      mainContent.push({
        message: constants.TERMS_AND_CONDITIONS_COPY,
        onClick: handleViewTerms,
        position: readTermsAndConditionsLinkPosition ?? "center",
        disabled: disabled,
        underline: true,
        component: GenericDetailsCardComponentEnum.ClickableLink,
      });
    }

    if (cfarDiscountEnabled && modalType === "refundable-fare") {
      mainContent.push({
        type: "secondary",
        component: GenericDetailsCardComponentEnum.GenericCopy,
        copy: DISCOUNT_PROMO_TERMS_CONDITIONS,
      });
    }

    return mainContent;
  }, [
    currentChangePolicy,
    currentOffer,
    selectedAccountReferenceId,
    isAgentPortal,
    selectedValue,
    onContinue,
    isUnselected,
    matchesMobile,
    usePartialScroll,
    hideContinueCta,
    readTermsAndConditionsLinkPosition,
    onSelectValueCallback,
    onConfirmSelectedValueCallback,
    modalType,
    error,
  ]);

  const BottomContent = useMemo<
    GenericDetailsCardComponent[] | undefined
  >(() => {
    if (!currentOffer) {
      return [];
    }

    if (!usePartialScroll || matchesMobile) {
      return undefined;
    }

    const isSelectionEnabled = getIsSelectionEnabled(modalType);

    return [
      {
        message: constants.VIEW_TERMS_AND_CONDITIONS_COPY,
        onClick: handleViewTerms,
        component: GenericDetailsCardComponentEnum.ClickableLink,
      },
      {
        className: clsx(
          "cfar-details-bottom-banner-continue-button",
          modalType
        ),
        message:
          modalType === "refundable-fare"
            ? constants.UPGRADE_AND_CONTINUE_BUTTON_COPY
            : constants.CONTINUE_BUTTON_COPY,
        onClick: onContinue,
        ariaLabel: constants.CONTINUE_BUTTON_COPY,
        disabled: isSelectionEnabled ? isUnselected : false,
        onClickWhenDisabled: () => {
          if (isSelectionEnabled && !selectedValue) {
            setErrorFromInsideComponent(
              ErrorFromInsideComponentType.NoOptionSelected
            );
          }
        },
        floating: false,
        fill: "blue",
        component: GenericDetailsCardComponentEnum.GenericCta,
      },
    ];
  }, [
    onContinue,
    isUnselected,
    matchesMobile,
    usePartialScroll,
    modalType,
    currentOffer,
  ]);

  useEffect(() => {
    // note: scroll to top upon arriving at this screen on mobile.
    if (matchesMobile) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [matchesMobile]);

  useEffect(() => {
    switch (modalType) {
      case "refundable-fare":
        break;
      case "cfar":
      default:
        if (isAgentPortal) {
          setSelectedCfarId({
            policyId: DO_NOT_APPLY_OPTION_KEY,
            productId: DO_NOT_APPLY_OPTION_KEY,
          });
        }
    }
  }, [isAgentPortal, modalType]);

  // There are 3 cases:
  // 1) Call finished, but failed or without CFAR offer ->
  //    Do not return any element and just call onNoCfarOffer(), which should direct user to the next page)
  // 2) Call not finished -> Show LoadingPopup
  // 3) Call finished, with CFAR offer -> Show GenericDetailsCard
  useEffect(() => {
    switch (modalType) {
      case "refundable-fare":
        break;
      case "cfar":
      default: {
        const noCfarOffer =
          fetchAncillaryOfferCallState === CallState.Failed ||
          (fetchAncillaryOfferCallState === CallState.Success && !hasCfarOffer);

        if (noCfarOffer) {
          onNoCfarOffer();
        }
      }
    }
  }, [fetchAncillaryOfferCallState, hasCfarOffer, onNoCfarOffer, modalType]);
  switch (modalType) {
    case "refundable-fare": {
      return (
        <GenericDetailsCard
          popupClassName="refundable-fare-generic-details-card"
          contentClassName={contentClassName}
          topContent={!hideTopContent ? getTopContent() : undefined}
          mainContent={MainContent}
          bottomContent={BottomContent}
          // note: it's not rendered as a modal on mobile view
          openModal={openCfarDetails ?? matchesMobile}
          onClose={onClose}
          isMobile={matchesMobile}
          contentOnly={contentOnly ?? matchesMobile}
          scrollOption={usePartialScroll ? "partial-scroll" : "default-scroll"}
          topBannerContent={showTopBanner ? getTopBanner() : undefined}
        />
      );
    }
    case "cfar":
    default: {
      if (fetchAncillaryOfferCallState === CallState.InProcess) {
        return (
          <LoadingPopup
            indicatorSize="medium"
            verticalAlignment="center"
            indicator={B2BSpinner}
            open={true}
            popupSize="small"
            fullScreen={matchesMobile}
            message="Loading offer"
          />
        );
      } else {
        return (
          <>
            {fetchAncillaryOfferCallState === CallState.Success &&
            hasCfarOffer ? (
              <GenericDetailsCard
                contentClassName={contentClassName}
                topContent={!hideTopContent ? getTopContent() : undefined}
                mainContent={MainContent}
                bottomContent={BottomContent}
                // note: it's not rendered as a modal on mobile view
                openModal={openCfarDetails ?? matchesMobile}
                onClose={onClose}
                isMobile={matchesMobile}
                contentOnly={contentOnly ?? matchesMobile}
                scrollOption={
                  usePartialScroll ? "partial-scroll" : "default-scroll"
                }
                topBannerContent={showTopBanner ? getTopBanner() : undefined}
                redesignClassName={
                  isAirOfferRedesignEnabled
                    ? constants.AIR_OFFER_REDESIGN_CLASSNAME
                    : undefined
                }
              />
            ) : null}
          </>
        );
      }
    }
  }
};

const getIsSelectionEnabled = (
  modalType: "refundable-fare" | "cfar" | undefined
) => {
  return modalType === "cfar" || modalType === undefined;
};
